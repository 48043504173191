// calculations.js

export const calculateBMI = (weight, height) => {
  const heightInMeters = height / 100;
  return (weight / (heightInMeters * heightInMeters)).toFixed(2);
};

export const calculateBodyFat = (sex, weight, height, neck, waist, hip) => {
  let result;
  weight = parseFloat(weight);
  height = parseFloat(height);
  neck = parseFloat(neck);
  waist = parseFloat(waist);
  hip = parseFloat(hip);

  if (sex === 'male') {
    result = 86.010 * Math.log10(waist - neck) - 70.041 * Math.log10(height) + 36.76;
  } else {
    result = 495 / (1.29579 - 0.35004 * Math.log10(waist + hip - neck) + 0.221 * Math.log10(height)) - 450;
  }

  return result.toFixed(2);
};

export const calculateBMR = (sex, weight, height, age) => {
  let bmr;
  weight = parseFloat(weight);
  height = parseFloat(height);
  age = parseFloat(age);

  if (sex === 'male') {
    bmr = 88.362 + (13.397 * weight) + (4.799 * height) - (5.677 * age);
  } else {
    bmr = 447.593 + (9.247 * weight) + (3.098 * height) - (4.330 * age);
  }

  return bmr.toFixed(2);
};

export const calculateTDEE = (bmr, activityFactor) => {
  return (bmr * activityFactor).toFixed(2);
};

export const determineFitnessLevel = (sex, age, bmi, bodyFat) => {
  if (sex === 'male') {
    if (age <= 35) {
      if (bmi >= 25 && bodyFat < 15) {
        return 'Estado físico atlético';
      } else if ((bmi >= 23 && bodyFat < 20) || (bmi >= 25 && bodyFat < 25)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 25) || (bmi >= 23 && bodyFat < 30)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else if (age <= 50) {
      if (bmi >= 23 && bodyFat < 17) {
        return 'Estado físico atlético';
      } else if ((bmi >= 22 && bodyFat < 22) || (bmi >= 23 && bodyFat < 27)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 27) || (bmi >= 22 && bodyFat < 32)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else {
      if (bmi >= 23 && bodyFat < 20) {
        return 'Estado físico atlético';
      } else if ((bmi >= 22 && bodyFat < 25) || (bmi >= 23 && bodyFat < 30)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 30) || (bmi >= 22 && bodyFat < 35)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    }
  } else {
    if (age <= 35) {
      if (bmi >= 23 && bodyFat < 18) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21 && bodyFat < 24) || (bmi >= 23 && bodyFat < 28)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19 && bodyFat < 28) || (bmi >= 21 && bodyFat < 35)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else if (age <= 50) {
      if (bmi >= 22 && bodyFat < 20) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21 && bodyFat < 26) || (bmi >= 22 && bodyFat < 30)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19 && bodyFat < 30) || (bmi >= 21  && bodyFat < 37)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else {
      if (bmi >= 22  && bodyFat < 22) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21  && bodyFat < 28) || (bmi >= 22  && bodyFat < 35)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19  && bodyFat < 35) || (bmi >= 21  && bodyFat < 40)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    }
  }
};

export const muscleGainRates = {
  'Estado físico atlético': 0.5, // Avanzado
  'Estado físico bueno': 1.0,    // Intermedio
  'Estado físico normal': 1.5,   // Principiante
  'Bajo estado físico': 2.0      // Principiante
};
