// src/components/PlanOverview.js

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../ThemeContext';
import AudioManager from './care/AudioManager';

const PlanOverview = ({
  fitnessLevel,
  caloricIntake,
  useCreatine,
  activityFactor,
  sex,
  age,
  firstDayMetrics = { bmi: 0, bodyFatPercentage: 0, totalMuscleGain: 0 },
  lastDayMetrics = { bmi: 0, bodyFatPercentage: 0, totalMuscleGain: 0 },
}) => {
  const { theme } = useTheme();

  // Agregar log para verificar las props
  console.log('PlanOverview Props:', { fitnessLevel, caloricIntake, useCreatine, activityFactor, sex, age });
  console.log('First day metrics:', firstDayMetrics);
  console.log('Last day metrics:', lastDayMetrics);

  const caloricIntakeMapping = {
    volum: 'Volumen',
    recomp: 'Recomposición',
    defin: 'Definición',
  };

  const themeClasses = {
    container: theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900',
    title: theme === 'dark' ? 'text-white border-gray-600' : 'text-gray-800 border-gray-300',
    label: theme === 'dark' ? 'text-gray-400' : 'text-gray-600',
  };

  // Calcular cambios
  const muscleGain = lastDayMetrics.totalMuscleGain - firstDayMetrics.totalMuscleGain;
  const bmiChange = lastDayMetrics.bmi - firstDayMetrics.bmi;
  const bmiDirection = bmiChange > 0 ? '↑' : '↓';
  const bodyFatChange = lastDayMetrics.bodyFatPercentage - firstDayMetrics.bodyFatPercentage;
  const bodyFatDirection = bodyFatChange > 0 ? '↑' : '↓';

  return (
    <div className={`p-6 max-w-md mx-auto rounded-lg shadow-md ${themeClasses.container}`}>
      <h2
        className={`text-2xl font-bold mb-4 text-center border-b-2 pb-2 ${themeClasses.title}`}
      >
        Información General del Plan
      </h2>
      <div className="space-y-4">
        {/* Información General */}
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Nivel de Fitness:</p>
          <p className="font-semibold">{fitnessLevel}</p>
        </div>
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Tipo de Plan Calórico:</p>
          <p className="font-semibold">
            {caloricIntakeMapping[caloricIntake] || 'Definición'}
          </p>
        </div>
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Uso de Creatina:</p>
          <p
            className={`font-semibold ${useCreatine ? 'text-green-500' : 'text-red-500'}`}
          >
            {useCreatine ? 'Sí' : 'No'}
          </p>
        </div>
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Factor de Actividad:</p>
          <p className="font-semibold">{activityFactor || 'N/A'}</p>
        </div>
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Sexo:</p>
          <p className="font-semibold">{sex || 'N/A'}</p>
        </div>
        <div className="flex justify-between">
          <p className={`${themeClasses.label} font-medium`}>Edad:</p>
          <p className="font-semibold">{age ? `${age} años` : 'N/A'}</p>
        </div>

        {/* Estadísticas del Plan */}
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Estadísticas del Plan</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <p className={`${themeClasses.label} font-medium`}>Ganancia de Músculo:</p>
              <p className="font-semibold">
                {muscleGain.toFixed(2)} kg ({muscleGain > 0 ? '↑' : '↓'})
              </p>
            </div>
            <div className="flex justify-between">
              <p className={`${themeClasses.label} font-medium`}>Tu cambio en el IMC:</p>
              <p className="font-semibold">
                {lastDayMetrics.bmi.toFixed(2)} {bmiDirection} ({bmiChange.toFixed(2)})
              </p>
            </div>
            <div className="flex justify-between">
              <p className={`${themeClasses.label} font-medium`}>Tu cambio en % de Grasa:</p>
              <p className="font-semibold">
                {lastDayMetrics.bodyFatPercentage.toFixed(2)}% {bodyFatDirection} ({bodyFatChange.toFixed(2)}%)
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Integración del AudioVisualizer */}
      <AudioManager />    
    </div>
  );
};

PlanOverview.propTypes = {
  fitnessLevel: PropTypes.string.isRequired,
  caloricIntake: PropTypes.oneOf(['recomp', 'volum', 'defin']).isRequired,
  useCreatine: PropTypes.bool.isRequired,
  activityFactor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Ajustar el tipo si es necesario
  sex: PropTypes.string,
  age: PropTypes.number,
  firstDayMetrics: PropTypes.shape({
    bmi: PropTypes.number,
    bodyFatPercentage: PropTypes.number,
    totalMuscleGain: PropTypes.number,
  }),
  lastDayMetrics: PropTypes.shape({
    bmi: PropTypes.number,
    bodyFatPercentage: PropTypes.number,
    totalMuscleGain: PropTypes.number,
  }),
};

export default PlanOverview;
