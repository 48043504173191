import React from 'react';

const MeasurementOfNeck = () => {
    const styles = `
        /* Animaciones */
        @keyframes moveLeftArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(-5deg); }
        }

        @keyframes moveRightArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(5deg); }
        }

        @keyframes adjustTape {
            0%, 100% { rx: 20; ry: 7; }
            50% { rx: 15; ry: 5; }
        }

        /* Aplicar animaciones a los grupos de brazos */
        #left-arm-group {
            transform-origin: 65px 80px; /* Origen en el hombro izquierdo */
            animation: moveLeftArm 2s infinite ease-in-out;
        }

        #right-arm-group {
            transform-origin: 85px 80px; /* Origen en el hombro derecho */
            animation: moveRightArm 2s infinite ease-in-out;
        }

        /* Animación de la cinta métrica */
        #tape-ellipse {
            animation: adjustTape 2s infinite ease-in-out;
        }

        /* Estilos de los marcadores */
        .tape-marker {
            stroke: #fff;
            stroke-width: 0.5;
        }

        /* Estilos de los números */
        .tape-number {
            font-size: 2.5px;
            fill: #fff;
            text-anchor: middle;
            dominant-baseline: middle;
            font-family: Arial, sans-serif;
        }

        /* Dedos */
        .finger {
            stroke: #000;
            stroke-width: 0.5;
        }

        /* Texturas */
        .hair {
            fill: url(#hairTexture);
        }

        .shirt {
            fill: url(#shirtTexture);
        }

        /* Detalles adicionales */
        .outline {
            stroke: #000;
            stroke-width: 0.5;
            fill: none;
        }
    `;

    return (
        <svg width="150" height="160" viewBox="0 0 150 160" xmlns="http://www.w3.org/2000/svg">
            <style>{styles}</style>
            {/* Definición de texturas */}
            <defs>
                {/* Textura para el cabello */}
                <pattern id="hairTexture" patternUnits="userSpaceOnUse" width="5" height="5">
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#6b4423" />
                </pattern>

                {/* Textura para la camiseta */}
                <pattern id="shirtTexture" patternUnits="userSpaceOnUse" width="5" height="5">
                    <circle cx="2.5" cy="2.5" r="0.5" fill="#ffffff" opacity="0.2" />
                </pattern>
            </defs>

            {/* Cuerpo de la Persona */}
            <g id="person">
                {/* Cabeza */}
                <circle cx="75" cy="40" r="20" fill="#ffcc99" stroke="#000" strokeWidth="1" />

                {/* Cabello ajustado para no superponerse al rostro */}
                <path
                    className="hair"
                    d="M55,30 Q75,10 95,30 L95,20 Q75,0 55,20 Z"
                    stroke="#000"
                    strokeWidth="1"
                    fill="url(#hairTexture)"
                />

                {/* Detalles Faciales */}
                {/* Ojos */}
                <circle cx="67.5" cy="37.5" r="2.5" fill="#000" />
                <circle cx="82.5" cy="37.5" r="2.5" fill="#000" />
                {/* Cejas */}
                <path d="M65,35 Q67.5,32.5 70,35" stroke="#000" strokeWidth="0.5" fill="none" />
                <path d="M80,35 Q82.5,32.5 85,35" stroke="#000" strokeWidth="0.5" fill="none" />
                {/* Boca */}
                <path d="M70,47.5 Q75,52.5 80,47.5" stroke="#000" strokeWidth="1" fill="none" />

                {/* Cuello */}
                <rect x="70" y="60" width="10" height="10" fill="#ffcc99" stroke="#000" strokeWidth="1" />

                {/* Cuerpo con textura */}
                <rect
                    className="shirt"
                    x="60"
                    y="70"
                    width="30"
                    height="50"
                    stroke="#000"
                    strokeWidth="1"
                    rx="5"
                />

                {/* Hombros */}
                <line x1="70" y1="70" x2="60" y2="70" stroke="#000" strokeWidth="1" />
                <line x1="80" y1="70" x2="90" y2="70" stroke="#000" strokeWidth="1" />

                {/* Brazos */}
                {/* Brazo Izquierdo (animado) */}
                <g id="left-arm-group">
                    {/* Brazo Superior */}
                    <rect
                        x="60"
                        y="70"
                        width="10"
                        height="35"
                        fill="#ffcc99"
                        stroke="#000"
                        strokeWidth="1"
                        rx="5"
                    />
                    {/* Mano Izquierda */}
                    <circle cx="65" cy="105" r="5" fill="#ffcc99" stroke="#000" strokeWidth="1" />
                    {/* Dedos Izquierdos */}
                    <line className="finger" x1="62.5" y1="102.5" x2="60" y2="100" />
                    <line className="finger" x1="67.5" y1="102.5" x2="70" y2="100" />
                </g>

                {/* Brazo Derecho (animado) */}
                <g id="right-arm-group">
                    {/* Brazo Superior */}
                    <rect
                        x="80"
                        y="70"
                        width="10"
                        height="35"
                        fill="#ffcc99"
                        stroke="#000"
                        strokeWidth="1"
                        rx="5"
                    />
                    {/* Mano Derecha */}
                    <circle cx="85" cy="105" r="5" fill="#ffcc99" stroke="#000" strokeWidth="1" />
                    {/* Dedos Derechos */}
                    <line className="finger" x1="82.5" y1="102.5" x2="80" y2="100" />
                    <line className="finger" x1="87.5" y1="102.5" x2="90" y2="100" />
                </g>
            </g>

            {/* Cinta Métrica alrededor del Cuello */}
            <g id="tape-measure">
                {/* Cinta Métrica (elipse con animación mejorada) */}
                <ellipse
                    id="tape-ellipse"
                    cx="75"
                    cy="65"
                    rx="20"
                    ry="7"
                    stroke="#d32f2f"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                />

                {/* Marcadores de la cinta métrica */}
                <g className="tape-markers">
                    {/* Marcadores a lo largo de la elipse */}
                    <line className="tape-marker" x1="75" y1="58" x2="75" y2="60" />
                    <line className="tape-marker" x1="88" y1="60" x2="90" y2="62" />
                    <line className="tape-marker" x1="95" y1="65" x2="97" y2="65" />
                    <line className="tape-marker" x1="88" y1="70" x2="90" y2="68" />
                    <line className="tape-marker" x1="75" y1="72" x2="75" y2="74" />
                    <line className="tape-marker" x1="62" y1="70" x2="60" y2="68" />
                    <line className="tape-marker" x1="55" y1="65" x2="53" y2="65" />
                    <line className="tape-marker" x1="62" y1="60" x2="60" y2="62" />
                </g>

                {/* Números de Centímetros */}
                <g className="tape-numbers">
                    <text className="tape-number" x="75" y="55">30</text>
                    <text className="tape-number" x="90" y="62">35</text>
                    <text className="tape-number" x="97" y="65">40</text>
                    <text className="tape-number" x="90" y="68">35</text>
                    <text className="tape-number" x="75" y="75">30</text>
                    <text className="tape-number" x="60" y="68">25</text>
                    <text className="tape-number" x="53" y="65">20</text>
                    <text className="tape-number" x="60" y="62">25</text>
                </g>
            </g>
        </svg>
    );
};

export default MeasurementOfNeck;
