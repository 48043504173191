import { useState, useCallback, useEffect, useRef } from 'react';
import {
  calculateBMI,
  calculateBodyFat,
  determineFitnessLevel,
} from './calculations';
import {
  updateCalendarWithPlan,
  calculateProjections,
} from './trainingPlanUtils';
import { useAuth } from '../../contexts/AuthContext';
import {
  savePlan as apiSavePlan,
  deletePlan as apiDeletePlan,
  getHealthMetrics,
  getPlans,
} from '../../Sidebar/formats/apiService';
import {
  loadEvents,
  saveEvents,
  loadPlanData,
  savePlanData,
  loadProjections,
  removeItem,
  loadHealthMetrics,
  saveHealthMetrics,
} from './dataManager';
import { v4 as uuidv4 } from 'uuid';

const parseDate = (dateInput) => {
  if (typeof dateInput === 'string') {
    const parsedDate = new Date(dateInput);
    if (isNaN(parsedDate)) {
      throw new Error(`Fecha inválida: ${dateInput}`);
    }
    return parsedDate;
  } else if (dateInput instanceof Date) {
    return dateInput;
  } else {
    throw new TypeError('parseDate solo acepta una cadena o un objeto Date.');
  }
};

export const useEventPlanManager = (
  dateRange,
  caloricIntake,
  useCreatine,
  healthMetrics,
  plans
) => {
  const [events, setEvents] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [planCreated, setPlanCreated] = useState(false);
  const [projections, setProjections] = useState([]);
  const [error, setError] = useState(null);
  const [healthMetricsState, setHealthMetricsState] = useState(healthMetrics || {});
  const [isLoading, setIsLoading] = useState(true);
  const { user, loading } = useAuth();

  // useRef para evitar múltiples ejecuciones simultáneas
  const isFetchingData = useRef(false);

  const handleEventsUpdate = useCallback(() => {
    if (!user) {
      const localEvents = loadEvents();
      const localProjections = loadProjections();
      setEvents(localEvents);
      setProjections(localProjections);
    }
  }, [user]);

  const createEvent = useCallback(
    async (event) => {
      try {
        const newEvent = {
          ...event,
          plan: planData ? planData._id : null,
          id: uuidv4(),
        };

        const updatedEvents = [...events, newEvent];
        saveEvents(updatedEvents);
        setEvents(updatedEvents);
      } catch (err) {
        setError(err.message);
      }
    },
    [planData, events]
  );

  // Efecto para cargar las métricas de salud
  useEffect(() => {
    const fetchHealthMetrics = async () => {
      if (user) {
        try {
          const metrics = await getHealthMetrics();
          setHealthMetricsState(metrics || {});
          saveHealthMetrics(metrics || {});
        } catch (err) {
          setError(err.message);
        }
      } else {
        const localMetrics = loadHealthMetrics();
        setHealthMetricsState(localMetrics || {});
      }
    };

    fetchHealthMetrics();
  }, [user]);

  // Efecto para cargar los datos del plan y eventos
  useEffect(() => {
    const fetchData = async () => {
      if (isFetchingData.current) return;
      isFetchingData.current = true;
      setIsLoading(true);

      try {
        if (user) {
          const plansData = plans.length > 0 ? plans : await getPlans();

          if (plansData && plansData.length > 0) {
            removeItem('planData');
            removeItem('events');
            removeItem('projections');

            const plan = {
              ...plansData[0],
              start: parseDate(plansData[0].start),
              end: parseDate(plansData[0].end),
            };

            setPlanData(plan);
            setPlanCreated(true);
            savePlanData(plan);

            setEvents([]);
            await updateCalendarWithPlan(
              plan,
              healthMetricsState,
              createEvent,
              calculateProjections,
              setEvents,
              [],
              handleEventsUpdate,
              setProjections,
              setError
            );
          } else {
            const localPlanData = loadPlanData();
            if (localPlanData) {
              setPlanData(localPlanData);
              setPlanCreated(true);

              setEvents([]);
              await updateCalendarWithPlan(
                localPlanData,
                healthMetricsState,
                createEvent,
                calculateProjections,
                setEvents,
                [],
                handleEventsUpdate,
                setProjections,
                setError
              );

              const localProjections = loadProjections();
              setProjections(localProjections);
            } else {
              // No hay planes ni localmente ni en el servidor
              setPlanData(null);
              setPlanCreated(false);
              setEvents([]);
              setProjections([]);
            }
          }
        } else {
          const localPlanData = loadPlanData();
          if (localPlanData) {
            setPlanData(localPlanData);
            setPlanCreated(true);

            setEvents([]);
            await updateCalendarWithPlan(
              localPlanData,
              healthMetricsState,
              createEvent,
              calculateProjections,
              setEvents,
              [],
              handleEventsUpdate,
              setProjections,
              setError
            );
          } else {
            // No hay plan local
            setPlanData(null);
            setPlanCreated(false);
            setEvents([]);
            setProjections([]);
          }

          const localEvents = loadEvents();
          setEvents(localEvents);

          const localProjections = loadProjections();
          setProjections(localProjections);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        isFetchingData.current = false;
      }
    };

    // Ejecutar fetchData solo cuando el usuario cambia o al montar el componente
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]); // Dependemos solo de 'user' para evitar bucles infinitos

  const resetCalendar = useCallback(
    async (planId) => {
      try {
        if (user) {
          await apiDeletePlan(planId);
          setPlanData(null);
          setPlanCreated(false);

          removeItem('planData');
          removeItem('events');
          removeItem('projections');

          setEvents([]);
          setProjections([]);

          handleEventsUpdate();
        } else {
          removeItem('planData');
          removeItem('events');
          removeItem('projections');
          setEvents([]);
          setProjections([]);
          setPlanData(null);
          setPlanCreated(false);
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [user, handleEventsUpdate]
  );

  const handleDeletePlan = useCallback(() => {
    if (planData && planData._id) {
      resetCalendar(planData._id);
      localStorage.setItem('rangeSelected', 'false');
    }
  }, [planData, resetCalendar]);

  const handleCreatePlan = useCallback(async () => {
    if (
      !healthMetricsState ||
      !healthMetricsState.weight ||
      !healthMetricsState.height ||
      !healthMetricsState.age ||
      !healthMetricsState.sex ||
      !healthMetricsState.activityFactor
    ) {
      setError('Las métricas de salud no están completas. Por favor, completa tus datos de salud.');
      return;
    }

    if (!planData) {
      const bmi = calculateBMI(healthMetricsState.weight || 0, healthMetricsState.height || 0);
      const bodyFat = calculateBodyFat(
        healthMetricsState.sex || 'unknown',
        healthMetricsState.weight || 0,
        healthMetricsState.height || 0,
        healthMetricsState.neck || 0,
        healthMetricsState.waist || 0,
        healthMetricsState.hip || 0
      );
      const fitnessLevel = determineFitnessLevel(
        healthMetricsState.sex,
        healthMetricsState.age,
        bmi,
        bodyFat
      );

      const newPlan = {
        start: dateRange[0].toISOString(),
        end: dateRange[1].toISOString(),
        fitnessLevel,
        caloricIntake,
        useCreatine,
      };

      try {
        let savedPlan;
        if (user) {
          savedPlan = await apiSavePlan(newPlan);
          if (!savedPlan) {
            throw new Error('No se pudo guardar el plan en el servidor.');
          }
          removeItem('planData');
          removeItem('events');
          removeItem('projections');
          savePlanData(savedPlan);
        } else {
          savedPlan = newPlan;
          savePlanData(savedPlan);
        }

        setPlanData(savedPlan);

        setEvents([]);
        await updateCalendarWithPlan(
          savedPlan,
          healthMetricsState,
          createEvent,
          calculateProjections,
          setEvents,
          [],
          handleEventsUpdate,
          setProjections,
          setError
        );

        setPlanCreated(true);
      } catch (err) {
        setError('No se pudo guardar el plan. Por favor, intenta nuevamente.');
      }
    }
  }, [
    planData,
    dateRange,
    caloricIntake,
    useCreatine,
    createEvent,
    setEvents,
    handleEventsUpdate,
    user,
    healthMetricsState,
    setError,
  ]);

  return {
    events,
    setEvents,
    planData,
    setPlanData,
    planCreated,
    setPlanCreated,
    projections,
    setProjections,
    error,
    setError,
    handleEventsUpdate,
    createEvent,
    resetCalendar,
    handleDeletePlan,
    handleCreatePlan,
    loading: isLoading || loading,
  };
};
