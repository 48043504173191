// src/components/TarjetaDeTimeline.js

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getEntries } from '../../Sidebar/formats/apiService';
import DayTracking from './DayTracking';
import { useTheme } from '../../ThemeContext';
import { usePlan } from '../../contexts/PlanContext';
import { ReactComponent as MoodIcon } from './mood.svg';
import { ReactComponent as StateIcon } from './state.svg';
import { ReactComponent as WorkoutIcon } from './workout.svg';
import { ReactComponent as FoodIcon } from './food.svg';
import AdditionalMetrics from './AdditionalMetrics';
import useOnScreen from './useOnScreen';

const EventHeader = ({ title, date, onEdit, theme }) => (
  <div className="flex flex-col mb-4">
    <div className="flex justify-between items-center">
      <div>
        <h3 className={`text-xl font-bold ${theme === 'dark' ? 'text-gray-100' : 'text-gray-800'}`}>
          {title}
        </h3>
        <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
          {date}
        </p>
      </div>
      <button
        className={`flex items-center px-4 py-2 text-sm font-semibold rounded-lg transition-colors focus:outline-none focus:ring-2 ${
          theme === 'dark'
            ? 'bg-blue-600 border-blue-600 hover:bg-blue-700 text-white'
            : 'bg-blue-500 border-blue-500 hover:bg-blue-600 text-white'
        }`}
        onClick={onEdit}
      >
        ✏️ Registro diario
      </button>
    </div>
  </div>
);

const TarjetaDeTimeline = forwardRef(
  ({ expandedEvent, index, onClick, className, metrics, planData }, ref) => {
    const { events, planId } = usePlan();
    const event = events[index];

    const [showDayTracking, setShowDayTracking] = useState(false);
    const [dailyCalories, setDailyCalories] = useState(0);
    const [dailyCaloriesBurned, setDailyCaloriesBurned] = useState(0);
    const [dailyNutritionDetail, setDailyNutritionDetail] = useState(null);
    const [exerciseActivities, setExerciseActivities] = useState([]);
    const [typeCounts, setTypeCounts] = useState({
      mood: 0,
      state: 0,
      workout: 0,
      food: 0,
    });
    const [registeredData, setRegisteredData] = useState({});
    const [dataChanged, setDataChanged] = useState(0);

    const { theme } = useTheme();
    const { user } = useAuth();

    const localRef = useRef();

    useImperativeHandle(ref, () => localRef.current);

    const isVisible = useOnScreen(localRef, '200px');

    const getBackgroundClass = (light, dark) => (theme === 'dark' ? dark : light);
    const getBorderClass = (light, dark) => (theme === 'dark' ? dark : light);

    const currentDateTime = useMemo(() => new Date(), []);
    const eventDateObj = useMemo(() => new Date(event?.start), [event?.start]);

    const eventDate = eventDateObj.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const todayDate = currentDateTime.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const isToday = eventDate === todayDate;

    const bmr = planData?.activityFactor
      ? metrics?.dailyTDEE / planData.activityFactor
      : 0;
    const maxCaloriesBurned = planData?.activityFactor
      ? metrics?.dailyTDEE - bmr
      : 0;

    const handleDataChange = useCallback(() => {
      setDataChanged((prev) => prev + 1);
    }, []);

    useEffect(() => {
      let timer;

      const fetchData = async () => {
        if (user) {
          try {
            const dateParts = eventDate.split('/');
            const startDate = new Date(
              Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 0, 0, 0)
            );
            const endDate = new Date(
              Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 23, 59, 59)
            );

            const entries = await getEntries(planId, startDate, endDate);

            if (entries) {
              const newRegisteredData = {};

              entries.forEach((entry) => {
                const entryDate = new Date(entry.timestamp);
                const entryHour = entryDate.getUTCHours();

                if (!newRegisteredData[entryHour]) {
                  newRegisteredData[entryHour] = [];
                }
                newRegisteredData[entryHour].push({
                  id: entry._id,
                  type: entry.type,
                  data: entry.data,
                  planId: entry.planId,
                  hour: entryHour,
                  date: eventDate,
                });
              });

              setRegisteredData(newRegisteredData);
            }
          } catch (error) {
            console.error('Error al obtener las entradas:', error);
          }
        } else {
          try {
            const savedData = localStorage.getItem('hourTrackingData');
            const parsedData = savedData ? JSON.parse(savedData) : {};
            const data = parsedData[planId]?.[eventDate] || {};
            setRegisteredData(data);
          } catch (error) {
            console.error('Error al parsear "hourTrackingData" desde localStorage:', error);
          }
        }
      };

      if (isVisible) {
        timer = setTimeout(() => {
          fetchData();
        }, 200);
      }

      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [user, planId, eventDate, dataChanged, isVisible]);

    useEffect(() => {
      const summary = {
        calories: 0,
        fat: 0,
        carbohydrates: 0,
        protein: 0,
        fiber: 0,
        sodium: 0,
      };

      const counts = { mood: 0, state: 0, workout: 0, food: 0 };
      let exerciseCalories = 0;
      const activities = [];

      Object.values(registeredData).forEach((hourData) => {
        hourData.forEach((entry) => {
          if (counts.hasOwnProperty(entry.type)) {
            counts[entry.type] += 1;
          }
          if (entry.type === 'food' && entry.data.nutritionDetails) {
            const { calories, fat, carbohydrates, protein, fiber, sodium } =
              entry.data.nutritionDetails;
            summary.calories += parseFloat(calories) || 0;
            summary.fat += parseFloat(fat) || 0;
            summary.carbohydrates += parseFloat(carbohydrates) || 0;
            summary.protein += parseFloat(protein) || 0;
            summary.fiber += parseFloat(fiber) || 0;
            summary.sodium += parseFloat(sodium) || 0;
          }
          if (entry.type === 'workout' && entry.data.caloriesBurned) {
            exerciseCalories += entry.data.caloriesBurned;
            activities.push({
              workoutType: entry.data.workoutType,
              duration: entry.data.duration,
              caloriesBurned: entry.data.caloriesBurned,
            });
          }
        });
      });

      setDailyNutritionDetail(summary);
      setDailyCalories(summary.calories);
      setDailyCaloriesBurned(exerciseCalories);
      setExerciseActivities(activities);
      setTypeCounts(counts);
    }, [registeredData]);

    const calculateWeekLabel = useCallback(() => {
      const oneDay = 24 * 60 * 60 * 1000;
      const planStartDate = new Date(
        planData?.startDate || planData?.start || planData?.startTime
      );
      const differenceInDays = Math.floor((eventDateObj - planStartDate) / oneDay);

      if (isNaN(differenceInDays)) {
        return '';
      }

      const weekNumber = Math.floor(differenceInDays / 7) + 1;

      if (differenceInDays < 0) {
        return `${eventDateObj.toLocaleDateString('es-ES', {
          weekday: 'long',
        })}: Antes del plan`;
      }

      if (weekNumber <= 4) {
        return `${eventDateObj.toLocaleDateString('es-ES', {
          weekday: 'long',
        })}: Semana ${weekNumber}`;
      } else {
        const monthNumber = Math.floor((weekNumber - 1) / 4) + 1;
        const weekInMonth = ((weekNumber - 1) % 4) + 1;
        return `${eventDateObj.toLocaleDateString('es-ES', {
          weekday: 'long',
        })}: Mes ${monthNumber}, Semana ${weekInMonth}`;
      }
    }, [eventDateObj, planData?.startDate, planData?.start, planData?.startTime]);

    const weekLabel = useMemo(() => calculateWeekLabel(), [calculateWeekLabel]);

    useEffect(() => {
      if (expandedEvent === index && localRef.current) {
        localRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, [expandedEvent, index]);

    const [isPlanDataLoading, setIsPlanDataLoading] = useState(true);

    useEffect(() => {
      if (planData) {
        setIsPlanDataLoading(false);
      }
    }, [planData]);

    return (
      <div
        ref={localRef}
        className={`relative border 
          ${
            isToday
              ? `${getBorderClass('border-blue-500', 'border-blue-500')} ${getBackgroundClass(
                  'bg-blue-50',
                  'bg-blue-900'
                )}`
              : `${getBorderClass('border-gray-300', 'border-gray-700')} ${getBackgroundClass(
                  'bg-white',
                  'bg-gray-800'
                )}`
          } 
          p-4 sm:p-6 rounded-xl cursor-pointer transition-all transform hover:scale-105 
          hover:shadow-2xl
          ${
            theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
          } 
          ${index % 2 === 0 ? 'ml-auto' : 'mr-auto'} 
          ${
            expandedEvent === index ? 'shadow-2xl border-blue-600' : 'shadow-lg'
          } 
          ${theme === 'dark' ? 'text-white' : 'text-gray-800'}
          group
          ${className}
          w-full sm:w-auto
        `}
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d',
          transform: expandedEvent === index ? 'rotateY(0deg)' : 'rotateY(-5deg)',
          transition: 'transform 0.3s ease',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'rotateY(0deg)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform =
            expandedEvent === index ? 'rotateY(0deg)' : 'rotateY(-5deg)';
        }}
        onClick={() => onClick(index)}
      >
        {isPlanDataLoading ? (
          <div className="text-center text-gray-500">Cargando datos del plan...</div>
        ) : showDayTracking ? (
          <div className="max-h-[75vh] sm:max-h-full overflow-y-auto">
            <DayTracking
              onClose={() => setShowDayTracking(false)}
              eventDate={eventDate}
              planId={planId}
              metrics={metrics}
              onDataChange={handleDataChange}
            />
          </div>
        ) : (
          <>
            <EventHeader
              title={event.title}
              date={eventDate}
              onEdit={(e) => {
                e.stopPropagation();
                setShowDayTracking(true);
              }}
              theme={theme}
            />
            {weekLabel && (
              <p
                className={`text-sm font-semibold ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                {weekLabel}
              </p>
            )}
            <p
              className={`text-sm mb-2 ${
                theme === 'dark' ? 'text-gray-100' : 'text-gray-800'
              }`}
            >
              <strong>Este día:</strong> {event.summary}
            </p>

            {metrics && (
              <div className="mt-2 text-sm">
                <strong>Calorías que debes comer hoy:</strong>{' '}
                <span className="font-bold text-lg">
                  {metrics.dailyCaloricIntake}
                </span>{' '}
                calorías
              </div>
            )}

            <div className="flex justify-between items-center mt-4">
              <div className="flex space-x-2">
                <div className="flex items-center space-x-1">
                  <MoodIcon className="w-4 h-4 text-yellow-500" />
                  <span
                    className={`text-xs ${
                      theme === 'dark'
                        ? 'text-yellow-300 group-hover:text-yellow-600'
                        : 'text-yellow-600 group-hover:text-yellow-600'
                    }`}
                  >
                    {typeCounts.mood}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <StateIcon className="w-4 h-4 text-purple-500" />
                  <span
                    className={`text-xs ${
                      theme === 'dark'
                        ? 'text-purple-300 group-hover:text-purple-600'
                        : 'text-purple-600 group-hover:text-purple-600'
                    }`}
                  >
                    {typeCounts.state}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <WorkoutIcon className="w-4 h-4 text-blue-500" />
                  <span
                    className={`text-xs ${
                      theme === 'dark'
                        ? 'text-blue-300 group-hover:text-blue-600'
                        : 'text-blue-600 group-hover:text-blue-600'
                    }`}
                  >
                    {typeCounts.workout}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <FoodIcon className="w-4 h-4 text-green-500" />
                  <span
                    className={`text-xs ${
                      theme === 'dark'
                        ? 'text-green-300 group-hover:text-green-600'
                        : 'text-green-600 group-hover:text-green-600'
                    }`}
                  >
                    {typeCounts.food}
                  </span>
                </div>
              </div>
            </div>

            <div
              className={`mt-4 transition-max-height duration-500 ease-in-out 
                ${
                  expandedEvent === index
                    ? 'max-h-full opacity-100 translate-y-0'
                    : 'max-h-0 opacity-0 -translate-y-5'
                }
              `}
            >
              {expandedEvent === index && (
                <>
                  {metrics ? (
                    <AdditionalMetrics
                      metrics={{
                        ...metrics,
                        currentCalories: dailyCalories,
                        currentCaloriesBurned: dailyCaloriesBurned,
                      }}
                      description={event.description}
                      location={event.location}
                      maxCaloriesBurned={maxCaloriesBurned}
                      nutritionDetail={dailyNutritionDetail}
                      exerciseActivities={exerciseActivities}
                      routine={event.routine}
                    />
                  ) : (
                    <div
                      className={`mt-4 text-sm ${
                        theme === 'dark' ? 'text-gray-100' : 'text-gray-800'
                      }`}
                    >
                      <p>No hay métricas disponibles para este evento.</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);

export default TarjetaDeTimeline;
