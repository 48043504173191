// src/components/useOnScreen.js

import { useState, useEffect } from 'react';

/**
 * Hook para detectar si un elemento está visible en el viewport.
 *
 * @param {React.RefObject} ref - Referencia al elemento a observar.
 * @param {string} rootMargin - Margen adicional para pre-cargar (por defecto '0px').
 * @returns {boolean} - Retorna `true` si el elemento está visible, de lo contrario `false`.
 */
const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const currentElement = ref.current; // Captura la referencia actual

    if (!currentElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin }
    );

    observer.observe(currentElement);

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [ref, rootMargin]); // Mantén las dependencias como están

  return isIntersecting;
};

export default useOnScreen;
