// src/components/CalendarComponent.js

import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarTailwind.css';
import { useTheme } from '../../ThemeContext';
import {
  FaRegCalendarCheck,
  FaTimes,
  FaClock,
  FaBullseye,
  FaInfoCircle,
  FaDumbbell,
  FaGlobe,
} from 'react-icons/fa';
import { GiPush, GiPull, GiLeg, GiNightSleep } from 'react-icons/gi';
import { usePlan } from '../../contexts/PlanContext';

const CalendarComponent = () => {
  const { theme } = useTheme();
  const {
    dateRange,
    onDayClick,
    tileClassName,
    tileDisabled,
    rangeSelected,
    ranges,
    activeRange,
    cancelRangeSelection,
    handleRangeClick,
    planCreated,
    handleCreatePlan,
    handleDeletePlan,
    caloricIntake,
    setCaloricIntake,
    useCreatine,
    setUseCreatine,
    error,
    isPlanActive,
  } = usePlan();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [location, setLocation] = useState('Obteniendo ubicación...');
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(new Date());
    };

    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocation('Geolocalización no es soportada por tu navegador.');
      return;
    }

    const success = async (position) => {
      const { latitude, longitude } = position.coords;
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
        );
        const data = await response.json();
        setLocation(data.display_name || 'Ubicación no encontrada.');
      } catch {
        setLocation('Error al obtener la ubicación.');
      }
    };

    const errorHandler = () => setLocation('No se pudo obtener tu ubicación.');
    navigator.geolocation.getCurrentPosition(success, errorHandler);
  }, []);

  const calculateDays = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = end - start;
    if (isNaN(diffTime)) return 'Fecha inválida';
    return Math.round(Math.abs(diffTime) / oneDay) + 1;
  };

  const handleCreatePlanWithInfo = async () => {
    setIsLoading(true);
    try {
      await handleCreatePlan();
      setShowPlanInfo(true);
    } catch (err) {
      console.error('Error al crear el plan:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePlanWithInfo = async () => {
    setIsLoading(true);
    await handleDeletePlan();
    setIsLoading(false);
    setShowPlanInfo(false);
  };

  const selectBgColor = planCreated
    ? theme === 'dark'
      ? 'bg-gray-700 cursor-not-allowed'
      : 'bg-gray-200 cursor-not-allowed'
    : theme === 'dark'
    ? 'bg-gray-800 hover:bg-gray-700'
    : 'bg-gray-100 hover:bg-gray-200';

  const selectTextColor = theme === 'dark' ? 'text-gray-100' : 'text-gray-900';

  // Mapear actividades a iconos más representativos
  const activityIcons = {
    'Empuje': <GiPush />,
    'Tirón': <GiPull />,
    'Piernas': <GiLeg />,
    'Descanso': <GiNightSleep />,
  };

  // Lista de actividades
  const activities = ['Empuje', 'Tirón', 'Piernas', 'Descanso'];

  // Función para obtener la actividad de una fecha
  const getActivityForDate = (date) => {
    if (!planCreated || dateRange.length < 2) return null;

    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    if (date < startDate || date > endDate) return null;

    const dayDifference = Math.floor((date - startDate) / (1000 * 60 * 60 * 24));
    const activityIndex = dayDifference % activities.length;
    return activities[activityIndex];
  };

  // Crear una función tileContent para mostrar los iconos
  const tileContent = ({ date, view }) => {
    if (view !== 'month') return null;

    const activityName = getActivityForDate(date);
    if (activityName && activityIcons[activityName]) {
      return (
        <div className="event-marker flex justify-center items-center">
          {activityIcons[activityName]}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`calendar-container w-full max-w-4xl mx-auto p-6 rounded-lg shadow-md flex flex-col items-center transition-colors duration-300 ${
        theme === 'dark' ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-900'
      }`}
    >
      {/* Información de Fecha y Ubicación */}
      <div className="w-full flex flex-col items-center mb-6 space-y-4">
        <div
          className={`p-4 rounded-lg shadow-md w-full sm:w-2/3 flex items-center space-x-4 transition-transform transform hover:scale-105`}
          style={{
            backgroundColor: theme === 'dark' ? '#2d3748' : '#f7fafc',
          }}
        >
          <FaClock className="text-3xl text-blue-500" />
          <div>
            <span className="text-lg font-semibold">
              {currentDateTime.toLocaleDateString('es-ES', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </span>
            <br />
            <span
              className={`text-sm ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
              }`}
            >
              {currentDateTime.toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}
            </span>
          </div>
        </div>
        <div
          className={`p-4 rounded-lg shadow-md w-full sm:w-2/3 flex items-center space-x-4 transition-transform transform hover:scale-105`}
          style={{
            backgroundColor: theme === 'dark' ? '#2d3748' : '#f7fafc',
          }}
        >
          <FaGlobe className="text-3xl text-green-500" />
          <div>
            <span className="text-lg font-semibold">{location}</span>
          </div>
        </div>
      </div>

      {/* Componente de Calendario */}
      <div className="w-full">
        <Calendar
          value={
            planCreated && dateRange.length === 2
              ? [new Date(dateRange[0]), new Date(dateRange[1])]
              : dateRange
          }
          selectRange={!planCreated}
          onChange={(range) => onDayClick(range)}
          tileContent={tileContent}
          tileClassName={tileClassName}
          tileDisabled={tileDisabled}
          minDetail="month"
          maxDetail="month"
          className={`w-full rounded-lg p-2 shadow-inner transition-transform transform hover:scale-100 ${
            theme === 'dark' ? 'calendar-dark' : ''
          }`}
        />
      </div>

      {/* Selector de Rangos */}
      {!rangeSelected && !planCreated && (
        <div className="flex flex-wrap justify-center gap-4 mt-6">
          {ranges.map((range) => (
            <button
              key={range.days}
              onClick={() => handleRangeClick(range.days)}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg shadow-md transition-transform transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                activeRange === range.days
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
            >
              <FaRegCalendarCheck />
              {range.label}
            </button>
          ))}
        </div>
      )}

      {/* Botón de Cancelación de Selección */}
      {rangeSelected && !isPlanActive && (
        <button
          onClick={cancelRangeSelection}
          className="mt-4 flex items-center gap-2 text-sm text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 rounded"
          title="Cancelar selección de rango"
        >
          <FaTimes className="w-4 h-4" />
          <span>Cancelar selección</span>
        </button>
      )}

      {/* PlanCreator UI */}
      <div className="w-full mt-6">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          {/* Objetivo */}
          <div className="flex flex-col">
            <label htmlFor="caloric-intake" className="text-sm mb-1 flex items-center">
              <FaBullseye className="mr-1" />
              Mi objetivo
            </label>
            <select
              id="caloric-intake"
              value={caloricIntake}
              onChange={(e) => setCaloricIntake(e.target.value)}
              disabled={planCreated}
              className={`p-2 rounded ${selectBgColor} ${selectTextColor} focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200`}
            >
              <option value="defin">Bajar de Peso</option>
              <option value="recomp">Ganar Músculo</option>
              <option value="volum">Subir de Peso</option>
            </select>
          </div>

          {/* Uso de Creatina */}
          <div className="flex items-center mt-4 sm:mt-0">
            <input
              type="checkbox"
              id="use-creatine"
              checked={useCreatine}
              onChange={(e) => setUseCreatine(e.target.checked)}
              disabled={planCreated}
              className={`cursor-pointer h-5 w-5 ${
                planCreated ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            />
            <label htmlFor="use-creatine" className="text-sm ml-2 flex items-center">
              <FaDumbbell className="mr-1" />
              Usar Creatina
            </label>
          </div>

          {/* Botones de Acción */}
          <div className="flex space-x-2 mt-4 sm:mt-0">
            <button
              onClick={handleCreatePlanWithInfo}
              disabled={!dateRange[0] || !dateRange[1] || planCreated || isLoading}
              className={`w-full px-4 py-2 rounded bg-green-500 text-white font-semibold hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed transition-colors duration-200`}
            >
              {isLoading ? 'Creando...' : 'Crear Plan'}
            </button>
            <button
              onClick={handleDeletePlanWithInfo}
              disabled={!planCreated || isLoading}
              className={`w-full px-4 py-2 rounded bg-red-500 text-white font-semibold hover:bg-red-600 disabled:bg-red-300 disabled:cursor-not-allowed transition-colors duration-200`}
            >
              {isLoading ? 'Procesando...' : 'Eliminar Plan'}
            </button>
          </div>
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>

      {/* Tarjeta de Información del Plan */}
      {showPlanInfo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className={`rounded-lg shadow-lg p-6 w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 animate-fade-in transition-transform transform ${
              theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'
            }`}
          >
            <h2 className="text-2xl font-bold mb-4 flex items-center justify-center">
              <FaInfoCircle className="mr-2" />
              Información del Plan
            </h2>
            <div className="space-y-2">
              <p>
                <strong>Fecha de Inicio:</strong>{' '}
                {dateRange[0]
                  ? new Date(dateRange[0]).toLocaleDateString('es-ES')
                  : 'N/A'}
              </p>
              <p>
                <strong>Fecha de Fin:</strong>{' '}
                {dateRange[1]
                  ? new Date(dateRange[1]).toLocaleDateString('es-ES')
                  : 'N/A'}
              </p>
              <p>
                <strong>Cantidad de Días:</strong>{' '}
                {dateRange[0] && dateRange[1]
                  ? calculateDays(dateRange[0], dateRange[1])
                  : 'N/A'}
              </p>
              <p>
                <strong>Tipo de Plan:</strong>{' '}
                {caloricIntake === 'defin'
                  ? 'Bajar de Peso'
                  : caloricIntake === 'recomp'
                  ? 'Ganar Músculo'
                  : 'Subir de Peso'}
              </p>
              <p>
                <strong>Usa Creatina:</strong> {useCreatine ? 'Sí' : 'No'}
              </p>
            </div>
            <div className="mt-6 flex justify-center">
              <button
                onClick={() => setShowPlanInfo(false)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
