import React, { useState, useRef, useEffect } from 'react';
import Recorder from 'recorder-js';
import heartIcon from './heart.svg';
import { useTheme } from '../../../ThemeContext';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';

const AudioRecorder = ({ stream }) => {
  const { theme } = useTheme();
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [currentRecording, setCurrentRecording] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  
  const audioContextRef = useRef(null);
  const recorderRef = useRef(null);
  const wavesurferRef = useRef(null);
  const waveformContainerRef = useRef(null);

  const base64ToBlob = (base64) => {
    try {
      const parts = base64.split(';base64,');
      if (parts.length !== 2) {
        throw new Error('Invalid base64 format');
      }
      const mime = parts[0].split(':')[1];
      const bstr = atob(parts[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime || 'audio/wav' });
    } catch (error) {
      console.error('Error in base64ToBlob:', error);
      return null;
    }
  };

  useEffect(() => {
    const loadRecordings = () => {
      const storedRecordings = JSON.parse(localStorage.getItem('recordings')) || [];
      console.log('Stored Recordings:', storedRecordings);
      const updatedRecordings = storedRecordings.map((rec) => {
        if (rec.base64) {
          const blob = base64ToBlob(rec.base64);
          if (blob) {
            const url = URL.createObjectURL(blob);
            return { ...rec, url };
          } else {
            console.warn('Invalid Blob for recording:', rec);
            return rec;
          }
        }
        return rec;
      }).filter(rec => rec.url);
      console.log('Updated Recordings with URLs:', updatedRecordings);
      setRecordings(updatedRecordings);
    };
    loadRecordings();
  }, []);

  useEffect(() => {
    if (!stream) return;

    const initRecorder = async () => {
      try {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;

        const recorder = new Recorder(audioContext, {
          numberOfChannels: 1,
        });
        await recorder.init(stream);
        recorderRef.current = recorder;
        console.log('Recorder initialized.');
      } catch (err) {
        console.error('Error initializing recorder:', err);
        setError('Error initializing audio recorder. Please check your device settings.');
      }
    };

    initRecorder();

    return () => {
      if (recorderRef.current) {
        recorderRef.current.stop();
        console.log('Recorder stopped.');
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
        console.log('AudioContext closed.');
      }
    };
  }, [stream]);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.error('Error reading Blob as Base64:', error);
          reject(error);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error in blobToBase64:', error);
        reject(error);
      }
    });
  };

  const startRecording = async () => {
    if (isRecording || !recorderRef.current) return;

    try {
      // For mobile Safari, we need to resume the AudioContext
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      await recorderRef.current.start();
      setIsRecording(true);
      setError(null);
      console.log('Recording started.');
    } catch (err) {
      console.error('Error starting recording:', err);
      setError('Failed to start recording. Please try again.');
    }
  };

  const stopRecording = async () => {
    if (!isRecording || !recorderRef.current) return;

    try {
      const { blob } = await recorderRef.current.stop();
      console.log('Recording stopped. Blob:', blob);
      const base64Audio = await blobToBase64(blob);
      const url = URL.createObjectURL(blob);
      console.log('New Recording:', { base64Audio, url });
      const newRecording = {
        id: Date.now(),
        url,
        base64: base64Audio,
        timestamp: new Date().toISOString(),
      };
      const updatedRecordings = [newRecording, ...recordings];
      setRecordings(updatedRecordings);
      localStorage.setItem('recordings', JSON.stringify(updatedRecordings));
      setIsRecording(false);
      setError(null);
    } catch (err) {
      console.error('Error stopping recording:', err);
      setError('Failed to stop recording. Please try again.');
    }
  };

  const downloadAudio = (recording) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = recording.url;
    a.download = `recording_${new Date(recording.timestamp).toLocaleDateString().replace(/\//g, '-')}_${new Date(recording.timestamp).toLocaleTimeString().replace(/:/g, '-')}.wav`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    console.log('Recording downloaded:', recording.download);
  };

  const selectRecording = (recording) => {
    console.log('Selecting Recording:', recording);
    setCurrentRecording(recording);
  };

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
      console.log(`Playback ${isPlaying ? 'paused' : 'playing'}.`);
    }
  };

  const deleteRecording = (recordingId) => {
    const recordingToDelete = recordings.find(rec => rec.id === recordingId);
    if (!recordingToDelete) {
      console.warn('Recording not found:', recordingId);
      return;
    }

    const confirmDelete = window.confirm('Are you sure you want to delete this recording?');
    if (!confirmDelete) return;

    const updatedRecordings = recordings.filter(rec => rec.id !== recordingId);
    setRecordings(updatedRecordings);
    localStorage.setItem('recordings', JSON.stringify(updatedRecordings));
    console.log('Recording deleted:', recordingId);

    if (currentRecording && currentRecording.id === recordingId) {
      if (wavesurferRef.current) {
        wavesurferRef.current.stop();
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
        console.log('WaveSurfer instance destroyed due to deletion.');
      }
      setCurrentRecording(null);
      setIsPlaying(false);
    }

    setTimeout(() => {
      if (recordingToDelete.url) {
        URL.revokeObjectURL(recordingToDelete.url);
        console.log('Object URL revoked:', recordingToDelete.url);
      }
    }, 1000);
  };

  useEffect(() => {
    if (!currentRecording || !waveformContainerRef.current) return;

    console.log('Initializing WaveSurfer with URL:', currentRecording.url);

    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
      console.log('Existing WaveSurfer instance destroyed.');
    }

    wavesurferRef.current = WaveSurfer.create({
      container: waveformContainerRef.current,
      waveColor: theme === 'dark' ? '#ffffff' : '#4a5568',
      progressColor: '#3182ce',
      height: 80,
      responsive: true,
    });

    wavesurferRef.current.load(currentRecording.url);

    wavesurferRef.current.on('ready', () => {
      console.log('WaveSurfer is ready to play:', currentRecording.url);
      wavesurferRef.current.play();
      setIsPlaying(true);
    });

    wavesurferRef.current.on('finish', () => {
      console.log('Playback finished.');
      setIsPlaying(false);
    });

    wavesurferRef.current.on('error', (error) => {
      console.error('WaveSurfer Error:', error);
      setIsPlaying(false);
      setError('Error playing the audio. Please try again.');
    });

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        console.log('WaveSurfer instance destroyed on cleanup.');
      }
    };
  }, [currentRecording, theme]);

  useEffect(() => {
    return () => {
      recordings.forEach((rec) => {
        if (rec.url) {
          URL.revokeObjectURL(rec.url);
          console.log('Object URL revoked on unmount:', rec.url);
        }
      });
    };
  }, [recordings]);

  return (
    <div
      className="mt-8 p-4 rounded-lg shadow-md"
      style={{ backgroundColor: theme === 'dark' ? '#2d3748' : '#f9fafb' }}
    >
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className="flex items-center justify-center mb-4 focus:outline-none"
        disabled={!stream}
        title={
          !stream
            ? 'No audio stream available'
            : isRecording
            ? 'Stop recording'
            : 'Start recording'
        }
      >
        <img
          src={heartIcon}
          alt={isRecording ? 'Stop recording' : 'Start recording'}
          className={`w-8 h-8 transition-transform duration-300 ${
            isRecording ? 'animate-pulse' : ''
          }`}
        />
      </button>
      <div className="text-center">
        {isRecording ? (
          <p className="text-sm text-gray-500">Recording audio...</p>
        ) : (
          <p className="text-sm text-gray-500">
            Click the heart to start recording
          </p>
        )}
      </div>

      {error && (
        <div className="mt-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          {error}
        </div>
      )}

      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">Recordings</h3>
        {recordings.length === 0 ? (
          <p className="text-sm text-gray-500">No recordings available.</p>
        ) : (
          <ul className="space-y-4">
            {recordings.map((recording) => (
              <li
                key={recording.id}
                className="p-4 rounded-lg shadow-sm flex justify-between items-center"
                style={{ backgroundColor: theme === 'dark' ? '#4a5568' : '#ffffff' }}
              >
                <div>
                  <p className="text-sm text-gray-700">
                    {new Date(recording.timestamp).toLocaleString()}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => selectRecording(recording)}
                    className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    Play
                  </button>
                  <button
                    onClick={() => downloadAudio(recording)}
                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Download
                  </button>
                  <button
                    onClick={() => deleteRecording(recording.id)}
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    title="Delete recording"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {currentRecording && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Now Playing:</h3>
          <div ref={waveformContainerRef} className="w-full"></div>
          <button
            onClick={togglePlayPause}
            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 focus:outline-none"
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
        </div>
      )}
    </div>
  );
};

AudioRecorder.propTypes = {
  stream: PropTypes.instanceOf(MediaStream),
};

export default AudioRecorder;