// storage.js

export const getSavedData = (key, initialData) => {
  return JSON.parse(localStorage.getItem(key)) || initialData;
};

export const saveData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeData = (key) => {
  localStorage.removeItem(key);
};
