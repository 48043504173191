import { useState, useEffect, useCallback, useRef } from 'react';
import { getSavedData, saveData, removeData } from './storage';
import { useAuth } from '../../contexts/AuthContext';
// Importa las funciones del servicio API
import { getHealthMetrics, saveHealthMetrics, deleteHealthMetrics } from './apiService';

const useDataManagement = (initialData) => {
  const { user, loading } = useAuth();

  const [savedData, setSavedData] = useState(() => {
    return getSavedData('healthMetrics', initialData);
  });

  const [formData, setFormData] = useState(savedData);
  const [isSaved, setIsSaved] = useState(true);
  const [isSynced, setIsSynced] = useState(false);

  const prevUserRef = useRef();
  const hasFetchedData = useRef(false);

  const fetchData = useCallback(async () => {
    const data = await getHealthMetrics();
    if (data) {
      setFormData(data);
      saveData('healthMetrics', data);
      setIsSynced(true);
    } else {
      setFormData(savedData);
    }
  }, [savedData]);

  useEffect(() => {
    if (user && !loading && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [user, loading, fetchData]);

  useEffect(() => {
    const isDataChanged = JSON.stringify(formData) !== JSON.stringify(savedData);
    setIsSaved(!isDataChanged);
  }, [formData, savedData]);

  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (prevUser && !user && !loading) {
      removeData('healthMetrics');
      setFormData(initialData);
      setSavedData(initialData);
      setIsSaved(true);
      setIsSynced(false);
      hasFetchedData.current = false;
    } else if (!prevUser && user && !loading) {
      hasFetchedData.current = false;
    }
    prevUserRef.current = user;
  }, [user, loading, initialData]);

  const saveFormData = async (updatedData) => {
    saveData('healthMetrics', updatedData);
    setFormData(updatedData);
    setSavedData(updatedData);
    setIsSaved(true);

    if (user) {
      const data = await saveHealthMetrics(updatedData);
      if (data) {
        setFormData(data);
        saveData('healthMetrics', data);
        setIsSynced(true);
      }
    }
  };

  const handleDelete = async (isPlanActive) => {
    if (isPlanActive) {
      alert('No puedes eliminar tus datos mientras exista un plan activo. Por favor, elimina el plan primero.');
      return;
    }
    setFormData(initialData);
    setSavedData(initialData);
    removeData('healthMetrics');
    setIsSaved(true);

    if (user) {
      const success = await deleteHealthMetrics();
      if (success) {
        setIsSynced(false);
      }
    }
  };

  return {
    formData,
    setFormData,
    savedData,
    setSavedData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
    loading,
    isSynced,
    setIsSynced,
  };
};

export default useDataManagement;
