// AssistantChat.js
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import assistantIcon from './assistant.svg';
import { useTheme } from '../ThemeContext';
import ChatWindow from './ChatWindow';

// Importar Framer Motion
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';

// Importar react-swipeable
import { useSwipeable } from 'react-swipeable';

// Importar el hook useAuth
import { useAuth } from '../contexts/AuthContext';

// Importar el hook useChat
import { useChat } from '../contexts/ChatContext';

// Función auxiliar para verificar si existe un nombre de chat
const doesChatNameExist = (name, chats) => {
  return chats.some((chat) => chat.name === name);
};

const AssistantChat = ({ isOpen, toggleChat }) => {
  const { theme } = useTheme();

  // Obtener el estado de autenticación y la función de login
  const { user, loading, login } = useAuth();

  // Obtener chats y funciones del contexto
  const {
    chats,
    addChat,
    updateChat,
    deleteChat,
    currentChatId,
    setCurrentChatId,
    isLoading,
    sendMessage,
  } = useChat();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [input, setInput] = useState('');

  // Referencia para la barra lateral
  const sidebarRef = useRef(null);

  // Referencia para el botón de toggle
  const toggleButtonRef = useRef(null);

  // Referencia para el botón de assistant
  const buttonRef = useRef(null);

  // Motion values for x and y positions
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  // Bloquear el scroll del fondo cuando el chat está abierto
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  // Detector de clics fuera de la barra lateral
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  // Función para generar un nombre de chat único
  const generateUniqueChatName = () => {
    const baseName = 'Nueva Conversación';
    let newName = baseName;
    let counter = 1;

    while (doesChatNameExist(newName, chats)) {
      counter += 1;
      newName = `${baseName} ${counter}`;
    }

    return newName;
  };

  const handleNewChat = () => {
    const newName = generateUniqueChatName();

    const newChat = {
      id: uuidv4(),
      name: newName,
      messages: [],
    };
    addChat(newChat);
    setCurrentChatId(newChat.id);
    setIsSidebarOpen(true);
  };

  const handleSelectChat = (id) => {
    setCurrentChatId(id);
    setIsSidebarOpen(false); // Opcional: Ocultar la barra lateral al seleccionar un chat
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  // Función para renombrar un chat
  const handleRenameChat = (id, newName) => {
    if (!newName || newName.trim() === '') {
      alert('El nombre del chat no puede estar vacío.');
      return;
    }

    // Verificar si el nuevo nombre ya existe
    if (chats.some((chat) => chat.name === newName && chat.id !== id)) {
      alert('Ya existe un chat con ese nombre. Por favor, elige otro nombre.');
      return;
    }

    const updatedChat = chats.find((chat) => chat.id === id);
    if (updatedChat) {
      updateChat(id, { ...updatedChat, name: newName.trim() });
    }
  };

  // Función para eliminar un chat con la validación requerida
  const handleDeleteChat = (id) => {
    deleteChat(id);

    if (id === currentChatId) {
      const remainingChats = chats.filter((chat) => chat.id !== id);
      if (remainingChats.length > 0) {
        setCurrentChatId(remainingChats[0].id);
      } else {
        setCurrentChatId(null); // Opcional: Manejar el estado sin chats
      }
    }
  };

  const currentChat = chats.find((chat) => chat.id === currentChatId);

  const handleSend = () => {
    if (input.trim() === '') return;
    sendMessage(input);
    setInput('');
  };

  // Configuración de los manejadores de swipe
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    },
    onSwipedRight: () => {
      if (!isSidebarOpen) {
        setIsSidebarOpen(true);
      }
    },
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  // Si la autenticación está cargando, puedes mostrar un indicador de carga o retornar null
  if (loading) {
    return null; // O puedes retornar un componente de carga si lo prefieres
  }

  return (
    <div>
      {/* Botón para abrir/cerrar el chat, ahora es draggable */}
      <motion.button
        ref={buttonRef}
        onClick={toggleChat}
        className="fixed bottom-4 left-4 w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full hover:bg-black hover:bg-opacity-20 flex items-center justify-center focus:outline-none z-40 transition-colors duration-200"
        aria-label={isOpen ? 'Cerrar chat' : 'Abrir chat'}
        drag
        dragMomentum={false}
        dragElastic={0}
        style={{ x, y }}
        onDragEnd={() => {
          const buttonRect = buttonRef.current.getBoundingClientRect();
          const buttonWidth = buttonRect.width;
          const buttonHeight = buttonRect.height;

          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;

          const currentX = x.get(); // Valor actual de x
          const currentY = y.get(); // Valor actual de y

          const initialLeft = 16; // Posición inicial desde la izquierda (left-4 en Tailwind CSS es 16px)
          const initialTop = screenHeight - buttonHeight - 16; // Posición inicial desde la parte superior

          const buttonLeft = initialLeft + currentX;
          const buttonTop = initialTop + currentY;

          const distanceToLeft = buttonLeft;
          const distanceToRight = screenWidth - (buttonLeft + buttonWidth);
          const distanceToTop = buttonTop;
          const distanceToBottom = screenHeight - (buttonTop + buttonHeight);

          const distances = [
            { edge: 'left', distance: distanceToLeft },
            { edge: 'right', distance: distanceToRight },
            { edge: 'top', distance: distanceToTop },
            { edge: 'bottom', distance: distanceToBottom },
          ];

          // Encontrar el borde más cercano
          const nearestEdge = distances.reduce((prev, curr) => {
            return prev.distance < curr.distance ? prev : curr;
          });

          if (nearestEdge.edge === 'left') {
            x.set(0); // Ajustar a la izquierda
          } else if (nearestEdge.edge === 'right') {
            const finalX = screenWidth - buttonWidth - 16; // Posición final deseada desde la izquierda
            x.set(finalX - initialLeft); // Ajustar x basado en la diferencia
          } else if (nearestEdge.edge === 'top') {
            const finalY = 16; // Posición final deseada desde la parte superior
            y.set(finalY - initialTop); // Ajustar y basado en la diferencia
          } else if (nearestEdge.edge === 'bottom') {
            y.set(0); // Ajustar a la parte inferior
          }
        }}
      >
        <img
          src={assistantIcon}
          alt="Assistant"
          className="w-12 h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
        />
      </motion.button>

      {/* Componente ChatWindow */}
      <AnimatePresence>
        {isOpen &&
          (user ? (
            <ChatWindow
              key="chat-window" // Añadir una key única
              handlers={handlers}
              theme={theme}
              toggleChat={toggleChat}
              isSidebarOpen={isSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
              sidebarRef={sidebarRef}
              toggleButtonRef={toggleButtonRef} // Pasar la referencia del botón
              chats={chats}
              currentChatId={currentChatId}
              handleSelectChat={handleSelectChat}
              handleNewChat={handleNewChat}
              handleRenameChat={handleRenameChat}
              handleDeleteChat={handleDeleteChat}
              currentChat={currentChat}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
              isLoading={isLoading} // Pasar el estado de loading
            />
          ) : (
            // Si no está autenticado, mostrar un mensaje para iniciar sesión
            <div
              key="login-message" // Añadir una key única
              className={`fixed bottom-20 left-4 right-4 sm:right-auto sm:bottom-auto sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 z-50`}
            >
              <p className="text-center text-gray-800 dark:text-gray-200 mb-4">
                Por favor, inicia sesión para utilizar el chat.
              </p>
              <button
                onClick={login}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
              >
                Iniciar Sesión
              </button>
            </div>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default AssistantChat;
