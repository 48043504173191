// foodDataset.js
const foodDataset = [
    { name: 'Pechuga de Pollo (Asada)', servingSize: '100g', calories: 165, protein: 31, carbohydrates: 0, fat: 3.6, fiber: 0, sodium: 74 },
    { name: 'Brócoli (Cocido al Vapor)', servingSize: '1 taza (91g)', calories: 55, protein: 3.7, carbohydrates: 11.2, fat: 0.6, fiber: 5.1, sodium: 30 },
    { name: 'Arroz Integral (Cocido)', servingSize: '1 taza (195g)', calories: 216, protein: 5, carbohydrates: 45, fat: 1.8, fiber: 3.5, sodium: 10 },
    { name: 'Almendras (Crudas)', servingSize: '1 oz (28g)', calories: 164, protein: 6, carbohydrates: 6.1, fat: 14.2, fiber: 3.5, sodium: 0 },
    { name: 'Salmón (Horneado)', servingSize: '100g', calories: 208, protein: 20, carbohydrates: 0, fat: 13, fiber: 0, sodium: 59 },
    { name: 'Plátano', servingSize: '1 mediano (118g)', calories: 105, protein: 1.3, carbohydrates: 27, fat: 0.3, fiber: 3.1, sodium: 1 },
    { name: 'Avena (Cocida)', servingSize: '1 taza (234g)', calories: 154, protein: 6, carbohydrates: 27, fat: 2.8, fiber: 4, sodium: 2 },
    { name: 'Huevo (Cocido)', servingSize: '1 grande (50g)', calories: 78, protein: 6.3, carbohydrates: 0.6, fat: 5.3, fiber: 0, sodium: 62 },
    { name: 'Batata (Horneada)', servingSize: '1 mediana (130g)', calories: 112, protein: 2, carbohydrates: 26, fat: 0.1, fiber: 4, sodium: 70 },
    { name: 'Yogur Griego (Natural, sin grasa)', servingSize: '1 taza (245g)', calories: 100, protein: 10, carbohydrates: 6, fat: 0, fiber: 0, sodium: 60 },
  
    { name: 'Manzana', servingSize: '1 mediana (182g)', calories: 95, protein: 0.5, carbohydrates: 25, fat: 0.3, fiber: 4.4, sodium: 1 },
    { name: 'Zanahoria (Cruda)', servingSize: '1 mediana (61g)', calories: 25, protein: 0.6, carbohydrates: 6, fat: 0.1, fiber: 1.7, sodium: 42 },
    { name: 'Papa (Horneada, con piel)', servingSize: '1 mediana (173g)', calories: 161, protein: 4.3, carbohydrates: 37, fat: 0.2, fiber: 3.8, sodium: 17 },
    { name: 'Pepino (Crudo, sin piel)', servingSize: '1 taza (104g)', calories: 16, protein: 0.7, carbohydrates: 3.8, fat: 0.1, fiber: 0.5, sodium: 2 },
    { name: 'Espinaca (Cocida)', servingSize: '1 taza (180g)', calories: 41, protein: 5.4, carbohydrates: 6.8, fat: 0.5, fiber: 4.3, sodium: 126 },
    { name: 'Naranja', servingSize: '1 mediana (131g)', calories: 62, protein: 1.2, carbohydrates: 15.4, fat: 0.2, fiber: 3.1, sodium: 0 },
    { name: 'Frijoles Negros (Cocidos)', servingSize: '1 taza (172g)', calories: 227, protein: 15.2, carbohydrates: 40.8, fat: 0.9, fiber: 15, sodium: 1 },
    { name: 'Pasta Integral (Cocida)', servingSize: '1 taza (140g)', calories: 174, protein: 7.5, carbohydrates: 37, fat: 0.8, fiber: 6.3, sodium: 4 },
    { name: 'Palta (Aguacate)', servingSize: '1 mediano (150g)', calories: 240, protein: 3, carbohydrates: 12.8, fat: 22, fiber: 10.1, sodium: 10 },
    { name: 'Leche (Entera)', servingSize: '1 taza (244g)', calories: 149, protein: 7.7, carbohydrates: 12.3, fat: 8, fiber: 0, sodium: 98 },
  
    { name: 'Queso Cheddar', servingSize: '1 oz (28g)', calories: 113, protein: 6.4, carbohydrates: 0.4, fat: 9.3, fiber: 0, sodium: 174 },
    { name: 'Mantequilla de Maní', servingSize: '2 cucharadas (32g)', calories: 190, protein: 8, carbohydrates: 7, fat: 16, fiber: 2, sodium: 140 },
    { name: 'Carne Molida (Magro, cocida)', servingSize: '100g', calories: 250, protein: 26, carbohydrates: 0, fat: 15, fiber: 0, sodium: 75 },
    { name: 'Atún en Agua (enlatado)', servingSize: '1 lata (165g)', calories: 191, protein: 42, carbohydrates: 0, fat: 1.4, fiber: 0, sodium: 580 },
    { name: 'Jugo de Naranja', servingSize: '1 taza (240ml)', calories: 112, protein: 2, carbohydrates: 26, fat: 0.5, fiber: 0.5, sodium: 2 },
    { name: 'Tortilla de Maíz', servingSize: '1 mediana (24g)', calories: 52, protein: 1.4, carbohydrates: 10.7, fat: 0.7, fiber: 1.5, sodium: 11 },
    { name: 'Aceite de Oliva', servingSize: '1 cucharada (14g)', calories: 119, protein: 0, carbohydrates: 0, fat: 13.5, fiber: 0, sodium: 0 },
    { name: 'Café (Negro)', servingSize: '1 taza (237ml)', calories: 2, protein: 0.3, carbohydrates: 0, fat: 0, fiber: 0, sodium: 5 },
    { name: 'Lechuga (Romaine)', servingSize: '1 taza (47g)', calories: 8, protein: 0.6, carbohydrates: 1.5, fat: 0.1, fiber: 1, sodium: 4 },
    { name: 'Helado de Vainilla', servingSize: '1/2 taza (66g)', calories: 137, protein: 2.3, carbohydrates: 15.5, fat: 7.3, fiber: 0.5, sodium: 53 },
    { name: 'Arepa (de Maíz)', servingSize: '1 mediana (60g)', calories: 120, protein: 2.4, carbohydrates: 26, fat: 1.5, fiber: 2.5, sodium: 10 },
    { name: 'Tamal', servingSize: '1 unidad (300g)', calories: 350, protein: 10, carbohydrates: 45, fat: 15, fiber: 4, sodium: 600 },
    { name: 'Arroz Blanco (Cocido)', servingSize: '1 taza (186g)', calories: 242, protein: 4.4, carbohydrates: 53, fat: 0.4, fiber: 0.6, sodium: 0 },
    { name: 'Frijoles (Cocidos)', servingSize: '1 taza (172g)', calories: 245, protein: 15.4, carbohydrates: 40.4, fat: 0.8, fiber: 13.6, sodium: 2 },
    { name: 'Ceviche (de Pescado)', servingSize: '1 taza (180g)', calories: 140, protein: 24, carbohydrates: 7, fat: 2, fiber: 1.2, sodium: 320 },
    { name: 'Chicharrón', servingSize: '1 porción (50g)', calories: 260, protein: 22, carbohydrates: 0, fat: 19, fiber: 0, sodium: 500 },
    { name: 'Tacos (Carne Asada)', servingSize: '1 unidad (150g)', calories: 250, protein: 14, carbohydrates: 26, fat: 10, fiber: 2, sodium: 400 },
    { name: 'Tortilla de Harina', servingSize: '1 mediana (40g)', calories: 130, protein: 3, carbohydrates: 22, fat: 3.5, fiber: 1.2, sodium: 280 },
    { name: 'Yuca (Cocida)', servingSize: '1 taza (150g)', calories: 178, protein: 1.4, carbohydrates: 42, fat: 0.3, fiber: 1.8, sodium: 13 },
    { name: 'Plátano Maduro (Frito)', servingSize: '1 mediano (130g)', calories: 220, protein: 1.2, carbohydrates: 58, fat: 8, fiber: 2.3, sodium: 3 },
    
    { name: 'Empanada (de Carne)', servingSize: '1 unidad (150g)', calories: 300, protein: 8, carbohydrates: 32, fat: 15, fiber: 2.5, sodium: 450 },
    { name: 'Pozole (de Cerdo)', servingSize: '1 taza (240g)', calories: 220, protein: 12, carbohydrates: 26, fat: 8, fiber: 3, sodium: 550 },
    { name: 'Churrasco', servingSize: '1 porción (200g)', calories: 400, protein: 32, carbohydrates: 0, fat: 30, fiber: 0, sodium: 75 },
    { name: 'Guacamole', servingSize: '1/4 taza (60g)', calories: 90, protein: 1.5, carbohydrates: 4, fat: 7.5, fiber: 3.5, sodium: 150 },
    { name: 'Sopa de Lentejas', servingSize: '1 taza (240g)', calories: 185, protein: 12, carbohydrates: 33, fat: 1.5, fiber: 7, sodium: 320 },
    { name: 'Mole Poblano', servingSize: '1/4 taza (60g)', calories: 180, protein: 3, carbohydrates: 18, fat: 10, fiber: 3, sodium: 250 },
    { name: 'Flan (de Caramelo)', servingSize: '1 porción (100g)', calories: 200, protein: 5, carbohydrates: 30, fat: 6, fiber: 0, sodium: 85 },
    { name: 'Gallo Pinto (Arroz y Frijoles)', servingSize: '1 taza (240g)', calories: 300, protein: 9, carbohydrates: 45, fat: 8, fiber: 6, sodium: 200 },
    { name: 'Ropa Vieja', servingSize: '1 porción (200g)', calories: 380, protein: 35, carbohydrates: 4, fat: 23, fiber: 0, sodium: 600 },
    { name: 'Choclo (Maíz Tierno)', servingSize: '1 mazorca (90g)', calories: 90, protein: 3.5, carbohydrates: 20, fat: 1.2, fiber: 2.7, sodium: 15 },
  
    { name: 'Churros', servingSize: '1 unidad (60g)', calories: 170, protein: 2.5, carbohydrates: 21, fat: 9, fiber: 0.8, sodium: 135 },
    { name: 'Turrón', servingSize: '1 barra (30g)', calories: 140, protein: 3, carbohydrates: 17, fat: 7, fiber: 0.6, sodium: 40 },
    { name: 'Tostones (Plátano Verde Frito)', servingSize: '1 taza (150g)', calories: 365, protein: 2, carbohydrates: 58, fat: 14, fiber: 3.5, sodium: 10 },
    { name: 'Lechona', servingSize: '1 porción (200g)', calories: 450, protein: 20, carbohydrates: 32, fat: 25, fiber: 3, sodium: 700 },
    { name: 'Ensalada de Nopal', servingSize: '1 taza (150g)', calories: 50, protein: 2.2, carbohydrates: 10.4, fat: 0.3, fiber: 5, sodium: 20 },
    { name: 'Chimichurri', servingSize: '1 cucharada (15g)', calories: 40, protein: 0, carbohydrates: 0.5, fat: 4.5, fiber: 0, sodium: 60 },
    { name: 'Patacones', servingSize: '1 porción (150g)', calories: 218, protein: 1.3, carbohydrates: 36, fat: 9, fiber: 2.5, sodium: 12 },
    { name: 'Tostada (de Maíz)', servingSize: '1 unidad (25g)', calories: 60, protein: 1.5, carbohydrates: 12, fat: 1.5, fiber: 1.2, sodium: 75 },
    { name: 'Café con Leche', servingSize: '1 taza (240ml)', calories: 80, protein: 4, carbohydrates: 10, fat: 3, fiber: 0, sodium: 75 },
    { name: 'Chicha (de Maíz)', servingSize: '1 taza (240ml)', calories: 120, protein: 1, carbohydrates: 28, fat: 0.1, fiber: 0, sodium: 15 },
    { name: 'Choripán', servingSize: '1 unidad (200g)', calories: 420, protein: 16, carbohydrates: 35, fat: 24, fiber: 2, sodium: 850 },
  { name: 'Empanada de Pollo', servingSize: '1 unidad (150g)', calories: 300, protein: 12, carbohydrates: 34, fat: 14, fiber: 2, sodium: 430 },
  { name: 'Pupusa (Queso y Frijoles)', servingSize: '1 unidad (150g)', calories: 270, protein: 11, carbohydrates: 36, fat: 10, fiber: 4, sodium: 520 },
  { name: 'Sopaipillas', servingSize: '1 unidad (50g)', calories: 150, protein: 2.4, carbohydrates: 18, fat: 7.5, fiber: 0.8, sodium: 250 },
  { name: 'Elote Asado (Maíz)', servingSize: '1 unidad (120g)', calories: 123, protein: 3.9, carbohydrates: 28, fat: 1.5, fiber: 3, sodium: 75 },
  { name: 'Salchipapas', servingSize: '1 porción (300g)', calories: 600, protein: 12, carbohydrates: 52, fat: 40, fiber: 4, sodium: 950 },
  { name: 'Pan de Bono', servingSize: '1 unidad (80g)', calories: 220, protein: 5, carbohydrates: 36, fat: 6, fiber: 1.5, sodium: 320 },
  { name: 'Buñuelo', servingSize: '1 unidad (70g)', calories: 200, protein: 6, carbohydrates: 27, fat: 7, fiber: 1, sodium: 250 },
  { name: 'Arequipe (Dulce de Leche)', servingSize: '2 cucharadas (40g)', calories: 120, protein: 2, carbohydrates: 23, fat: 2.5, fiber: 0, sodium: 60 },

  { name: 'Churrasco (Sándwich)', servingSize: '1 unidad (300g)', calories: 600, protein: 32, carbohydrates: 50, fat: 30, fiber: 4, sodium: 800 },
  { name: 'Tacos al Pastor', servingSize: '1 unidad (150g)', calories: 260, protein: 12, carbohydrates: 25, fat: 12, fiber: 3, sodium: 700 },
  { name: 'Chicharrón de Cerdo', servingSize: '1 porción (100g)', calories: 500, protein: 25, carbohydrates: 0, fat: 45, fiber: 0, sodium: 720 },
  { name: 'Nachos con Queso', servingSize: '1 porción (150g)', calories: 400, protein: 8, carbohydrates: 42, fat: 20, fiber: 4, sodium: 900 },
  { name: 'Tequeños', servingSize: '1 unidad (30g)', calories: 110, protein: 3, carbohydrates: 10, fat: 6, fiber: 0.5, sodium: 150 },
  { name: 'Arepa de Queso', servingSize: '1 unidad (80g)', calories: 220, protein: 5, carbohydrates: 28, fat: 10, fiber: 2, sodium: 260 },
  { name: 'Jugo de Guayaba', servingSize: '1 vaso (240ml)', calories: 140, protein: 2, carbohydrates: 34, fat: 0.5, fiber: 5.2, sodium: 5 },
  { name: 'Galletas Dulces (Mantequilla)', servingSize: '1 unidad (15g)', calories: 75, protein: 1, carbohydrates: 10, fat: 3, fiber: 0.3, sodium: 35 },
  { name: 'Leche Condensada', servingSize: '2 cucharadas (40g)', calories: 130, protein: 3, carbohydrates: 23, fat: 3, fiber: 0, sodium: 45 },
  { name: 'Agua de Jamaica', servingSize: '1 vaso (240ml)', calories: 60, protein: 0, carbohydrates: 16, fat: 0, fiber: 0, sodium: 5 },

  { name: 'Quesillo (Postre)', servingSize: '1 porción (100g)', calories: 200, protein: 7, carbohydrates: 25, fat: 8, fiber: 0, sodium: 90 },
  { name: 'Tortilla de Patatas', servingSize: '1 porción (200g)', calories: 250, protein: 10, carbohydrates: 30, fat: 10, fiber: 3, sodium: 350 },
  { name: 'Pastel de Tres Leches', servingSize: '1 porción (120g)', calories: 350, protein: 8, carbohydrates: 40, fat: 15, fiber: 0, sodium: 150 },
  { name: 'Horchata', servingSize: '1 vaso (240ml)', calories: 150, protein: 2, carbohydrates: 32, fat: 3, fiber: 1, sodium: 40 },
  { name: 'Tamales (de Cerdo)', servingSize: '1 unidad (250g)', calories: 400, protein: 15, carbohydrates: 45, fat: 18, fiber: 6, sodium: 700 },
  { name: 'Plátano Asado', servingSize: '1 mediano (150g)', calories: 180, protein: 2, carbohydrates: 45, fat: 0.3, fiber: 3.5, sodium: 2 },
  { name: 'Canelazo', servingSize: '1 taza (240ml)', calories: 100, protein: 0.5, carbohydrates: 25, fat: 0.1, fiber: 0, sodium: 10 },
  { name: 'Dulce de Higos', servingSize: '1 porción (100g)', calories: 230, protein: 2, carbohydrates: 60, fat: 0.5, fiber: 3, sodium: 20 },
  { name: 'Empanada de Queso', servingSize: '1 unidad (150g)', calories: 320, protein: 10, carbohydrates: 32, fat: 15, fiber: 2, sodium: 500 },
  { name: 'Mazamorra (Maíz)', servingSize: '1 taza (240g)', calories: 170, protein: 5, carbohydrates: 35, fat: 2, fiber: 4, sodium: 20 },
    
  { name: 'Ribeye (Res)', servingSize: '100g', calories: 291, protein: 24, carbohydrates: 0, fat: 22, fiber: 0, sodium: 60 },
  { name: 'Lomo de Res (Filete)', servingSize: '100g', calories: 210, protein: 26, carbohydrates: 0, fat: 12, fiber: 0, sodium: 55 },
  { name: 'Falda de Res', servingSize: '100g', calories: 287, protein: 21, carbohydrates: 0, fat: 23, fiber: 0, sodium: 65 },
  { name: 'Costilla de Res', servingSize: '100g', calories: 350, protein: 19, carbohydrates: 0, fat: 30, fiber: 0, sodium: 75 },
  { name: 'T-Bone (Res)', servingSize: '100g', calories: 230, protein: 25, carbohydrates: 0, fat: 14, fiber: 0, sodium: 70 },

  // Pork Cuts
  { name: 'Chuleta de Cerdo', servingSize: '100g', calories: 231, protein: 26, carbohydrates: 0, fat: 14, fiber: 0, sodium: 62 },
  { name: 'Lomo de Cerdo', servingSize: '100g', calories: 196, protein: 28, carbohydrates: 0, fat: 9, fiber: 0, sodium: 55 },
  { name: 'Costilla de Cerdo', servingSize: '100g', calories: 320, protein: 19, carbohydrates: 0, fat: 28, fiber: 0, sodium: 72 },
  { name: 'Jamón (Cerdo, sin grasa)', servingSize: '100g', calories: 145, protein: 20, carbohydrates: 0, fat: 6, fiber: 0, sodium: 102 },

  // Poultry
  { name: 'Pechuga de Pollo (Sin piel)', servingSize: '100g', calories: 165, protein: 31, carbohydrates: 0, fat: 3.6, fiber: 0, sodium: 74 },
  { name: 'Muslo de Pollo', servingSize: '100g', calories: 210, protein: 25, carbohydrates: 0, fat: 11, fiber: 0, sodium: 65 },
  { name: 'Pato (Pechuga, con piel)', servingSize: '100g', calories: 337, protein: 18, carbohydrates: 0, fat: 28, fiber: 0, sodium: 52 },
  { name: 'Codorniz (Cocida)', servingSize: '100g', calories: 134, protein: 22, carbohydrates: 0, fat: 5.6, fiber: 0, sodium: 56 },

  // Fish and Seafood
  { name: 'Salmón (Crudo)', servingSize: '100g', calories: 208, protein: 20, carbohydrates: 0, fat: 13, fiber: 0, sodium: 59 },
  { name: 'Atún (Crudo)', servingSize: '100g', calories: 144, protein: 30, carbohydrates: 0, fat: 1, fiber: 0, sodium: 50 },
  { name: 'Camarones (Cocidos)', servingSize: '100g', calories: 99, protein: 24, carbohydrates: 0.2, fat: 0.3, fiber: 0, sodium: 111 },
  { name: 'Tilapia (Cocida)', servingSize: '100g', calories: 128, protein: 26, carbohydrates: 0, fat: 2.6, fiber: 0, sodium: 56 },
  { name: 'Bacalao (Cocido)', servingSize: '100g', calories: 105, protein: 23, carbohydrates: 0, fat: 0.8, fiber: 0, sodium: 70 },

  // Plant-Based Proteins
  { name: 'Tofu (Firme)', servingSize: '100g', calories: 76, protein: 8, carbohydrates: 1.9, fat: 4.8, fiber: 0.3, sodium: 8 },
  { name: 'Lentejas (Cocidas)', servingSize: '1 taza (198g)', calories: 230, protein: 18, carbohydrates: 40, fat: 0.8, fiber: 15.6, sodium: 4 },
  { name: 'Frijoles Negros (Cocidos)', servingSize: '1 taza (172g)', calories: 227, protein: 15.2, carbohydrates: 40.8, fat: 0.9, fiber: 15, sodium: 1 },
  { name: 'Garbanzos (Cocidos)', servingSize: '1 taza (164g)', calories: 269, protein: 14.5, carbohydrates: 45, fat: 4.2, fiber: 12.5, sodium: 10 },
  { name: 'Edamame (Cocido)', servingSize: '1 taza (155g)', calories: 188, protein: 18, carbohydrates: 14, fat: 8, fiber: 8, sodium: 9 },
  { name: 'Carne Molida (Magro, cocida)', servingSize: '100g', calories: 250, protein: 26, carbohydrates: 0, fat: 15, fiber: 0, sodium: 75 },
  { name: 'Ribeye (Res)', servingSize: '100g', calories: 291, protein: 24, carbohydrates: 0, fat: 22, fiber: 0, sodium: 60 },
  { name: 'Lomo de Res (Filete)', servingSize: '100g', calories: 210, protein: 26, carbohydrates: 0, fat: 12, fiber: 0, sodium: 55 },
  { name: 'Falda de Res', servingSize: '100g', calories: 287, protein: 21, carbohydrates: 0, fat: 23, fiber: 0, sodium: 65 },
  { name: 'Costilla de Res', servingSize: '100g', calories: 350, protein: 19, carbohydrates: 0, fat: 30, fiber: 0, sodium: 75 },
  { name: 'T-Bone (Res)', servingSize: '100g', calories: 230, protein: 25, carbohydrates: 0, fat: 14, fiber: 0, sodium: 70 },

  // Carne de Cerdo
  { name: 'Chuleta de Cerdo', servingSize: '100g', calories: 231, protein: 26, carbohydrates: 0, fat: 14, fiber: 0, sodium: 62 },
  { name: 'Lomo de Cerdo', servingSize: '100g', calories: 196, protein: 28, carbohydrates: 0, fat: 9, fiber: 0, sodium: 55 },
  { name: 'Costilla de Cerdo', servingSize: '100g', calories: 320, protein: 19, carbohydrates: 0, fat: 28, fiber: 0, sodium: 72 },
  { name: 'Jamón (Cerdo, sin grasa)', servingSize: '100g', calories: 145, protein: 20, carbohydrates: 0, fat: 6, fiber: 0, sodium: 102 },
  { name: 'Chorizo de Cerdo', servingSize: '100g', calories: 455, protein: 22, carbohydrates: 2, fat: 42, fiber: 0, sodium: 130 },

  // Aves
  { name: 'Pechuga de Pollo (Sin piel)', servingSize: '100g', calories: 165, protein: 31, carbohydrates: 0, fat: 3.6, fiber: 0, sodium: 74 },
  { name: 'Muslo de Pollo', servingSize: '100g', calories: 210, protein: 25, carbohydrates: 0, fat: 11, fiber: 0, sodium: 65 },
  { name: 'Pato (Pechuga, con piel)', servingSize: '100g', calories: 337, protein: 18, carbohydrates: 0, fat: 28, fiber: 0, sodium: 52 },
  { name: 'Codorniz (Cocida)', servingSize: '100g', calories: 134, protein: 22, carbohydrates: 0, fat: 5.6, fiber: 0, sodium: 56 },
  { name: 'Pavo (Pechuga, sin piel)', servingSize: '100g', calories: 135, protein: 30, carbohydrates: 0, fat: 1.5, fiber: 0, sodium: 55 },

  // Pescados y Mariscos
  { name: 'Salmón (Crudo)', servingSize: '100g', calories: 208, protein: 20, carbohydrates: 0, fat: 13, fiber: 0, sodium: 59 },
  { name: 'Atún (Crudo)', servingSize: '100g', calories: 144, protein: 30, carbohydrates: 0, fat: 1, fiber: 0, sodium: 50 },
  { name: 'Camarones (Cocidos)', servingSize: '100g', calories: 99, protein: 24, carbohydrates: 0.2, fat: 0.3, fiber: 0, sodium: 111 },
  { name: 'Tilapia (Cocida)', servingSize: '100g', calories: 128, protein: 26, carbohydrates: 0, fat: 2.6, fiber: 0, sodium: 56 },
  { name: 'Bacalao (Cocido)', servingSize: '100g', calories: 105, protein: 23, carbohydrates: 0, fat: 0.8, fiber: 0, sodium: 70 },
  { name: 'Langosta (Cocida)', servingSize: '100g', calories: 89, protein: 19, carbohydrates: 0, fat: 0.8, fiber: 0, sodium: 150 },
  { name: 'Mejillones (Cocidos)', servingSize: '100g', calories: 172, protein: 24, carbohydrates: 7, fat: 4, fiber: 0, sodium: 369 },
  { name: 'Calamares (Cocidos)', servingSize: '100g', calories: 92, protein: 15, carbohydrates: 5.2, fat: 2.3, fiber: 0, sodium: 233 },

  // Proteínas Vegetales
  { name: 'Tofu (Firme)', servingSize: '100g', calories: 76, protein: 8, carbohydrates: 1.9, fat: 4.8, fiber: 0.3, sodium: 8 },
  { name: 'Lentejas (Cocidas)', servingSize: '1 taza (198g)', calories: 230, protein: 18, carbohydrates: 40, fat: 0.8, fiber: 15.6, sodium: 4 },
  { name: 'Frijoles Negros (Cocidos)', servingSize: '1 taza (172g)', calories: 227, protein: 15.2, carbohydrates: 40.8, fat: 0.9, fiber: 15, sodium: 1 },
  { name: 'Garbanzos (Cocidos)', servingSize: '1 taza (164g)', calories: 269, protein: 14.5, carbohydrates: 45, fat: 4.2, fiber: 12.5, sodium: 10 },
  { name: 'Edamame (Cocido)', servingSize: '1 taza (155g)', calories: 188, protein: 18, carbohydrates: 14, fat: 8, fiber: 8, sodium: 9 },
  { name: 'Tempeh', servingSize: '100g', calories: 192, protein: 20, carbohydrates: 7.6, fat: 11, fiber: 1.4, sodium: 9 },
  { name: 'Seitán', servingSize: '100g', calories: 143, protein: 25, carbohydrates: 7, fat: 2, fiber: 0, sodium: 373 },

  // ============================================
  // Cereales y Granos
  // ============================================

  { name: 'Arroz Integral (Cocido)', servingSize: '1 taza (195g)', calories: 216, protein: 5, carbohydrates: 45, fat: 1.8, fiber: 3.5, sodium: 10 },
  { name: 'Arroz Blanco (Cocido)', servingSize: '1 taza (186g)', calories: 242, protein: 4.4, carbohydrates: 53, fat: 0.4, fiber: 0.6, sodium: 0 },
  { name: 'Quinoa (Cocida)', servingSize: '1 taza (185g)', calories: 222, protein: 8, carbohydrates: 39, fat: 3.6, fiber: 5, sodium: 13 },
  { name: 'Avena (Cocida)', servingSize: '1 taza (234g)', calories: 154, protein: 6, carbohydrates: 27, fat: 2.8, fiber: 4, sodium: 2 },
  { name: 'Pasta Integral (Cocida)', servingSize: '1 taza (140g)', calories: 174, protein: 7.5, carbohydrates: 37, fat: 0.8, fiber: 6.3, sodium: 4 },
  { name: 'Pan Integral', servingSize: '2 rebanadas (60g)', calories: 130, protein: 6, carbohydrates: 24, fat: 2, fiber: 4, sodium: 220 },
  { name: 'Tortilla de Maíz', servingSize: '1 mediana (24g)', calories: 52, protein: 1.4, carbohydrates: 10.7, fat: 0.7, fiber: 1.5, sodium: 11 },
  { name: 'Tortilla de Harina', servingSize: '1 mediana (40g)', calories: 130, protein: 3, carbohydrates: 22, fat: 3.5, fiber: 1.2, sodium: 280 },
  { name: 'Cuscús (Cocido)', servingSize: '1 taza (157g)', calories: 176, protein: 6, carbohydrates: 36, fat: 0.3, fiber: 2.2, sodium: 5 },
  { name: 'Bulgur (Cocido)', servingSize: '1 taza (182g)', calories: 151, protein: 5.6, carbohydrates: 33.8, fat: 0.4, fiber: 8, sodium: 8 },

  // ============================================
  // Frutas
  // ============================================

  { name: 'Plátano', servingSize: '1 mediano (118g)', calories: 105, protein: 1.3, carbohydrates: 27, fat: 0.3, fiber: 3.1, sodium: 1 },
  { name: 'Manzana', servingSize: '1 mediana (182g)', calories: 95, protein: 0.5, carbohydrates: 25, fat: 0.3, fiber: 4.4, sodium: 1 },
  { name: 'Naranja', servingSize: '1 mediana (131g)', calories: 62, protein: 1.2, carbohydrates: 15.4, fat: 0.2, fiber: 3.1, sodium: 0 },
  { name: 'Zanahoria (Cruda)', servingSize: '1 mediana (61g)', calories: 25, protein: 0.6, carbohydrates: 6, fat: 0.1, fiber: 1.7, sodium: 42 },
  { name: 'Papa (Horneada, con piel)', servingSize: '1 mediana (173g)', calories: 161, protein: 4.3, carbohydrates: 37, fat: 0.2, fiber: 3.8, sodium: 17 },
  { name: 'Pepino (Crudo, sin piel)', servingSize: '1 taza (104g)', calories: 16, protein: 0.7, carbohydrates: 3.8, fat: 0.1, fiber: 0.5, sodium: 2 },
  { name: 'Yuca (Cocida)', servingSize: '1 taza (150g)', calories: 178, protein: 1.4, carbohydrates: 42, fat: 0.3, fiber: 1.8, sodium: 13 },
  { name: 'Choclo (Maíz Tierno)', servingSize: '1 mazorca (90g)', calories: 90, protein: 3.5, carbohydrates: 20, fat: 1.2, fiber: 2.7, sodium: 15 },
  { name: 'Fresas', servingSize: '1 taza (152g)', calories: 49, protein: 1, carbohydrates: 12, fat: 0.5, fiber: 3, sodium: 1 },
  { name: 'Mango', servingSize: '1 mediano (200g)', calories: 135, protein: 1.1, carbohydrates: 35, fat: 0.6, fiber: 3.7, sodium: 1 },
  { name: 'Uvas', servingSize: '1 taza (151g)', calories: 104, protein: 1.1, carbohydrates: 27, fat: 0.2, fiber: 1.4, sodium: 2 },
  { name: 'Kiwi', servingSize: '2 medianos (150g)', calories: 84, protein: 2, carbohydrates: 21, fat: 1.2, fiber: 4.4, sodium: 6 },
  { name: 'Pera', servingSize: '1 mediana (178g)', calories: 101, protein: 0.6, carbohydrates: 27, fat: 0.2, fiber: 5.5, sodium: 2 },
  { name: 'Piña', servingSize: '1 taza (165g)', calories: 82, protein: 0.9, carbohydrates: 21, fat: 0.2, fiber: 2.3, sodium: 2 },
  { name: 'Melón (Cantalupe)', servingSize: '1 taza (177g)', calories: 53, protein: 1.3, carbohydrates: 14, fat: 0.3, fiber: 1.5, sodium: 2 },
  { name: 'Papaya', servingSize: '1 taza (145g)', calories: 59, protein: 0.9, carbohydrates: 15, fat: 0.4, fiber: 2.5, sodium: 7 },
  { name: 'Sandía', servingSize: '2 tazas (280g)', calories: 86, protein: 1.7, carbohydrates: 22, fat: 0.4, fiber: 0.6, sodium: 2 },
  { name: 'Granada', servingSize: '1 taza (174g)', calories: 144, protein: 3.3, carbohydrates: 33, fat: 3.2, fiber: 11, sodium: 7 },
  { name: 'Cereza', servingSize: '1 taza (154g)', calories: 97, protein: 1.6, carbohydrates: 25, fat: 0.3, fiber: 3, sodium: 1 },

  // ============================================
  // Verduras
  // ============================================

  { name: 'Brócoli (Cocido al Vapor)', servingSize: '1 taza (91g)', calories: 55, protein: 3.7, carbohydrates: 11.2, fat: 0.6, fiber: 5.1, sodium: 30 },
  { name: 'Espinaca (Cocida)', servingSize: '1 taza (180g)', calories: 41, protein: 5.4, carbohydrates: 6.8, fat: 0.5, fiber: 4.3, sodium: 126 },
  { name: 'Lechuga (Romaine)', servingSize: '1 taza (47g)', calories: 8, protein: 0.6, carbohydrates: 1.5, fat: 0.1, fiber: 1, sodium: 4 },
  { name: 'Calabacín (Cocido)', servingSize: '1 taza (180g)', calories: 27, protein: 2.4, carbohydrates: 5.8, fat: 0.4, fiber: 1.7, sodium: 5 },
  { name: 'Berenjena (Cocida)', servingSize: '1 taza (99g)', calories: 35, protein: 0.8, carbohydrates: 8.6, fat: 0.2, fiber: 2.5, sodium: 5 },
  { name: 'Pimiento (Rojo, Crudo)', servingSize: '1 mediano (119g)', calories: 37, protein: 1.2, carbohydrates: 7.5, fat: 0.4, fiber: 2.5, sodium: 3 },
  { name: 'Cebolla (Cruda)', servingSize: '1 mediana (110g)', calories: 44, protein: 1.2, carbohydrates: 10.3, fat: 0.1, fiber: 1.9, sodium: 4 },
  { name: 'Ajo (Crudo)', servingSize: '1 diente (3g)', calories: 5, protein: 0.2, carbohydrates: 1, fat: 0, fiber: 0.1, sodium: 1 },
  { name: 'Repollo (Crudo)', servingSize: '1 taza (89g)', calories: 22, protein: 1.1, carbohydrates: 5.2, fat: 0.1, fiber: 2.2, sodium: 18 },
  { name: 'Apio (Crudo)', servingSize: '1 taza (101g)', calories: 16, protein: 0.7, carbohydrates: 3, fat: 0.2, fiber: 1.6, sodium: 80 },
  { name: 'Espárragos (Cocidos)', servingSize: '1 taza (180g)', calories: 40, protein: 4.3, carbohydrates: 7.4, fat: 0.5, fiber: 3.6, sodium: 2 },
  { name: 'Coliflor (Cruda)', servingSize: '1 taza (107g)', calories: 25, protein: 2, carbohydrates: 5, fat: 0.1, fiber: 2.5, sodium: 30 },
  { name: 'Champiñones (Crudos)', servingSize: '1 taza (72g)', calories: 15, protein: 2.2, carbohydrates: 2.3, fat: 0.2, fiber: 0.7, sodium: 5 },
  { name: 'Calabaza (Cocida)', servingSize: '1 taza (116g)', calories: 49, protein: 1.8, carbohydrates: 12.3, fat: 0.1, fiber: 2.7, sodium: 5 },
  { name: 'Puerro (Cocido)', servingSize: '1 taza (89g)', calories: 54, protein: 1.6, carbohydrates: 12.3, fat: 0.3, fiber: 1.8, sodium: 5 },

  // ============================================
  // Lácteos y Alternativas
  // ============================================

  { name: 'Yogur Griego (Natural, sin grasa)', servingSize: '1 taza (245g)', calories: 100, protein: 10, carbohydrates: 6, fat: 0, fiber: 0, sodium: 60 },
  { name: 'Leche (Entera)', servingSize: '1 taza (244g)', calories: 149, protein: 7.7, carbohydrates: 12.3, fat: 8, fiber: 0, sodium: 98 },
  { name: 'Queso Cheddar', servingSize: '1 oz (28g)', calories: 113, protein: 6.4, carbohydrates: 0.4, fat: 9.3, fiber: 0, sodium: 174 },
  { name: 'Mantequilla de Maní', servingSize: '2 cucharadas (32g)', calories: 190, protein: 8, carbohydrates: 7, fat: 16, fiber: 2, sodium: 140 },
  { name: 'Leche de Almendra', servingSize: '1 taza (240ml)', calories: 30, protein: 1, carbohydrates: 1, fat: 2.5, fiber: 1, sodium: 170 },
  { name: 'Queso Mozzarella (Bajo en grasa)', servingSize: '1 oz (28g)', calories: 72, protein: 5.5, carbohydrates: 0.6, fat: 4.5, fiber: 0, sodium: 174 },
  { name: 'Leche de Soja', servingSize: '1 taza (240ml)', calories: 80, protein: 7, carbohydrates: 4, fat: 4, fiber: 1, sodium: 100 },
  { name: 'Requesón (Cottage Cheese)', servingSize: '1 taza (226g)', calories: 206, protein: 28, carbohydrates: 6, fat: 8, fiber: 0, sodium: 918 },
  { name: 'Crema de Leche', servingSize: '2 cucharadas (30ml)', calories: 103, protein: 0.5, carbohydrates: 0.4, fat: 11, fiber: 0, sodium: 14 },
  { name: 'Leche Descremada', servingSize: '1 taza (244g)', calories: 83, protein: 8.3, carbohydrates: 12.2, fat: 0.2, fiber: 0, sodium: 107 },
  { name: 'Queso Feta', servingSize: '1 oz (28g)', calories: 75, protein: 4, carbohydrates: 1.2, fat: 6, fiber: 0, sodium: 316 },

  // ============================================
  // Bebidas
  // ============================================

  { name: 'Jugo de Naranja', servingSize: '1 taza (240ml)', calories: 112, protein: 2, carbohydrates: 26, fat: 0.5, fiber: 0.5, sodium: 2 },
  { name: 'Agua de Jamaica', servingSize: '1 vaso (240ml)', calories: 60, protein: 0, carbohydrates: 16, fat: 0, fiber: 0, sodium: 5 },
  { name: 'Café (Negro)', servingSize: '1 taza (237ml)', calories: 2, protein: 0.3, carbohydrates: 0, fat: 0, fiber: 0, sodium: 5 },
  { name: 'Te (Sin azúcar)', servingSize: '1 taza (240ml)', calories: 2, protein: 0, carbohydrates: 0.5, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Leche Condensada', servingSize: '2 cucharadas (40g)', calories: 130, protein: 3, carbohydrates: 23, fat: 3, fiber: 0, sodium: 45 },
  { name: 'Cerveza (Lata)', servingSize: '1 lata (355ml)', calories: 153, protein: 1.6, carbohydrates: 13, fat: 0, fiber: 0, sodium: 14 },
  { name: 'Vino Tinto', servingSize: '1 copa (150ml)', calories: 125, protein: 0.1, carbohydrates: 4, fat: 0, fiber: 0, sodium: 5 },
  { name: 'Refresco de Cola', servingSize: '1 lata (355ml)', calories: 140, protein: 0, carbohydrates: 39, fat: 0, fiber: 0, sodium: 45 },
  { name: 'Agua Mineral', servingSize: '1 vaso (240ml)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Té Verde', servingSize: '1 taza (240ml)', calories: 2, protein: 0, carbohydrates: 0.5, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Batido de Frutas', servingSize: '1 vaso (240ml)', calories: 180, protein: 5, carbohydrates: 35, fat: 2, fiber: 3, sodium: 50 },
  { name: 'Agua de Coco', servingSize: '1 vaso (240ml)', calories: 46, protein: 0.7, carbohydrates: 9, fat: 1, fiber: 3, sodium: 252 },
  { name: 'Limonada', servingSize: '1 vaso (240ml)', calories: 99, protein: 0.6, carbohydrates: 25, fat: 0.2, fiber: 0.6, sodium: 3 },
  { name: 'Smoothie Verde', servingSize: '1 vaso (240ml)', calories: 120, protein: 4, carbohydrates: 20, fat: 3, fiber: 5, sodium: 30 },
  { name: 'Té Chai', servingSize: '1 taza (240ml)', calories: 190, protein: 4, carbohydrates: 20, fat: 10, fiber: 0, sodium: 20 },
  { name: 'Agua con Gas', servingSize: '1 vaso (240ml)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Sidra de Manzana', servingSize: '1 vaso (240ml)', calories: 120, protein: 0.1, carbohydrates: 31, fat: 0, fiber: 0, sodium: 15 },
  { name: 'Kéfir', servingSize: '1 taza (240ml)', calories: 150, protein: 8, carbohydrates: 12, fat: 8, fiber: 0, sodium: 30 },

  // ============================================
  // Snacks y Aperitivos
  // ============================================

  { name: 'Almendras (Crudas)', servingSize: '1 oz (28g)', calories: 164, protein: 6, carbohydrates: 6.1, fat: 14.2, fiber: 3.5, sodium: 0 },
  { name: 'Palomitas de Maíz (Sin mantequilla)', servingSize: '3 tazas (24g)', calories: 93, protein: 3.6, carbohydrates: 18.6, fat: 1.1, fiber: 3.6, sodium: 0 },
  { name: 'Galletas Saladas', servingSize: '1 oz (28g)', calories: 140, protein: 2, carbohydrates: 24, fat: 5, fiber: 1, sodium: 180 },
  { name: 'Barrita de Granola', servingSize: '1 unidad (40g)', calories: 150, protein: 3, carbohydrates: 25, fat: 5, fiber: 2, sodium: 80 },
  { name: 'Chips de Plátano', servingSize: '1 oz (28g)', calories: 140, protein: 1, carbohydrates: 18, fat: 8, fiber: 2, sodium: 90 },
  { name: 'Trail Mix', servingSize: '1 oz (28g)', calories: 170, protein: 5, carbohydrates: 15, fat: 10, fiber: 3, sodium: 20 },
  { name: 'Pretzels', servingSize: '1 oz (28g)', calories: 108, protein: 3.2, carbohydrates: 22, fat: 1.2, fiber: 1.1, sodium: 398 },
  { name: 'Barrita de Proteína', servingSize: '1 unidad (60g)', calories: 250, protein: 20, carbohydrates: 20, fat: 8, fiber: 5, sodium: 150 },
  { name: 'Edamame (Crudo)', servingSize: '1 taza (155g)', calories: 188, protein: 18, carbohydrates: 14, fat: 8, fiber: 8, sodium: 9 },
  { name: 'Hummus', servingSize: '2 cucharadas (30g)', calories: 70, protein: 2, carbohydrates: 4, fat: 5, fiber: 1.5, sodium: 70 },
  { name: 'Guacamole', servingSize: '1/4 taza (60g)', calories: 90, protein: 1.5, carbohydrates: 4, fat: 7.5, fiber: 3.5, sodium: 150 },
  { name: 'Yogur con Frutas', servingSize: '1 taza (245g)', calories: 120, protein: 8, carbohydrates: 15, fat: 3, fiber: 2, sodium: 70 },
  { name: 'Barras de Cereal', servingSize: '1 unidad (30g)', calories: 120, protein: 2, carbohydrates: 22, fat: 3, fiber: 1, sodium: 60 },
  { name: 'Bocados de Queso', servingSize: '1 oz (28g)', calories: 113, protein: 6.4, carbohydrates: 0.4, fat: 9.3, fiber: 0, sodium: 174 },
  { name: 'Snickers', servingSize: '1 barra (52g)', calories: 250, protein: 4, carbohydrates: 35, fat: 12, fiber: 1, sodium: 120 },

  // ============================================
  // Postres y Dulces
  // ============================================

  { name: 'Helado de Vainilla', servingSize: '1/2 taza (66g)', calories: 137, protein: 2.3, carbohydrates: 15.5, fat: 7.3, fiber: 0.5, sodium: 53 },
  { name: 'Flan (de Caramelo)', servingSize: '1 porción (100g)', calories: 200, protein: 5, carbohydrates: 30, fat: 6, fiber: 0, sodium: 85 },
  { name: 'Pastel de Tres Leches', servingSize: '1 porción (120g)', calories: 350, protein: 8, carbohydrates: 40, fat: 15, fiber: 0, sodium: 150 },
  { name: 'Turrón', servingSize: '1 barra (30g)', calories: 140, protein: 3, carbohydrates: 17, fat: 7, fiber: 0.6, sodium: 40 },
  { name: 'Churros', servingSize: '1 unidad (60g)', calories: 170, protein: 2.5, carbohydrates: 21, fat: 9, fiber: 0.8, sodium: 135 },
  { name: 'Galletas Dulces (Mantequilla)', servingSize: '1 unidad (15g)', calories: 75, protein: 1, carbohydrates: 10, fat: 3, fiber: 0.3, sodium: 35 },
  { name: 'Dulce de Higos', servingSize: '1 porción (100g)', calories: 230, protein: 2, carbohydrates: 60, fat: 0.5, fiber: 3, sodium: 20 },
  { name: 'Cheesecake', servingSize: '1 porción (100g)', calories: 321, protein: 6, carbohydrates: 24, fat: 21, fiber: 1, sodium: 196 },
  { name: 'Brownie', servingSize: '1 porción (50g)', calories: 240, protein: 3, carbohydrates: 30, fat: 12, fiber: 2, sodium: 100 },
  { name: 'Donut Glaseado', servingSize: '1 unidad (50g)', calories: 195, protein: 2.2, carbohydrates: 22, fat: 11, fiber: 1, sodium: 120 },
  { name: 'Macarrones con Queso', servingSize: '1 taza (200g)', calories: 300, protein: 12, carbohydrates: 35, fat: 12, fiber: 3, sodium: 400 },
  { name: 'Gelatina (Sin azúcar)', servingSize: '1 taza (177g)', calories: 20, protein: 0, carbohydrates: 5, fat: 0, fiber: 0, sodium: 40 },
  { name: 'Pastel de Chocolate', servingSize: '1 porción (100g)', calories: 371, protein: 5, carbohydrates: 49, fat: 15, fiber: 3, sodium: 327 },
  { name: 'Galletas de Chocolate Chip', servingSize: '2 unidades (30g)', calories: 160, protein: 2, carbohydrates: 22, fat: 8, fiber: 1, sodium: 90 },
  { name: 'Mousse de Chocolate', servingSize: '1 taza (240g)', calories: 310, protein: 4, carbohydrates: 35, fat: 18, fiber: 3, sodium: 150 },

  // ============================================
  // Otros Alimentos Comunes
  // ============================================

  { name: 'Aceite de Oliva', servingSize: '1 cucharada (14g)', calories: 119, protein: 0, carbohydrates: 0, fat: 13.5, fiber: 0, sodium: 0 },
  { name: 'Mantequilla', servingSize: '1 cucharada (14g)', calories: 102, protein: 0.1, carbohydrates: 0, fat: 11.5, fiber: 0, sodium: 82 },
  { name: 'Sal', servingSize: '1 cucharadita (6g)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 2325 },
  { name: 'Azúcar', servingSize: '1 cucharadita (4g)', calories: 16, protein: 0, carbohydrates: 4, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Harina de Trigo', servingSize: '1 taza (120g)', calories: 455, protein: 13, carbohydrates: 95, fat: 1.2, fiber: 3.4, sodium: 2 },
  { name: 'Salsa de Tomate', servingSize: '1/2 taza (122g)', calories: 40, protein: 1.8, carbohydrates: 8, fat: 0.2, fiber: 2, sodium: 800 },
  { name: 'Mayonesa', servingSize: '1 cucharada (14g)', calories: 94, protein: 0, carbohydrates: 0.1, fat: 10, fiber: 0, sodium: 90 },
  { name: 'Mostaza', servingSize: '1 cucharada (15g)', calories: 10, protein: 0.5, carbohydrates: 1, fat: 0.5, fiber: 0.1, sodium: 55 },
  { name: 'Vinagre', servingSize: '1 cucharada (15ml)', calories: 3, protein: 0, carbohydrates: 0.1, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Miel', servingSize: '1 cucharada (21g)', calories: 64, protein: 0.1, carbohydrates: 17, fat: 0, fiber: 0, sodium: 1 },
  { name: 'Sirope de Arce', servingSize: '1 cucharada (20g)', calories: 52, protein: 0, carbohydrates: 13, fat: 0, fiber: 0, sodium: 7 },
  { name: 'Pan de Hamburguesa', servingSize: '1 unidad (70g)', calories: 165, protein: 5, carbohydrates: 28, fat: 2.5, fiber: 1.5, sodium: 300 },
  { name: 'Pan de Hot Dog', servingSize: '1 unidad (55g)', calories: 150, protein: 4, carbohydrates: 26, fat: 2.5, fiber: 1, sodium: 240 },
  { name: 'Tortilla de Harina (Grande)', servingSize: '1 unidad (60g)', calories: 150, protein: 4, carbohydrates: 24, fat: 3, fiber: 1.2, sodium: 420 },
  { name: 'Pan Pita', servingSize: '1 unidad (60g)', calories: 165, protein: 6, carbohydrates: 33, fat: 1, fiber: 2, sodium: 260 },
  { name: 'Bagel', servingSize: '1 unidad (100g)', calories: 250, protein: 10, carbohydrates: 50, fat: 1.5, fiber: 2, sodium: 500 },
  { name: 'Wrap de Tortilla', servingSize: '1 unidad (70g)', calories: 200, protein: 5, carbohydrates: 35, fat: 4, fiber: 2, sodium: 400 },

  // ============================================
  // Comidas Rápidas y Platos Preparados
  // ============================================

  { name: 'Hamburguesa (de Res)', servingSize: '1 unidad (250g)', calories: 510, protein: 25, carbohydrates: 35, fat: 28, fiber: 3.5, sodium: 1200 },
  { name: 'Tacos al Pastor', servingSize: '1 unidad (150g)', calories: 260, protein: 12, carbohydrates: 25, fat: 12, fiber: 3, sodium: 700 },
  { name: 'Pizza de Queso', servingSize: '1 porción (100g)', calories: 266, protein: 11, carbohydrates: 33, fat: 10, fiber: 2, sodium: 640 },
  { name: 'Pizza Pepperoni', servingSize: '1 porción (100g)', calories: 300, protein: 12, carbohydrates: 36, fat: 12, fiber: 2, sodium: 700 },
  { name: 'Hot Dog', servingSize: '1 unidad (100g)', calories: 290, protein: 10, carbohydrates: 30, fat: 15, fiber: 2, sodium: 800 },
  { name: 'Sándwich de Pavo', servingSize: '1 unidad (150g)', calories: 220, protein: 15, carbohydrates: 25, fat: 7, fiber: 3, sodium: 600 },
  { name: 'Sopa Instantánea', servingSize: '1 paquete (70g)', calories: 380, protein: 9, carbohydrates: 54, fat: 13, fiber: 2, sodium: 1500 },
  { name: 'Pollo Frito', servingSize: '100g', calories: 296, protein: 20, carbohydrates: 20, fat: 16, fiber: 1, sodium: 735 },
  { name: 'Nuggets de Pollo', servingSize: '100g', calories: 296, protein: 14, carbohydrates: 15, fat: 18, fiber: 1, sodium: 440 },
  { name: 'Wrap de Pollo', servingSize: '1 unidad (200g)', calories: 350, protein: 20, carbohydrates: 30, fat: 15, fiber: 4, sodium: 800 },
  { name: 'Burrito de Carne', servingSize: '1 unidad (300g)', calories: 450, protein: 25, carbohydrates: 45, fat: 15, fiber: 5, sodium: 900 },
  { name: 'Ensalada César con Pollo', servingSize: '1 taza (200g)', calories: 350, protein: 20, carbohydrates: 10, fat: 25, fiber: 2, sodium: 700 },
  { name: 'Taco de Pescado', servingSize: '1 unidad (150g)', calories: 280, protein: 15, carbohydrates: 25, fat: 12, fiber: 3, sodium: 600 },
  { name: 'Falafel', servingSize: '3 unidades (75g)', calories: 200, protein: 6, carbohydrates: 25, fat: 8, fiber: 5, sodium: 350 },
  { name: 'Gyro', servingSize: '1 unidad (200g)', calories: 400, protein: 20, carbohydrates: 35, fat: 18, fiber: 4, sodium: 800 },

  // ============================================
  // Bebidas Energéticas y Suplementos
  // ============================================

  { name: 'Bebida Energética', servingSize: '1 lata (250ml)', calories: 110, protein: 0, carbohydrates: 28, fat: 0, fiber: 0, sodium: 160 },
  { name: 'Suplemento de Proteína (Polvo)', servingSize: '1 scoop (30g)', calories: 120, protein: 24, carbohydrates: 3, fat: 1.5, fiber: 1, sodium: 60 },
  { name: 'Bebida de Proteína (Lista para Beber)', servingSize: '1 botella (500ml)', calories: 200, protein: 30, carbohydrates: 10, fat: 5, fiber: 2, sodium: 300 },
  { name: 'Bebida de BCAA', servingSize: '1 vaso (240ml)', calories: 5, protein: 0, carbohydrates: 1, fat: 0, fiber: 0, sodium: 200 },
  { name: 'Electrolitos (Tabletas)', servingSize: '1 tableta (5g)', calories: 0, protein: 0, carbohydrates: 1, fat: 0, fiber: 0, sodium: 400 },

  // ============================================
  // Salsas y Condimentos
  // ============================================

  { name: 'Salsa de Soja', servingSize: '1 cucharada (15ml)', calories: 10, protein: 2, carbohydrates: 1, fat: 0, fiber: 0, sodium: 920 },
  { name: 'Salsa BBQ', servingSize: '2 cucharadas (30ml)', calories: 70, protein: 1, carbohydrates: 18, fat: 0, fiber: 0, sodium: 800 },
  { name: 'Salsa Picante', servingSize: '1 cucharadita (5ml)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 30 },
  { name: 'Pesto', servingSize: '2 cucharadas (30g)', calories: 160, protein: 4, carbohydrates: 2, fat: 16, fiber: 1, sodium: 200 },
  { name: 'Hummus', servingSize: '2 cucharadas (30g)', calories: 70, protein: 2, carbohydrates: 4, fat: 5, fiber: 1.5, sodium: 70 },
  { name: 'Tahini', servingSize: '1 cucharada (15g)', calories: 89, protein: 2.6, carbohydrates: 3.2, fat: 8, fiber: 1.3, sodium: 48 },
  { name: 'Mayonesa Vegana', servingSize: '1 cucharada (14g)', calories: 90, protein: 0, carbohydrates: 0, fat: 10, fiber: 0, sodium: 90 },

  // ============================================
  // Otros Alimentos
  // ============================================

  { name: 'Aceitunas Negras', servingSize: '10 unidades (28g)', calories: 115, protein: 0.8, carbohydrates: 6, fat: 10, fiber: 2, sodium: 724 },
  { name: 'Alcachofas (Cocidas)', servingSize: '1 taza (120g)', calories: 60, protein: 4, carbohydrates: 13, fat: 0.2, fiber: 10, sodium: 190 },
  { name: 'Espárragos a la Parrilla', servingSize: '1 taza (180g)', calories: 40, protein: 4.3, carbohydrates: 7.4, fat: 0.5, fiber: 3.6, sodium: 2 },
  { name: 'Edamame (Crudo)', servingSize: '1 taza (155g)', calories: 188, protein: 18, carbohydrates: 14, fat: 8, fiber: 8, sodium: 9 },
  { name: 'Tempeh', servingSize: '100g', calories: 192, protein: 20, carbohydrates: 7.6, fat: 11, fiber: 1.4, sodium: 9 },
  { name: 'Seitán', servingSize: '100g', calories: 143, protein: 25, carbohydrates: 7, fat: 2, fiber: 0, sodium: 373 },
  { name: 'Tofu Ahumado', servingSize: '100g', calories: 76, protein: 8, carbohydrates: 1.9, fat: 4.8, fiber: 0.3, sodium: 8 },
  { name: 'Chía (Semillas)', servingSize: '1 oz (28g)', calories: 138, protein: 4.7, carbohydrates: 12, fat: 8.7, fiber: 10.6, sodium: 5 },
  { name: 'Semillas de Lino', servingSize: '1 oz (28g)', calories: 150, protein: 5.2, carbohydrates: 8.1, fat: 12, fiber: 7.6, sodium: 7 },
  { name: 'Semillas de Calabaza', servingSize: '1 oz (28g)', calories: 151, protein: 7, carbohydrates: 5.3, fat: 13, fiber: 1.1, sodium: 5 },
  { name: 'Semillas de Girasol', servingSize: '1 oz (28g)', calories: 164, protein: 5.8, carbohydrates: 6.4, fat: 14, fiber: 2.4, sodium: 1 },
  { name: 'Tahini', servingSize: '1 cucharada (15g)', calories: 89, protein: 2.6, carbohydrates: 3.2, fat: 8, fiber: 1.3, sodium: 48 },
  { name: 'Sésamo (Semillas)', servingSize: '1 oz (28g)', calories: 160, protein: 5.7, carbohydrates: 7, fat: 14, fiber: 4, sodium: 2 },
  { name: 'Alcachofa (Cruda)', servingSize: '1 unidad (120g)', calories: 60, protein: 4, carbohydrates: 13, fat: 0.2, fiber: 10, sodium: 190 },

  // ============================================
  // Alimentos Rápidos y Platos Preparados
  // ============================================

  { name: 'Hamburguesa Vegana', servingSize: '1 unidad (200g)', calories: 350, protein: 20, carbohydrates: 30, fat: 15, fiber: 5, sodium: 700 },
  { name: 'Tacos de Vegetales', servingSize: '2 unidades (150g)', calories: 180, protein: 5, carbohydrates: 25, fat: 7, fiber: 4, sodium: 300 },
  { name: 'Pizza Vegetariana', servingSize: '1 porción (100g)', calories: 250, protein: 10, carbohydrates: 32, fat: 8, fiber: 3, sodium: 400 },
  { name: 'Falafel Wrap', servingSize: '1 unidad (250g)', calories: 400, protein: 15, carbohydrates: 45, fat: 18, fiber: 6, sodium: 700 },
  { name: 'Ensalada de Quinoa', servingSize: '1 taza (185g)', calories: 222, protein: 8, carbohydrates: 39, fat: 3.6, fiber: 5, sodium: 13 },
  { name: 'Burrito de Vegetales', servingSize: '1 unidad (250g)', calories: 450, protein: 15, carbohydrates: 55, fat: 15, fiber: 7, sodium: 800 },
  { name: 'Sopa de Verduras', servingSize: '1 taza (240g)', calories: 80, protein: 3, carbohydrates: 12, fat: 2, fiber: 3, sodium: 400 },
  { name: 'Wrap de Falafel', servingSize: '1 unidad (200g)', calories: 380, protein: 12, carbohydrates: 40, fat: 18, fiber: 5, sodium: 650 },
  { name: 'Tortilla Española', servingSize: '1 porción (150g)', calories: 200, protein: 6, carbohydrates: 12, fat: 14, fiber: 1, sodium: 300 },
  { name: 'Croissant', servingSize: '1 unidad (50g)', calories: 270, protein: 5, carbohydrates: 30, fat: 14, fiber: 2, sodium: 230 },

  // ============================================
  // Postres Saludables
  // ============================================

  { name: 'Barra de Granola Casera', servingSize: '1 barra (40g)', calories: 150, protein: 5, carbohydrates: 20, fat: 6, fiber: 3, sodium: 70 },
  { name: 'Bol de Frutas', servingSize: '1 bol (200g)', calories: 120, protein: 2, carbohydrates: 30, fat: 0.5, fiber: 5, sodium: 5 },
  { name: 'Chia Pudding', servingSize: '1 porción (150g)', calories: 180, protein: 6, carbohydrates: 15, fat: 10, fiber: 8, sodium: 50 },
  { name: 'Smoothie de Frutas', servingSize: '1 vaso (240ml)', calories: 180, protein: 5, carbohydrates: 35, fat: 2, fiber: 5, sodium: 50 },
  { name: 'Muffin de Avena y Plátano', servingSize: '1 unidad (80g)', calories: 200, protein: 5, carbohydrates: 30, fat: 6, fiber: 4, sodium: 150 },
  { name: 'Pudding de Avena', servingSize: '1 taza (200g)', calories: 220, protein: 8, carbohydrates: 35, fat: 5, fiber: 6, sodium: 100 },
  { name: 'Barra de Proteína Casera', servingSize: '1 barra (60g)', calories: 250, protein: 20, carbohydrates: 25, fat: 10, fiber: 5, sodium: 150 },
  { name: 'Tarta de Manzana', servingSize: '1 porción (150g)', calories: 300, protein: 3, carbohydrates: 50, fat: 10, fiber: 4, sodium: 200 },
  { name: 'Helado de Yogur', servingSize: '1 porción (100g)', calories: 120, protein: 5, carbohydrates: 20, fat: 2, fiber: 0, sodium: 80 },
  { name: 'Fruta Deshidratada', servingSize: '1 oz (28g)', calories: 70, protein: 1, carbohydrates: 18, fat: 0.2, fiber: 2.5, sodium: 0 },

  // ============================================
  // Legumbres y Tubérculos
  // ============================================

  { name: 'Guisantes (Cocidos)', servingSize: '1 taza (160g)', calories: 117, protein: 8, carbohydrates: 21, fat: 0.5, fiber: 8, sodium: 7 },
  { name: 'Calabaza (Cruda)', servingSize: '1 taza (116g)', calories: 50, protein: 1.1, carbohydrates: 12, fat: 0.1, fiber: 1.7, sodium: 5 },
  { name: 'Batata (Horneada)', servingSize: '1 mediana (130g)', calories: 112, protein: 2, carbohydrates: 26, fat: 0.1, fiber: 4, sodium: 70 },
  { name: 'Maíz (Cocido)', servingSize: '1 taza (154g)', calories: 143, protein: 5.2, carbohydrates: 31, fat: 1.6, fiber: 3.5, sodium: 7 },
  { name: 'Yuca (Cruda)', servingSize: '1 taza (150g)', calories: 160, protein: 1.2, carbohydrates: 38, fat: 0.3, fiber: 2.7, sodium: 10 },
  { name: 'Papas Fritas (Cocidas)', servingSize: '1 porción (150g)', calories: 400, protein: 5, carbohydrates: 50, fat: 18, fiber: 4, sodium: 400 },

  // ============================================
  // Bebidas sin Calorías
  // ============================================

  { name: 'Agua', servingSize: '1 vaso (240ml)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Té Helado (Sin azúcar)', servingSize: '1 vaso (240ml)', calories: 0, protein: 0, carbohydrates: 0, fat: 0, fiber: 0, sodium: 0 },
  { name: 'Agua de Limón', servingSize: '1 vaso (240ml)', calories: 8, protein: 0.1, carbohydrates: 2.6, fat: 0, fiber: 0.3, sodium: 2 },
  { name: 'Agua con Infusión de Frutas', servingSize: '1 vaso (240ml)', calories: 20, protein: 0, carbohydrates: 5, fat: 0, fiber: 1, sodium: 10 },

  // ============================================
  // Condimentos y Especias
  // ============================================

  { name: 'Pimienta Negra', servingSize: '1 cucharadita (2g)', calories: 6, protein: 0.2, carbohydrates: 1.4, fat: 0.1, fiber: 0.7, sodium: 1 },
  { name: 'Orégano (Seco)', servingSize: '1 cucharadita (1g)', calories: 3, protein: 0.1, carbohydrates: 0.7, fat: 0.1, fiber: 0.3, sodium: 1 },
  { name: 'Cúrcuma (En Polvo)', servingSize: '1 cucharadita (3g)', calories: 8, protein: 0.3, carbohydrates: 1.4, fat: 0.2, fiber: 0.7, sodium: 1 },
  { name: 'Canela (En Polvo)', servingSize: '1 cucharadita (2.6g)', calories: 6, protein: 0.1, carbohydrates: 2, fat: 0.1, fiber: 1.3, sodium: 1 },
  { name: 'Comino (En Polvo)', servingSize: '1 cucharadita (2g)', calories: 8, protein: 0.4, carbohydrates: 1.4, fat: 0.5, fiber: 0.7, sodium: 4 },
  { name: 'Paprika', servingSize: '1 cucharadita (2.3g)', calories: 6, protein: 0.3, carbohydrates: 1.2, fat: 0.3, fiber: 0.7, sodium: 1 },
  { name: 'Ajo en Polvo', servingSize: '1 cucharadita (3g)', calories: 10, protein: 0.5, carbohydrates: 2, fat: 0, fiber: 0.6, sodium: 1 },
  { name: 'Sazonador Italiano', servingSize: '1 cucharadita (1.5g)', calories: 5, protein: 0.2, carbohydrates: 1.1, fat: 0.3, fiber: 0.6, sodium: 1 },
  { name: 'Hierbas Provenzales', servingSize: '1 cucharadita (1.5g)', calories: 5, protein: 0.2, carbohydrates: 1.1, fat: 0.3, fiber: 0.6, sodium: 1 },
  { name: 'Salvia (Seca)', servingSize: '1 cucharadita (1g)', calories: 3, protein: 0.1, carbohydrates: 0.7, fat: 0.1, fiber: 0.3, sodium: 1 },

  // ============================================
  // Nuevos Alimentos y Bebidas
  // ============================================

  // Nuevas Carnes y Proteínas
  { name: 'Conejo (Cocido)', servingSize: '100g', calories: 173, protein: 33, carbohydrates: 0, fat: 4.3, fiber: 0, sodium: 55 },
  { name: 'Venado (Cocido)', servingSize: '100g', calories: 157, protein: 30, carbohydrates: 0, fat: 3.5, fiber: 0, sodium: 60 },
  { name: 'Búfalo (Cocido)', servingSize: '100g', calories: 143, protein: 27, carbohydrates: 0, fat: 3.5, fiber: 0, sodium: 55 },

  // Nuevos Pescados y Mariscos
  { name: 'Trucha (Cocida)', servingSize: '100g', calories: 148, protein: 20, carbohydrates: 0, fat: 7, fiber: 0, sodium: 55 },
  { name: 'Calamar (Crudo)', servingSize: '100g', calories: 92, protein: 15, carbohydrates: 5.2, fat: 2.3, fiber: 0, sodium: 233 },
  { name: 'Pulpo (Cocido)', servingSize: '100g', calories: 164, protein: 29, carbohydrates: 1.5, fat: 2.3, fiber: 0, sodium: 282 },
  { name: 'Cangrejo (Cocido)', servingSize: '100g', calories: 97, protein: 19, carbohydrates: 0, fat: 1.5, fiber: 0, sodium: 410 },
  { name: 'Vieiras (Cocidas)', servingSize: '100g', calories: 111, protein: 20, carbohydrates: 3.3, fat: 1.8, fiber: 0, sodium: 361 },

  // Nuevas Verduras y Frutas
  { name: 'Kale (Cocido)', servingSize: '1 taza (130g)', calories: 36, protein: 2.5, carbohydrates: 7, fat: 0.5, fiber: 2.6, sodium: 25 },
  { name: 'Pimientos de Pimiento (Amarillos)', servingSize: '1 taza (150g)', calories: 50, protein: 1.5, carbohydrates: 11, fat: 0.5, fiber: 3, sodium: 5 },
  { name: 'Remolacha (Cocida)', servingSize: '1 taza (136g)', calories: 75, protein: 2.9, carbohydrates: 17, fat: 0.2, fiber: 3.8, sodium: 65 },
  { name: 'Tomate Cherry', servingSize: '1 taza (149g)', calories: 27, protein: 1.3, carbohydrates: 5.8, fat: 0.3, fiber: 1.7, sodium: 5 },
  { name: 'Pimiento Verde', servingSize: '1 taza (92g)', calories: 24, protein: 1, carbohydrates: 5.5, fat: 0.2, fiber: 1.5, sodium: 2 },
  { name: 'Brotes de Bambú', servingSize: '1 taza (70g)', calories: 30, protein: 1.5, carbohydrates: 7, fat: 0.3, fiber: 2, sodium: 5 },
  { name: 'Okra (Cocida)', servingSize: '1 taza (100g)', calories: 33, protein: 1.9, carbohydrates: 7.5, fat: 0.2, fiber: 3.2, sodium: 7 },
  { name: 'Espinaca (Cruda)', servingSize: '1 taza (30g)', calories: 7, protein: 0.9, carbohydrates: 1.1, fat: 0.1, fiber: 0.7, sodium: 24 },
  { name: 'Chayote (Crudo)', servingSize: '1 taza (132g)', calories: 20, protein: 0.8, carbohydrates: 4.6, fat: 0.1, fiber: 2.2, sodium: 8 },
  { name: 'Radicheta', servingSize: '1 taza (55g)', calories: 8, protein: 0.5, carbohydrates: 1.6, fat: 0.1, fiber: 1, sodium: 10 },

  // Nuevos Snacks y Aperitivos
  { name: 'Chips de Kale', servingSize: '1 oz (28g)', calories: 120, protein: 3, carbohydrates: 10, fat: 8, fiber: 2, sodium: 150 },
  { name: 'Trozos de Queso', servingSize: '1 oz (28g)', calories: 113, protein: 6.4, carbohydrates: 0.4, fat: 9.3, fiber: 0, sodium: 174 },
  { name: 'Pipocas de Coliflor', servingSize: '1 taza (100g)', calories: 50, protein: 3, carbohydrates: 8, fat: 1, fiber: 2, sodium: 10 },
  { name: 'Rollitos de Jamón y Queso', servingSize: '3 unidades (60g)', calories: 150, protein: 10, carbohydrates: 2, fat: 12, fiber: 0, sodium: 300 },
  { name: 'Bocados de Pollo', servingSize: '100g', calories: 210, protein: 20, carbohydrates: 5, fat: 13, fiber: 1, sodium: 400 },
  { name: 'Nueces de Brasil', servingSize: '1 oz (28g)', calories: 187, protein: 4, carbohydrates: 3.3, fat: 19, fiber: 2, sodium: 1 },
  { name: 'Pistachos', servingSize: '1 oz (28g)', calories: 159, protein: 6, carbohydrates: 8, fat: 13, fiber: 3, sodium: 1 },
  { name: 'Cacahuates (Crudos)', servingSize: '1 oz (28g)', calories: 161, protein: 7, carbohydrates: 6, fat: 14, fiber: 2, sodium: 7 },
  { name: 'Semillas de Chía', servingSize: '1 oz (28g)', calories: 138, protein: 4.7, carbohydrates: 12, fat: 8.7, fiber: 10.6, sodium: 5 },
  { name: 'Semillas de Linaza', servingSize: '1 oz (28g)', calories: 150, protein: 5.2, carbohydrates: 8.1, fat: 12, fiber: 7.6, sodium: 7 },
  { name: 'Semillas de Calabaza', servingSize: '1 oz (28g)', calories: 151, protein: 7, carbohydrates: 5.3, fat: 13, fiber: 1.1, sodium: 5 },
  { name: 'Semillas de Girasol', servingSize: '1 oz (28g)', calories: 164, protein: 5.8, carbohydrates: 6.4, fat: 14, fiber: 2.4, sodium: 1 },

  // ============================================
  // Alimentos Internacionales
  // ============================================

  // Comida Italiana
  { name: 'Lasagna', servingSize: '1 porción (250g)', calories: 336, protein: 16, carbohydrates: 40, fat: 10, fiber: 4, sodium: 800 },
  { name: 'Risotto', servingSize: '1 taza (250g)', calories: 300, protein: 7, carbohydrates: 55, fat: 5, fiber: 2, sodium: 600 },
  { name: 'Polenta (Cocida)', servingSize: '1 taza (154g)', calories: 156, protein: 4, carbohydrates: 32, fat: 1.5, fiber: 2, sodium: 14 },

  // Comida Mexicana
  { name: 'Chiles en Nogada', servingSize: '1 porción (250g)', calories: 400, protein: 15, carbohydrates: 40, fat: 18, fiber: 5, sodium: 700 },
  { name: 'Tamales (de Pollo)', servingSize: '1 unidad (300g)', calories: 350, protein: 15, carbohydrates: 40, fat: 12, fiber: 5, sodium: 600 },

  // Comida China
  { name: 'Arroz Frito', servingSize: '1 taza (200g)', calories: 242, protein: 4.4, carbohydrates: 53, fat: 0.4, fiber: 0.6, sodium: 800 },
  { name: 'Dim Sum', servingSize: '5 unidades (150g)', calories: 300, protein: 10, carbohydrates: 50, fat: 5, fiber: 3, sodium: 500 },
  { name: 'Fideos Lo Mein', servingSize: '1 taza (200g)', calories: 220, protein: 8, carbohydrates: 40, fat: 5, fiber: 3, sodium: 600 },

  // Comida Japonesa
  { name: 'Sushi (6 piezas)', servingSize: '6 piezas (200g)', calories: 300, protein: 10, carbohydrates: 50, fat: 5, fiber: 2, sodium: 700 },
  { name: 'Ramen', servingSize: '1 tazón (450g)', calories: 500, protein: 20, carbohydrates: 60, fat: 15, fiber: 4, sodium: 2000 },
  { name: 'Tempura', servingSize: '5 piezas (150g)', calories: 450, protein: 10, carbohydrates: 35, fat: 25, fiber: 2, sodium: 600 },

  // Comida India
  { name: 'Butter Chicken', servingSize: '1 porción (250g)', calories: 450, protein: 25, carbohydrates: 35, fat: 20, fiber: 3, sodium: 800 },
  { name: 'Palak Paneer', servingSize: '1 porción (250g)', calories: 300, protein: 15, carbohydrates: 20, fat: 15, fiber: 5, sodium: 500 },
  { name: 'Chana Masala', servingSize: '1 porción (250g)', calories: 350, protein: 15, carbohydrates: 45, fat: 10, fiber: 12, sodium: 700 },

  // Comida Tailandesa
  { name: 'Pad Thai', servingSize: '1 porción (300g)', calories: 400, protein: 15, carbohydrates: 55, fat: 12, fiber: 4, sodium: 800 },
  { name: 'Green Curry', servingSize: '1 porción (250g)', calories: 350, protein: 10, carbohydrates: 25, fat: 20, fiber: 3, sodium: 700 },
  { name: 'Som Tam (Ensalada de Papaya)', servingSize: '1 taza (150g)', calories: 150, protein: 3, carbohydrates: 20, fat: 5, fiber: 5, sodium: 300 },

  // Comida Española
  { name: 'Paella', servingSize: '1 porción (300g)', calories: 450, protein: 20, carbohydrates: 60, fat: 12, fiber: 4, sodium: 800 },
  { name: 'Gazpacho', servingSize: '1 taza (240g)', calories: 100, protein: 3, carbohydrates: 15, fat: 5, fiber: 2, sodium: 500 },
  { name: 'Tortilla Española', servingSize: '1 porción (150g)', calories: 200, protein: 6, carbohydrates: 12, fat: 14, fiber: 3, sodium: 300 },

  // Comida Francesa
  { name: 'Quiche Lorraine', servingSize: '1 porción (200g)', calories: 400, protein: 12, carbohydrates: 30, fat: 25, fiber: 2, sodium: 800 },
  { name: 'Croissant', servingSize: '1 unidad (50g)', calories: 270, protein: 5, carbohydrates: 30, fat: 14, fiber: 2, sodium: 230 },
  { name: 'Ratatouille', servingSize: '1 taza (240g)', calories: 150, protein: 3, carbohydrates: 15, fat: 10, fiber: 5, sodium: 300 },

  // Comida Griega
  { name: 'Moussaka', servingSize: '1 porción (250g)', calories: 400, protein: 15, carbohydrates: 35, fat: 18, fiber: 4, sodium: 700 },
  { name: 'Gyro', servingSize: '1 unidad (200g)', calories: 400, protein: 20, carbohydrates: 35, fat: 18, fiber: 4, sodium: 800 },
  { name: 'Souvlaki', servingSize: '4 brochetas (200g)', calories: 300, protein: 25, carbohydrates: 10, fat: 18, fiber: 0, sodium: 600 },
  
    // Add more food items here...
{ name: 'Hamburguesa con Queso', servingSize: '1 unidad (250g)', calories: 500, protein: 25, carbohydrates: 40, fat: 25, fiber: 3, sodium: 900 },
{ name: 'Hot Dog', servingSize: '1 unidad (150g)', calories: 300, protein: 12, carbohydrates: 30, fat: 15, fiber: 2, sodium: 800 },
{ name: 'Mac and Cheese', servingSize: '1 taza (200g)', calories: 350, protein: 15, carbohydrates: 35, fat: 15, fiber: 3, sodium: 700 },

// Comida Turca
{ name: 'Kebap de Adana', servingSize: '1 porción (200g)', calories: 400, protein: 30, carbohydrates: 10, fat: 25, fiber: 1, sodium: 800 },
{ name: 'Börek', servingSize: '2 piezas (150g)', calories: 350, protein: 8, carbohydrates: 30, fat: 20, fiber: 2, sodium: 600 },
{ name: 'Dolma (Hojas de Parra Rellenas)', servingSize: '3 unidades (150g)', calories: 250, protein: 5, carbohydrates: 40, fat: 8, fiber: 5, sodium: 500 },

// Comida Libanesa
{ name: 'Tabbouleh', servingSize: '1 taza (150g)', calories: 180, protein: 4, carbohydrates: 25, fat: 8, fiber: 5, sodium: 300 },
{ name: 'Falafel', servingSize: '4 unidades (100g)', calories: 350, protein: 12, carbohydrates: 35, fat: 20, fiber: 8, sodium: 600 },
{ name: 'Hummus', servingSize: '1/2 taza (120g)', calories: 400, protein: 15, carbohydrates: 30, fat: 25, fiber: 10, sodium: 800 },

// Comida Brasileña
{ name: 'Feijoada', servingSize: '1 porción (300g)', calories: 600, protein: 35, carbohydrates: 50, fat: 25, fiber: 10, sodium: 1000 },
{ name: 'Brigadeiro', servingSize: '2 unidades (50g)', calories: 200, protein: 3, carbohydrates: 25, fat: 10, fiber: 1, sodium: 100 },
{ name: 'Pão de Queijo', servingSize: '3 unidades (100g)', calories: 300, protein: 8, carbohydrates: 35, fat: 12, fiber: 2, sodium: 400 },

// Comida Coreana
{ name: 'Bibimbap', servingSize: '1 porción (450g)', calories: 550, protein: 20, carbohydrates: 70, fat: 15, fiber: 8, sodium: 900 },
{ name: 'Kimchi Jjigae', servingSize: '1 tazón (350g)', calories: 200, protein: 10, carbohydrates: 15, fat: 10, fiber: 3, sodium: 1200 },
{ name: 'Bulgogi', servingSize: '1 porción (200g)', calories: 400, protein: 25, carbohydrates: 20, fat: 20, fiber: 2, sodium: 800 },

// Comida Mediterránea
{ name: 'Shakshuka', servingSize: '1 porción (250g)', calories: 300, protein: 15, carbohydrates: 20, fat: 18, fiber: 5, sodium: 700 },
{ name: 'Meze Platter', servingSize: '1 porción (300g)', calories: 450, protein: 20, carbohydrates: 35, fat: 25, fiber: 6, sodium: 900 },
{ name: 'Baklava', servingSize: '2 piezas (100g)', calories: 500, protein: 6, carbohydrates: 60, fat: 25, fiber: 3, sodium: 300 },

// Comida Vietnamita
{ name: 'Pho', servingSize: '1 tazón (500g)', calories: 400, protein: 20, carbohydrates: 50, fat: 10, fiber: 3, sodium: 1200 },
{ name: 'Bánh Mì', servingSize: '1 sándwich (250g)', calories: 450, protein: 15, carbohydrates: 60, fat: 15, fiber: 4, sodium: 900 },
{ name: 'Gỏi Cuốn (Rollitos de Primavera Frescos)', servingSize: '4 unidades (200g)', calories: 300, protein: 10, carbohydrates: 40, fat: 8, fiber: 5, sodium: 400 },

// Comida Peruana
{ name: 'Ceviche', servingSize: '1 porción (200g)', calories: 250, protein: 20, carbohydrates: 10, fat: 10, fiber: 2, sodium: 800 },
{ name: 'Lomo Saltado', servingSize: '1 porción (300g)', calories: 500, protein: 30, carbohydrates: 40, fat: 20, fiber: 5, sodium: 900 },
{ name: 'Aji de Gallina', servingSize: '1 porción (250g)', calories: 450, protein: 25, carbohydrates: 35, fat: 20, fiber: 3, sodium: 800 },

// Comida Tailandesa
{ name: 'Tom Yum Soup', servingSize: '1 tazón (300g)', calories: 150, protein: 10, carbohydrates: 15, fat: 5, fiber: 2, sodium: 800 },
{ name: 'Massaman Curry', servingSize: '1 porción (350g)', calories: 500, protein: 20, carbohydrates: 45, fat: 25, fiber: 5, sodium: 900 },
{ name: 'Mango Sticky Rice', servingSize: '1 porción (200g)', calories: 300, protein: 5, carbohydrates: 60, fat: 8, fiber: 3, sodium: 50 },

// Comida Alemana
{ name: 'Bratwurst', servingSize: '1 unidad (150g)', calories: 350, protein: 15, carbohydrates: 5, fat: 30, fiber: 0, sodium: 800 },
{ name: 'Sauerbraten', servingSize: '1 porción (250g)', calories: 450, protein: 30, carbohydrates: 20, fat: 25, fiber: 2, sodium: 700 },
{ name: 'Pretzel', servingSize: '1 unidad (100g)', calories: 380, protein: 9, carbohydrates: 80, fat: 3, fiber: 4, sodium: 800 },

// Comida Etiopía
{ name: 'Doro Wat', servingSize: '1 porción (300g)', calories: 500, protein: 25, carbohydrates: 30, fat: 25, fiber: 5, sodium: 900 },
{ name: 'Injera', servingSize: '2 porciones (400g)', calories: 350, protein: 10, carbohydrates: 70, fat: 2, fiber: 6, sodium: 400 },
{ name: 'Tibs', servingSize: '1 porción (200g)', calories: 400, protein: 20, carbohydrates: 10, fat: 30, fiber: 2, sodium: 700 }
  ];
  
  export default foodDataset;
  