// src/components/Sidebar.js
import React, { useEffect } from 'react';
import { useTheme } from '../ThemeContext';
import HealthMetricsForm from './formats/HealthMetricsForm';
import Auth from './Auth'; // Importa el componente Auth
import logo from '../../logo.svg'; // Ajusta la ruta según sea necesario
import { ReactComponent as ToggleSide } from './toggleside.svg'; // Importa useAuth

const Sidebar = () => {
  const { theme, isSidebarVisible, toggleSidebar } = useTheme();

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden'; // Bloquear scroll en el fondo
    } else {
      document.body.style.overflow = ''; // Restaurar scroll
    }
  }, [isSidebarVisible]);

  return (
    <>
      {/* Botón de alternar */}
      <button
        className={`${
          theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'
        } 
          p-0.5 
          rounded-full 
          fixed 
          top-2 
          left-2 
          shadow-md 
          hover:bg-opacity-80 
          transition-all 
          z-50 
          flex 
          items-center 
          justify-center
          w-8 
          h-8`}
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        <ToggleSide
          className={`${
            isSidebarVisible ? 'transform rotate-180' : ''
          } text-blue-400`}
        />
      </button>

      {/* Sidebar */}
      <div
        className={`scrollbar scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-transparent transform ${
          isSidebarVisible ? 'translate-x-0' : '-translate-x-full'
        } ${
          theme === 'dark' ? 'bg-black text-white' : 'bg-gray-100 text-gray-900'
        } 
          fixed 
          top-0 
          left-0 
          w-64 
          h-full 
          z-40 
          shadow-2xl 
          transition-transform 
          duration-300 
          ease-in-out 
          overflow-y-auto`}
      >
        <img src={logo} alt="Logo" className="sidebar-logo w-20 mx-auto my-6 animate-pulse" />
        
        {/* Componente Auth */}
        <Auth />

        {/* Mostrar siempre el formulario de Health Metrics */}
        <div className="my-4 border-t border-gray-300"></div>
        <h2 className="text-center text-lg font-semibold">Tus datos de inicio</h2>
        <HealthMetricsForm />
      </div>
    </>
  );
};

export default Sidebar;
