// trainingPlanUtils.js

import {
  calculateBMR,
  calculateTDEE,
  calculateBMI,
  muscleGainRates,
  calculateBodyFat,
} from './calculations';

// Estructura actualizada de fitnessLevelPlans
const fitnessLevelPlans = {
  'Estado físico atlético': [
    {
      title: 'Empuje',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de empuje.',
      routine: [
        { nombre: 'Press de banca', series: 4, repeticiones: 8 },
        { nombre: 'Press militar', series: 4, repeticiones: 10 },
        { nombre: 'Fondos en paralelas', series: 3, repeticiones: 12 },
        { nombre: 'Flexiones de pecho', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Tirón',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de tirón.',
      routine: [
        { nombre: 'Dominadas', series: 4, repeticiones: 8 },
        { nombre: 'Remo con barra', series: 4, repeticiones: 10 },
        { nombre: 'Remo en polea baja', series: 3, repeticiones: 12 },
        { nombre: 'Curl de bíceps', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Piernas',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de piernas.',
      routine: [
        { nombre: 'Sentadillas', series: 4, repeticiones: 8 },
        { nombre: 'Peso muerto', series: 4, repeticiones: 10 },
        { nombre: 'Prensa de piernas', series: 3, repeticiones: 12 },
        { nombre: 'Elevación de talones (gemelos)', series: 4, repeticiones: 15 },
      ],
    },
    {
      title: 'Descanso y recuperación',
      location: 'Casa',
      description:
        'Tómate un día de descanso y recuperación. Mantente hidratado y duerme lo suficiente.',
      routine: [],
    },
  ],
  'Estado físico bueno': [
    {
      title: 'Empuje',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de empuje.',
      routine: [
        { nombre: 'Press de banca', series: 3, repeticiones: 10 },
        { nombre: 'Press militar con mancuernas', series: 3, repeticiones: 12 },
        { nombre: 'Fondos asistidos', series: 3, repeticiones: 15 },
        { nombre: 'Flexiones de pecho', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Tirón',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de tirón.',
      routine: [
        { nombre: 'Jalón al pecho', series: 3, repeticiones: 10 },
        { nombre: 'Remo inclinado con mancuernas', series: 3, repeticiones: 12 },
        { nombre: 'Remo en máquina', series: 3, repeticiones: 15 },
        { nombre: 'Curl de bíceps con barra', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Piernas',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de piernas.',
      routine: [
        { nombre: 'Sentadillas en máquina Smith', series: 3, repeticiones: 10 },
        { nombre: 'Peso muerto rumano', series: 3, repeticiones: 12 },
        { nombre: 'Prensa de piernas', series: 3, repeticiones: 15 },
        { nombre: 'Elevación de talones (gemelos)', series: 4, repeticiones: 15 },
      ],
    },
    {
      title: 'Descanso y recuperación',
      location: 'Casa',
      description:
        'Tómate un día de descanso y recuperación. Mantente hidratado y duerme lo suficiente.',
      routine: [],
    },
  ],
  'Estado físico normal': [
    {
      title: 'Empuje',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de empuje.',
      routine: [
        { nombre: 'Press de banca en máquina', series: 3, repeticiones: 12 },
        { nombre: 'Press militar sentado', series: 3, repeticiones: 12 },
        { nombre: 'Fondos asistidos o flexiones en el suelo', series: 3, repeticiones: 15 },
        { nombre: 'Flexiones de pecho', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Tirón',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de tirón.',
      routine: [
        { nombre: 'Jalón al pecho en máquina', series: 3, repeticiones: 12 },
        { nombre: 'Remo en máquina', series: 3, repeticiones: 15 },
        { nombre: 'Remo sentado con cable', series: 3, repeticiones: 15 },
        { nombre: 'Curl de bíceps con mancuernas', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Piernas',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de piernas.',
      routine: [
        { nombre: 'Sentadillas en máquina Smith', series: 3, repeticiones: 12 },
        { nombre: 'Peso muerto con mancuernas', series: 3, repeticiones: 15 },
        { nombre: 'Prensa de piernas', series: 3, repeticiones: 15 },
        { nombre: 'Elevación de talones (gemelos)', series: 4, repeticiones: 15 },
      ],
    },
    {
      title: 'Descanso y recuperación',
      location: 'Casa',
      description:
        'Tómate un día de descanso y recuperación. Mantente hidratado y duerme lo suficiente.',
      routine: [],
    },
  ],
  'Bajo estado físico': [
    {
      title: 'Empuje',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de empuje para principiantes.',
      routine: [
        { nombre: 'Press de banca en máquina asistida', series: 3, repeticiones: 15 },
        { nombre: 'Press militar sentado en máquina', series: 3, repeticiones: 15 },
        { nombre: 'Fondos asistidos en máquina', series: 3, repeticiones: 15 },
        { nombre: 'Flexiones en pared', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Tirón',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de tirón para principiantes.',
      routine: [
        { nombre: 'Jalón al pecho en máquina asistida', series: 3, repeticiones: 15 },
        { nombre: 'Remo sentado en máquina', series: 3, repeticiones: 15 },
        { nombre: 'Remo con banda de resistencia', series: 3, repeticiones: 15 },
        { nombre: 'Curl de bíceps con banda de resistencia', series: 3, repeticiones: 15 },
      ],
    },
    {
      title: 'Piernas',
      location: 'Gimnasio',
      description: 'Sesión enfocada en ejercicios de piernas para principiantes.',
      routine: [
        { nombre: 'Sentadillas en máquina asistida', series: 3, repeticiones: 15 },
        { nombre: 'Peso muerto con banda de resistencia', series: 3, repeticiones: 15 },
        { nombre: 'Prensa de piernas en máquina asistida', series: 3, repeticiones: 15 },
        { nombre: 'Elevación de talones en máquina asistida', series: 4, repeticiones: 15 },
      ],
    },
    {
      title: 'Descanso y recuperación',
      location: 'Casa',
      description:
        'Tómate un día de descanso y recuperación. Mantente hidratado y duerme lo suficiente.',
      routine: [],
    },
  ],
};

// Exportamos fitnessLevelPlans para poder usarlo en otros archivos si es necesario
export { fitnessLevelPlans };

// Función updateCalendarWithPlan actualizada para recibir setProjections
export const updateCalendarWithPlan = (
  plan,
  healthMetrics,
  createEvent,
  calculateProjections,
  setEvents,
  events,
  onEventsUpdate,
  setProjections,
  setError
) => {
  const {
    start,
    end,
    fitnessLevel,
    caloricIntake,
    useCreatine,
  } = plan;

  if (
    !healthMetrics.weight ||
    !healthMetrics.height ||
    !healthMetrics.sex ||
    !healthMetrics.age ||
    !healthMetrics.activityFactor
  ) {
    setError('Faltan métricas de salud necesarias para generar proyecciones.');
    return;
  }

  const {
    weight,
    height,
    neck,
    waist,
    hip,
    sex,
    age,
    activityFactor,
  } = healthMetrics;

  const startDate = new Date(start);
  const endDate = new Date(end);
  let dayCounter = 1;
  let exerciseDaysCounter = 0;

  const exercisePlan = fitnessLevelPlans[fitnessLevel];
  if (!exercisePlan) {
    setError('Nivel de fitness desconocido. No se puede generar el plan.');
    return;
  }

  const caloricIntakeOptions = {
    recomp: 0,
    volum: 500,
    defin: -500,
  };

  const caloricIntakeValue = caloricIntakeOptions[caloricIntake] || 0;

  const newEvents = [];
  const projections = [];
  let previousWeight = parseFloat(weight);
  let bodyFatPercentage = calculateBodyFat(sex, previousWeight, height, neck, waist, hip);
  let previousFatWeight = previousWeight * (bodyFatPercentage / 100);
  let totalMuscleGain = 0;

  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    const currentDate = new Date(date); // Clonar la fecha actual del bucle

    // Crear nuevas instancias de Date para startEvent y endEvent sin modificar currentDate
    const startEventDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 18, 0, 0, 0);
    const endEventDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 19, 0, 0, 0);

    const startEvent = startEventDate.toISOString();
    const endEvent = endEventDate.toISOString();

    const dayOfWeek = currentDate.getDay();
    let exercise;

    if (dayOfWeek === 0 || exerciseDaysCounter === 4) {
      exercise = exercisePlan[3]; // Día de descanso
      exerciseDaysCounter = 0;
    } else {
      exercise = exercisePlan[(dayCounter - 1) % 3];
      exerciseDaysCounter++;
    }

    const event = {
      id: Date.now() + newEvents.length,
      planId: plan.id,
      title: `Día de plan ${newEvents.length + 1}`,
      summary: exercise.title,
      description: exercise.description,
      location: exercise.location,
      routine: exercise.routine,
      start: startEvent,
      end: endEvent,
      allDay: false,
      planDay: newEvents.length + 1,
      fitnessLevel,
      caloricIntake,
      useCreatine,
      activityFactor,
      sex,
      age,
    };
    createEvent(event);
    newEvents.push(event);

    const metrics = calculateProjections(
      dayCounter,
      previousWeight,
      previousFatWeight,
      activityFactor,
      exerciseDaysCounter === 0,
      fitnessLevel,
      totalMuscleGain,
      caloricIntakeValue,
      caloricIntake,
      useCreatine,
      sex,
      height,
      age
    );

    if (!metrics) {
      setError(`Proyecciones no generadas para el día ${dayCounter}`);
      return;
    }

    projections.push({
      ...metrics,
      fitnessLevel,
      caloricIntake,
      useCreatine,
      activityFactor,
      sex,
      age,
    });
    previousWeight = parseFloat(metrics.dailyWeight);
    previousFatWeight = parseFloat(metrics.dailyFatWeight);
    totalMuscleGain += parseFloat(metrics.dailyMuscleGain);
    dayCounter++;
  }

  setEvents(newEvents);
  localStorage.setItem('events', JSON.stringify(newEvents));
  localStorage.setItem('projections', JSON.stringify(projections));
  onEventsUpdate(newEvents);

  setProjections(projections);
};


// Función calculateProjections
export const calculateProjections = (
  index,
  previousWeight,
  previousFatWeight,
  activityFactor,
  isRestDay,
  fitnessLevel,
  totalMuscleGain,
  caloricIntakeValue,
  caloricIntakeType,
  useCreatine,
  sex,
  height,
  age
) => {
  const bmr = parseFloat(calculateBMR(sex, previousWeight, height, age));
  const dailyTDEE = isRestDay ? bmr : parseFloat(calculateTDEE(bmr, activityFactor));

  let dailyCaloricIntake;
  if (caloricIntakeType === 'defin') {
    dailyCaloricIntake = dailyTDEE - 500;
  } else if (caloricIntakeType === 'recomp') {
    dailyCaloricIntake = dailyTDEE - 200;
  } else if (caloricIntakeType === 'volum') {
    dailyCaloricIntake = dailyTDEE + caloricIntakeValue;
  }

  const dailyCaloricSurplusOrDeficit = dailyCaloricIntake - dailyTDEE;

  const maxMuscleGainRate = muscleGainRates[fitnessLevel] / 30;
  const creatineBoostFactor = useCreatine ? 1.1 : 1;

  const normalizedActivityFactor = (activityFactor - 1.2) / (1.9 - 1.2);
  const adjustedMuscleGainRate = maxMuscleGainRate * normalizedActivityFactor;

  let dailyMuscleGain = 0;
  let dailyFatChange = 0;

  const baseFatChangeRate = dailyCaloricSurplusOrDeficit / 7700;

  if (caloricIntakeType === 'defin') {
    const targetWeeklyFatLoss = previousWeight * 0.01;
    dailyFatChange = -targetWeeklyFatLoss / 7;
    dailyMuscleGain = adjustedMuscleGainRate * 0.2 * creatineBoostFactor;
  } else if (caloricIntakeType === 'recomp') {
    dailyFatChange = baseFatChangeRate * 1.5;
    dailyMuscleGain = adjustedMuscleGainRate * 0.6 * creatineBoostFactor;
  } else if (caloricIntakeType === 'volum') {
    dailyFatChange = baseFatChangeRate * 0.6;
    dailyMuscleGain = adjustedMuscleGainRate * creatineBoostFactor;
  }

  dailyMuscleGain = Math.min(Math.max(dailyMuscleGain, 0), 0.07);
  dailyFatChange = Math.min(Math.max(dailyFatChange, -0.3), 0.1);

  const dailyWeightChange = dailyMuscleGain + dailyFatChange;
  const dailyWeight = (previousWeight + dailyWeightChange).toFixed(2);
  const dailyLeanWeight = (
    previousWeight - previousFatWeight + totalMuscleGain + dailyMuscleGain
  ).toFixed(2);
  const dailyBMI = calculateBMI(dailyWeight, height);
  const newFatWeight = Math.max(previousFatWeight + dailyFatChange, 0);
  const dailyBodyFat = ((newFatWeight / dailyWeight) * 100).toFixed(2);

  return {
    dailyWeight,
    dailyBMI,
    dailyTDEE: dailyTDEE.toFixed(2),
    dailyLeanWeight,
    dailyMuscleGain: dailyMuscleGain.toFixed(4),
    totalMuscleGain: (totalMuscleGain + dailyMuscleGain).toFixed(2),
    dailyFatWeight: newFatWeight.toFixed(2),
    dailyFatChange: dailyFatChange.toFixed(4),
    dailyBodyFat,
    dailyCaloricIntake: dailyCaloricIntake.toFixed(2),
  };
};
