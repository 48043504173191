import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useEventPlanManager } from '../Dashboard/CalendarComponent/eventPlanUtils';
import { useAuth } from './AuthContext';
import { useTodayDate, useDateRange } from '../Dashboard/CalendarComponent/dateUtils';
import { getHealthMetrics, getPlans, getEntries } from '../Sidebar/formats/apiService';
import {
  loadPlanData,
  savePlanData,
  loadHealthMetrics,
  saveHealthMetrics,
  removeItem,
} from '../Dashboard/CalendarComponent/dataManager';

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const todayDate = useTodayDate();
  const {
    dateRange,
    setDateRange,
    rangeSelected,
    setRangeSelected,
    activeRange,
    setActiveRange,
    setRangeByDays,
    cancelRangeSelection,
  } = useDateRange(todayDate);

  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [caloricIntake, setCaloricIntake] = useState(localStorage.getItem('caloricIntake') || 'recomp');
  const [useCreatine, setUseCreatine] = useState(localStorage.getItem('useCreatine') === 'true' || false);

  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(null);
  const [healthMetrics, setHealthMetrics] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Guardar preferencias localmente
  useEffect(() => {
    localStorage.setItem('caloricIntake', caloricIntake);
  }, [caloricIntake]);

  useEffect(() => {
    localStorage.setItem('useCreatine', useCreatine);
  }, [useCreatine]);

  const addEntry = useCallback((entry) => {
    setEntries((prevEntries) => [...prevEntries, entry]);
  }, []);

  const deleteEntry = useCallback((id) => {
    setEntries((prevEntries) => prevEntries.filter((entry) => entry.id !== id));
  }, []);

  useEffect(() => {
    const fetchHealthMetrics = async () => {
      if (user) {
        try {
          const metrics = await getHealthMetrics();
          setHealthMetrics(metrics);
          saveHealthMetrics(metrics);
        } catch (err) {
          console.error('Error fetching health metrics:', err);
          setError(err.message);
        }
      } else {
        const localMetrics = loadHealthMetrics();
        setHealthMetrics(localMetrics);
      }
    };
    fetchHealthMetrics();
  }, [user]);

  useEffect(() => {
    const fetchPlans = async () => {
      if (user) {
        try {
          const plansData = await getPlans();
          setPlans(plansData);
          removeItem('planData');
          removeItem('events');
          removeItem('projections');
          if (plansData && plansData.length > 0) {
            const plan = {
              ...plansData[0],
              start: new Date(plansData[0].start),
              end: new Date(plansData[0].end),
            };
            savePlanData(plan);
          }
        } catch (err) {
          console.error('Error fetching plans:', err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        const localPlanData = loadPlanData();
        if (localPlanData) {
          setPlans([localPlanData]);
        }
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, [user]);

  const planManager = useEventPlanManager(
    dateRange,
    caloricIntake,
    useCreatine,
    healthMetrics,
    plans
  );

  const planId = planManager.planData?._id || null;

  useEffect(() => {
    const loadPlanEntries = async () => {
      if (planId && planManager.planData) {
        try {
          const { start, end } = planManager.planData;
          const startDate = new Date(start);
          const endDate = new Date(end);
          const fetchedEntries = await getEntries(planId, startDate, endDate);
          setEntries(fetchedEntries);
        } catch (error) {
          console.error('Error al cargar las entradas del plan:', error);
          setError(error.message);
        }
      } else {
        setEntries([]);
      }
    };
    loadPlanEntries();
  }, [planId, planManager.planData]);

  const handleRangeClick = useCallback(
    (days) => {
      setRangeByDays(days);
      setActiveRange(days);
      setRangeSelected(true);
    },
    [setRangeByDays, setActiveRange, setRangeSelected]
  );

  const onDayClick = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const getEventsForDate = useCallback(
    (date) => {
      const dateStr = date.toISOString().split('T')[0];
      return planManager.events.filter((event) => event.start.startsWith(dateStr));
    },
    [planManager.events]
  );

  const tileContent = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const eventsForDate = getEventsForDate(date);
        return (
          <>
            {eventsForDate.map((event) => (
              <div key={event.id} className="event-marker">
                {event.summary}
              </div>
            ))}
          </>
        );
      }
      return null;
    },
    [getEventsForDate]
  );

  const tileClassName = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const dateStr = date.toISOString().split('T')[0];
        const todayStr = todayDate.toISOString().split('T')[0];
        const selectedDateStr = selectedDate.toISOString().split('T')[0];

        let classes = '';
        if (dateStr === todayStr) classes += 'react-calendar__tile--now ';
        if (dateStr === selectedDateStr) classes += 'react-calendar__tile--active ';
        if (date < todayDate) classes += 'opacity-50 ';

        return classes.trim();
      }
      return null;
    },
    [todayDate, selectedDate]
  );

  const tileDisabled = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const isPast = date < todayDate;
        const eventsForDate = getEventsForDate(date);
        const isPartOfPlan = eventsForDate.length > 0;
        return isPast && !isPartOfPlan;
      }
      return false;
    },
    [getEventsForDate, todayDate]
  );

  const ranges = [
    { label: '1 Semana', days: 7 },
    { label: '2 Semanas', days: 14 },
    { label: '1 Mes', days: 30 },
    { label: '6 Semanas', days: 42 },
    { label: '2 Meses', days: 60 },
    { label: '3 Meses', days: 90 },
  ];

  const isPlanActive = planManager.events.length > 0;

  useEffect(() => {
    if (planManager.planCreated && planManager.planData) {
      setDateRange([new Date(planManager.planData.start), new Date(planManager.planData.end)]);
    }
  }, [planManager.planCreated, planManager.planData, setDateRange]);

  const handleCreatePlan = useCallback(
    async () => {
      try {
        await planManager.handleCreatePlan();
        if (planManager.planData) {
          setDateRange([new Date(planManager.planData.start), new Date(planManager.planData.end)]);
          setError(null);
        }
      } catch (err) {
        console.error('Error al crear el plan:', err);
        setError('Hubo un error al crear el plan. Por favor, intenta nuevamente.');
      }
    },
    [planManager, setDateRange]
  );

  const handleDeletePlanFn = useCallback(async () => {
    try {
      await planManager.handleDeletePlan();
      planManager.setPlanData(null);
      setDateRange([todayDate, todayDate]);
      setError(null);
    } catch (err) {
      console.error('Error al eliminar el plan:', err);
      setError('Hubo un error al eliminar el plan. Por favor, intenta nuevamente.');
    }
  }, [planManager, setDateRange, todayDate]);

  return (
    <PlanContext.Provider
      value={{
        ...planManager,
        dateRange,
        setDateRange,
        caloricIntake,
        setCaloricIntake,
        useCreatine,
        setUseCreatine,
        planId,
        isAuthenticated,
        user,
        rangeSelected,
        setRangeSelected,
        activeRange,
        setActiveRange,
        setRangeByDays,
        cancelRangeSelection,
        selectedDate,
        setSelectedDate,
        handleRangeClick,
        onDayClick,
        getEventsForDate,
        tileContent,
        tileClassName,
        tileDisabled,
        ranges,
        isPlanActive,
        handleCreatePlan,
        handleDeletePlan: handleDeletePlanFn,
        entries,
        addEntry,
        deleteEntry,
        error,
        planCreated: !!planManager.planData,
        isLoading,
        healthMetrics,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};
