// dateUtils.js
import { useMemo, useState, useCallback } from 'react';

// Función auxiliar para convertir cadenas a Date
const parseDate = (dateStr) => (dateStr ? new Date(dateStr) : new Date());

// Today's date without time
export const useTodayDate = () => {
  return useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);
};

// Date range management
export const useDateRange = (todayDate) => {
  const [dateRange, setDateRange] = useState(() => {
    const storedRange = localStorage.getItem('dateRange');
    if (storedRange) {
      const [startStr, endStr] = JSON.parse(storedRange);
      return [parseDate(startStr), parseDate(endStr)];
    }
    return [todayDate, todayDate];
  });

  const [rangeSelected, setRangeSelected] = useState(() => {
    return localStorage.getItem('rangeSelected') === 'true';
  });

  const [activeRange, setActiveRange] = useState(null);

  // Function to set the date range based on the provided number of days.
  const setRangeByDays = useCallback(
    (days) => {
      const endDate = new Date(todayDate);
      endDate.setDate(todayDate.getDate() + days - 1);
      setDateRange([todayDate, endDate]);
      localStorage.setItem(
        'dateRange',
        JSON.stringify([todayDate.toISOString(), endDate.toISOString()])
      );
      setRangeSelected(true);
      localStorage.setItem('rangeSelected', 'true');
      setActiveRange(days);
    },
    [todayDate]
  );

  // Function to cancel the date range selection.
  const cancelRangeSelection = useCallback(() => {
    setDateRange([todayDate, todayDate]);
    localStorage.removeItem('dateRange');
    setRangeSelected(false);
    localStorage.setItem('rangeSelected', 'false');
    setActiveRange(null);
  }, [todayDate]);

  return {
    dateRange,
    setDateRange,
    rangeSelected,
    setRangeSelected,
    activeRange,
    setActiveRange,
    setRangeByDays,
    cancelRangeSelection,
  };
};
