import React from 'react';

const MeasurementOfHips = () => {
    const styles = `
        /* Animaciones */
        @keyframes moveLeftArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(-10deg); }
        }

        @keyframes moveRightArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(10deg); }
        }

        @keyframes adjustTape {
            0%, 100% { rx: 25; ry: 8; }
            50% { rx: 22; ry: 6; }
        }

        /* Aplicar animaciones a los grupos de brazos */
        #left-arm-group {
            transform-origin: 35px 100px;
            animation: moveLeftArm 2s infinite ease-in-out;
        }

        #right-arm-group {
            transform-origin: 65px 100px;
            animation: moveRightArm 2s infinite ease-in-out;
        }

        /* Animación de la cinta métrica */
        #tape-ellipse {
            animation: adjustTape 2s infinite ease-in-out;
        }

        /* Estilos de los marcadores */
        .tape-marker {
            stroke: #fff;
            stroke-width: 0.5;
        }

        /* Estilos de los números */
        .tape-number {
            font-size: 3px;
            fill: #fff;
            text-anchor: middle;
            dominant-baseline: middle;
            font-family: Arial, sans-serif;
        }

        /* Dedos */
        .finger {
            stroke: #000;
            stroke-width: 0.3;
        }

        /* Texturas y Gradientes */
        .hair {
            fill: url(#hairGradient);
        }

        .shirt {
            fill: url(#shirtTexture);
        }

        /* Detalles adicionales */
        .outline {
            stroke: #000;
            stroke-width: 0.5;
            fill: none;
        }
    `;

    return (
        <svg width="100" height="200" viewBox="0 0 100 200" xmlns="http://www.w3.org/2000/svg">
            <style>{styles}</style>
            {/* Definición de texturas y gradientes */}
            <defs>
                {/* Gradiente para el cabello */}
                <linearGradient id="hairGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#8a6fa1" />
                    <stop offset="100%" stopColor="#6a4e77" />
                </linearGradient>

                {/* Textura para la camiseta */}
                <pattern id="shirtTexture" patternUnits="userSpaceOnUse" width="4" height="4">
                    <circle cx="2" cy="2" r="0.5" fill="#ffffff" opacity="0.2" />
                </pattern>

                {/* Gradiente para la piel */}
                <linearGradient id="skinGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#ffdbac" />
                    <stop offset="100%" stopColor="#e0ac69" />
                </linearGradient>

                {/* Gradiente para la cinta métrica */}
                <linearGradient id="tapeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#d32f2f" />
                    <stop offset="100%" stopColor="#f44336" />
                </linearGradient>
            </defs>

            {/* Cuerpo de la Persona */}
            <g id="person">
                {/* Cabello detrás del rostro */}
                <path
                    className="hair"
                    d="
                        M35,60
                        C25,40 25,20 50,20
                        C75,20 75,40 65,60
                        L65,130
                        C60,140 40,140 35,130
                        Z"
                    fill="url(#hairGradient)"
                    stroke="#000"
                    strokeWidth="0.5"
                />

                {/* Cabeza */}
                <circle cx="50" cy="40" r="15" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />

                {/* Cabello superior */}
                <path
                    className="hair"
                    d="
                        M35,25
                        Q50,5 65,25
                        "
                    fill="url(#hairGradient)"
                    stroke="#000"
                    strokeWidth="0.5"
                />

                {/* Detalles Faciales */}
                {/* Ojos más femeninos */}
                <ellipse cx="44" cy="38" rx="2" ry="3" fill="#fff" stroke="#000" strokeWidth="0.3" />
                <circle cx="44" cy="38" r="1" fill="#000" />
                <ellipse cx="56" cy="38" rx="2" ry="3" fill="#fff" stroke="#000" strokeWidth="0.3" />
                <circle cx="56" cy="38" r="1" fill="#000" />

                {/* Pestañas */}
                <line x1="42" y1="36" x2="41" y2="35" stroke="#000" strokeWidth="0.2" />
                <line x1="46" y1="36" x2="47" y2="35" stroke="#000" strokeWidth="0.2" />
                <line x1="54" y1="36" x2="53" y2="35" stroke="#000" strokeWidth="0.2" />
                <line x1="58" y1="36" x2="59" y2="35" stroke="#000" strokeWidth="0.2" />

                {/* Cejas más delgadas y arqueadas */}
                <path d="M42,35 Q44,33 46,35" stroke="#000" strokeWidth="0.2" fill="none" />
                <path d="M54,35 Q56,33 58,35" stroke="#000" strokeWidth="0.2" fill="none" />

                {/* Nariz más delicada */}
                <path d="M50,38 L50,42" stroke="#000" strokeWidth="0.2" />

                {/* Labios */}
                <path
                    d="M44,45 Q50,48 56,45"
                    stroke="#000"
                    strokeWidth="0.3"
                    fill="#e57373"
                />
                <path
                    d="M44,45 Q50,50 56,45"
                    stroke="#000"
                    strokeWidth="0.3"
                    fill="#e57373"
                />

                {/* Orejas */}
                <ellipse cx="35" cy="40" rx="2" ry="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.2" />
                <ellipse cx="65" cy="40" rx="2" ry="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.2" />

                {/* Cuello más estilizado */}
                <rect x="47" y="50" width="6" height="10" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />

                {/* Cuerpo con figura femenina */}
                <path
                    className="shirt"
                    d="
                        M35,60
                        C40,80 60,80 65,60
                        L65,130
                        C60,140 40,140 35,130
                        Z"
                    stroke="#000"
                    strokeWidth="0.5"
                />

                {/* Brazos */}
                {/* Brazo Izquierdo (animado) */}
                <g id="left-arm-group">
                    {/* Brazo Superior */}
                    <path
                        d="M35,70 C25,90 30,110 35,130"
                        fill="url(#skinGradient)"
                        stroke="#000"
                        strokeWidth="0.5"
                    />
                    {/* Mano Izquierda */}
                    <circle cx="35" cy="130" r="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />
                    {/* Dedos Izquierdos */}
                    <line className="finger" x1="33.5" y1="128.5" x2="32" y2="127" />
                    <line className="finger" x1="36.5" y1="128.5" x2="38" y2="127" />
                </g>

                {/* Brazo Derecho (animado) */}
                <g id="right-arm-group">
                    {/* Brazo Superior */}
                    <path
                        d="M65,70 C75,90 70,110 65,130"
                        fill="url(#skinGradient)"
                        stroke="#000"
                        strokeWidth="0.5"
                    />
                    {/* Mano Derecha */}
                    <circle cx="65" cy="130" r="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />
                    {/* Dedos Derechos */}
                    <line className="finger" x1="63.5" y1="128.5" x2="62" y2="127" />
                    <line className="finger" x1="66.5" y1="128.5" x2="68" y2="127" />
                </g>

                {/* Piernas más estilizadas */}
                <rect x="42" y="140" width="6" height="50" fill="#555" stroke="#000" strokeWidth="0.5" />
                <rect x="52" y="140" width="6" height="50" fill="#555" stroke="#000" strokeWidth="0.5" />
            </g>

            {/* Cinta Métrica alrededor de las Caderas */}
            <g id="tape-measure">
                {/* Cinta Métrica (elipse con animación) */}
                <ellipse
                    id="tape-ellipse"
                    cx="50"
                    cy="120"
                    rx="25"
                    ry="8"
                    stroke="url(#tapeGradient)"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                />

                {/* Marcadores de la cinta métrica */}
                <g className="tape-markers">
                    {/* Marcadores a lo largo de la elipse */}
                    <line className="tape-marker" x1="50" y1="112" x2="50" y2="114" />
                    <line className="tape-marker" x1="66" y1="114" x2="68" y2="116" />
                    <line className="tape-marker" x1="78" y1="120" x2="80" y2="120" />
                    <line className="tape-marker" x1="66" y1="126" x2="68" y2="124" />
                    <line className="tape-marker" x1="50" y1="128" x2="50" y2="130" />
                    <line className="tape-marker" x1="34" y1="126" x2="32" y2="124" />
                    <line className="tape-marker" x1="22" y1="120" x2="20" y2="120" />
                    <line className="tape-marker" x1="34" y1="114" x2="32" y2="116" />
                </g>

                {/* Números de Centímetros */}
                <g className="tape-numbers">
                    <text className="tape-number" x="50" y="109">90</text>
                    <text className="tape-number" x="68" y="116">95</text>
                    <text className="tape-number" x="80" y="120">100</text>
                    <text className="tape-number" x="68" y="124">95</text>
                    <text className="tape-number" x="50" y="133">90</text>
                    <text className="tape-number" x="32" y="124">85</text>
                    <text className="tape-number" x="20" y="120">80</text>
                    <text className="tape-number" x="32" y="116">85</text>
                </g>
            </g>
        </svg>
    );
};

export default MeasurementOfHips;
