// src/components/ThemeSwitch.js
import React from 'react';
import { useTheme } from '../ThemeContext';
import sunIcon from '../../ligth.svg';
import moonIcon from '../../dark.svg';
import './ThemeSwitch.css'; // Archivo de estilos

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme();

  // Función para manejar el cambio de tema
  const handleToggleTheme = () => {
    toggleTheme();
  };

  return (
    <div className="theme-switch-container">
      <label className="theme-switch">
        <input
          type="checkbox"
          onChange={handleToggleTheme}
          checked={theme === 'dark'}
        />
        <span className={`slider round ${theme === 'light' ? 'light' : 'dark'}`}>
          <img src={sunIcon} alt="Light Mode" className="icon light" />
          <img src={moonIcon} alt="Dark Mode" className="icon dark" />
        </span>
      </label>
    </div>
  );
};

export default ThemeSwitch;
