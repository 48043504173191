// src/components/WorkoutForm.js

import React, { useState } from 'react';
import { useTheme } from '../../ThemeContext'; // Importamos el contexto del tema

const WorkoutForm = ({ onSubmit, metrics }) => {
  const [workout, setWorkout] = useState('');
  const [minutes, setMinutes] = useState(0);

  const { theme } = useTheme(); // Obtener el tema actual

  const METValues = {
    correr: 10,
    fútbol: 8.5,
    voleibol: 3.5,
    baloncesto: 7,
    natación: 7,
    ciclismo: 8,
    tenis: 7.5,
    senderismo: 6.5,
    baile: 6.5,
    artesMarciales: 8,
    remo: 7,
    boxeo: 9.5,
    caminar: 3,
    empuje_Gym: 5,
    Tirón_Gym: 6,
    pierna_Gym: 6,
    calistenia: 6
  };

  const calculateCalories = (workoutType, minutes, metrics) => {
    const weight = parseFloat(metrics?.Weight) || 70; // Default to 70kg if undefined
    const MET = METValues[workoutType] || 0; // Get the MET value for the selected activity
    const timeInHours = Number(minutes) / 60; // Convertir minutos a horas

    return MET * weight * timeInHours; // Caloric burn based on MET formula
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!workout) return alert('Por favor, selecciona un tipo de ejercicio.');

    const totalMinutes = Number(minutes);
    const caloriesBurned = calculateCalories(workout, totalMinutes, metrics);

    if (caloriesBurned === 0) return alert('Error en el cálculo de calorías.');

    onSubmit({
      workout,
      Duración: `${totalMinutes} minutos`,
      'Calorías Quemadas': `${Math.round(caloriesBurned)} kcal`,
      caloriesBurned: Math.round(caloriesBurned),
      duration: `${totalMinutes} minutos`,
      workoutType: workout
    });

    setWorkout('');
    setMinutes(0);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label
          htmlFor="workout"
          className={`block font-semibold ${
            theme === 'dark' ? 'text-gray-200' : 'text-gray-800'
          }`}
        >
          Tipo de Ejercicio:
        </label>
        <select
          id="workout"
          value={workout}
          onChange={(e) => setWorkout(e.target.value)}
          required
          className={`w-full p-2 mt-1 border ${
            theme === 'dark' ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-800'
          } rounded-md focus:outline-none focus:ring-2 ${
            theme === 'dark'
              ? 'focus:ring-blue-500 focus:border-blue-500 focus:bg-gray-900'
              : 'focus:ring-blue-400 focus:border-blue-400 focus:bg-gray-100'
          }`}
        >
          <option value="">Seleccionar...</option>
          {Object.keys(METValues).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label
          htmlFor="minutes"
          className={`block font-semibold ${
            theme === 'dark' ? 'text-gray-200' : 'text-gray-800'
          }`}
        >
          Minutos:
        </label>
        <input
          type="number"
          id="minutes"
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          min="1"
          required
          className={`w-full p-2 mt-1 border ${
            theme === 'dark' ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-800'
          } rounded-md focus:outline-none focus:ring-2 ${
            theme === 'dark'
              ? 'focus:ring-blue-500 focus:border-blue-500 focus:bg-gray-900'
              : 'focus:ring-blue-400 focus:border-blue-400 focus:bg-gray-100'
          }`}
        />
      </div>

      <button
        type="submit"
        className={`w-full py-2 mt-4 font-semibold text-white rounded-md ${
          theme === 'dark' ? 'bg-blue-600 hover:bg-blue-500' : 'bg-blue-500 hover:bg-blue-400'
        } transition-colors`}
      >
        Guardar
      </button>
    </form>
  );
};

export default WorkoutForm;
