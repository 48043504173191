// src/components/Auth.js
import React from 'react';
import { FaGoogle } from 'react-icons/fa';
import { useTheme } from '../ThemeContext';
import { useAuth } from '../contexts/AuthContext'; // Importa el hook useAuth

const Auth = () => {
  const { theme } = useTheme();
  const { user, loading, login, logout } = useAuth(); // Usa el contexto de autenticación

  if (loading) {
    return (
      <div className="auth-container px-6 mb-6">
        <h2 className="text-center text-lg font-semibold mb-4">Cargando...</h2>
      </div>
    );
  }

  return (
    <div className="auth-container px-6 mb-6">
      {user ? (
        <div className="user-info text-center">
          <h2 className="text-lg font-semibold mb-4">Bienvenido, {user.displayName}</h2>
          {user.photos && user.photos.length > 0 && (
            <img
              src={user.photos[0].value}
              alt="Perfil"
              className="mx-auto rounded-full mb-4"
              style={{ width: '100px', height: '100px' }}
            />
          )}
          <p className="mb-4">{user.emails && user.emails[0].value}</p>
          <button
            onClick={logout}
            className={`flex items-center justify-center space-x-2 
              ${
                theme === 'dark'
                  ? 'bg-red-700 hover:bg-red-600'
                  : 'bg-red-500 hover:bg-red-400'
              }
              border 
              border-red-300 
              rounded-lg 
              px-4 
              py-2 
              shadow-sm 
              transition-colors 
              duration-200
              w-full
              text-white
            `}
            aria-label="Cerrar sesión"
          >
            <span>Cerrar Sesión</span>
          </button>
        </div>
      ) : (
        <div>
          <h2 className="text-center text-lg font-semibold mb-4">Iniciar Sesión</h2>

          {/* Botón de inicio de sesión con Google */}
          <button
            onClick={login} // Usa la función de login del contexto
            className={`flex items-center justify-center space-x-2 
              ${
                theme === 'dark' 
                  ? 'bg-gray-700 hover:bg-gray-600' 
                  : 'bg-white hover:bg-gray-100'
              }
              border 
              border-gray-300 
              rounded-lg 
              px-4 
              py-2 
              shadow-sm 
              transition-colors 
              duration-200
              w-full
            `}
            aria-label="Iniciar sesión con Google"
          >
            <FaGoogle className="w-5 h-5" />
            <span>Iniciar con Google</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Auth;
