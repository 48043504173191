// src/components/Timeline.js

import React, { useRef, useEffect, useLayoutEffect, useState, useMemo } from 'react';
import TarjetaDeTimeline from './TarjetaDeTimeline';
import PlanOverview from './PlanOverview';
import { usePlan } from '../../contexts/PlanContext';
import { useTheme } from '../../ThemeContext';

const Timeline = () => {
  const { events, planData, projections, healthMetrics } = usePlan(); // Agregar healthMetrics
  const { theme } = useTheme();

  const [expandedEvent, setExpandedEvent] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [refsInitialized, setRefsInitialized] = useState(false);
  const eventRefs = useRef([]);

  const todayDate = new Date().toLocaleDateString('en-CA');

  // Ordenar los eventos por fecha de inicio
  const sortedEvents = useMemo(() => {
    return [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
  }, [events]);

  useEffect(() => {
    eventRefs.current = sortedEvents.map(
      (_, i) => eventRefs.current[i] || React.createRef()
    );

    requestAnimationFrame(() => {
      setRefsInitialized(true);
    });
  }, [sortedEvents]);

  useEffect(() => {
    setActiveIndex(0);
  }, [sortedEvents]);

  const handleScrollToEvent = (index) => {
    if (!refsInitialized) return;

    const ref = eventRefs.current[index];
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setActiveIndex(index);
      setExpandedEvent(index);
    } else {
      console.warn(`No se encontró la referencia para el índice ${index}`);
    }
  };

  const handleEventClick = (index) => {
    setExpandedEvent(expandedEvent === index ? null : index);
    setActiveIndex(index);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveIndex(0);
    setExpandedEvent(null);
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let closestIndex = 0;
      let closestDistance = Infinity;

      eventRefs.current.forEach((ref, index) => {
        const element = ref.current;
        if (element) {
          const elementPosition = element.offsetTop + element.offsetHeight / 2;
          const distance = Math.abs(scrollPosition - elementPosition);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestIndex = index;
          }
        }
      });

      if (activeIndex !== closestIndex) {
        setActiveIndex(closestIndex);
      }

      // Efecto de apariencia mejorada
      eventRefs.current.forEach((ref) => {
        const element = ref.current;
        if (element) {
          const rect = element.getBoundingClientRect();
          const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
          if (isVisible) {
            element.style.opacity = '1';
            element.style.transform = 'translateY(0)';
          } else {
            element.style.opacity = '0';
            element.style.transform = 'translateY(20px)';
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sortedEvents, activeIndex]);

  useEffect(() => {
    const scrollButton = document.querySelector('.scroll-to-top-button');

    const handleScrollVisibility = () => {
      if (scrollButton) {
        if (window.scrollY > 200) {
          scrollButton.classList.remove('hidden');
          scrollButton.classList.add('block');
        } else {
          scrollButton.classList.remove('block');
          scrollButton.classList.add('hidden');
        }
      }
    };

    window.addEventListener('scroll', handleScrollVisibility);
    handleScrollVisibility();

    return () => {
      window.removeEventListener('scroll', handleScrollVisibility);
    };
  }, []);

  // Calcular la fecha de inicio del plan
  const planStartDate = useMemo(() => {
    if (sortedEvents.length === 0) return null;
    return new Date(sortedEvents[0].start);
  }, [sortedEvents]);

  // Agrupar eventos por semana del plan
  const eventsByWeek = useMemo(() => {
    if (!planStartDate) return {};

    const eventsGrouped = {};
    sortedEvents.forEach((event, index) => {
      const eventDate = new Date(event.start);
      const diffInTime = eventDate.getTime() - planStartDate.getTime();
      const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
      const weekNumber = Math.floor(diffInDays / 7) + 1;
      const key = `Semana ${weekNumber}`;

      if (!eventsGrouped[key]) {
        eventsGrouped[key] = {
          events: [],
          weekNumber,
        };
      }
      eventsGrouped[key].events.push({ event, globalIndex: index });
    });
    return eventsGrouped;
  }, [sortedEvents, planStartDate]);

  // Obtener métricas para el primer y último día
  const lastDayMetrics = useMemo(
    () => ({
      totalMuscleGain:
        parseFloat(projections[projections.length - 1]?.totalMuscleGain) || 0,
      bmi: parseFloat(projections[projections.length - 1]?.dailyBMI) || 0,
      bodyFatPercentage:
        parseFloat(projections[projections.length - 1]?.dailyBodyFat) || 0,
    }),
    [projections]
  );

  const firstDayMetrics = useMemo(
    () => ({
      totalMuscleGain: parseFloat(projections[0]?.totalMuscleGain) || 0,
      bmi: parseFloat(projections[0]?.dailyBMI) || 0,
      bodyFatPercentage: parseFloat(projections[0]?.dailyBodyFat) || 0,
    }),
    [projections]
  );

  // Calcular la duración del plan en días
  const planDays = useMemo(() => {
    if (sortedEvents.length === 0) return 0;
    const startDate = new Date(sortedEvents[0]?.start);
    const endDate = new Date(sortedEvents[sortedEvents.length - 1]?.start);
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  }, [sortedEvents]);

  return (
    <div
      className={`timeline-container flex flex-col md:flex-row md:space-x-4 p-4 ${
        theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
      }`}
    >
      {/* Barra de navegación para el índice de días */}
      <div className="timeline-nav w-full md:w-1/5 flex flex-col items-start space-y-4">
        <h4 className="timeline-nav-title text-lg font-bold mb-4">
          Índice de Días
        </h4>
        <div className="timeline-nav-weeks w-full">
          {Object.keys(eventsByWeek).map((weekKey) => {
            const { events: weekEvents, weekNumber } = eventsByWeek[weekKey];
            return (
              <div key={weekKey} className="timeline-nav-week mb-4">
                <div className="week-label font-bold mb-2">{`Semana ${weekNumber}`}</div>
                <div className="week-days flex flex-wrap">
                  {weekEvents.map(({ event, globalIndex }) => {
                    const eventDate = new Date(event.start).toLocaleDateString(
                      'en-CA'
                    );
                    const isToday = eventDate === todayDate;

                    return (
                      <div
                        key={event?.id || `event-${globalIndex}`}
                        className={`timeline-nav-point w-8 h-8 flex items-center justify-center border rounded-full text-center cursor-pointer m-1 text-sm
                          ${
                            activeIndex === globalIndex
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-300 text-gray-700'
                          }
                          ${isToday ? 'border-red-500 ring-2 ring-red-500' : ''}`}
                        onClick={() => handleScrollToEvent(globalIndex)}
                      >
                        {globalIndex + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Resumen del plan */}
      <div className="w-full md:w-4/5 flex flex-col space-y-4">
        <div className="text-center text-lg font-semibold">
          El plan tiene una duración de {planDays} días.
        </div>
        {planData && planData.fitnessLevel && healthMetrics && (
          <PlanOverview
            fitnessLevel={planData.fitnessLevel}
            caloricIntake={planData.caloricIntake}
            useCreatine={planData.useCreatine}
            activityFactor={healthMetrics.activityFactor}
            sex={healthMetrics.sex}
            age={healthMetrics.age}
            firstDayMetrics={firstDayMetrics}
            lastDayMetrics={lastDayMetrics}
          />
        )}

        {/* Contenedor de la línea de tiempo */}
        <div className="timeline w-full flex flex-col space-y-4">
          {sortedEvents.map((event, index) => {
            const eventDate = new Date(event.start).toLocaleDateString('en-CA');
            const isToday = eventDate === todayDate;
            return (
              <TarjetaDeTimeline
                key={event?.id || `event-${index}`}
                expandedEvent={expandedEvent}
                index={index}
                onClick={handleEventClick}
                ref={eventRefs.current[index]}
                metrics={projections[index]} // Añadido: pasar metrics
                planData={planData} // Añadido: pasar planData
                className={`transition-all duration-700 ease-out transform opacity-0 translate-y-5 ${
                  isToday ? 'ring-2 ring-red-500 border-red-500' : ''
                }`}
              />
            );
          })}
        </div>
      </div>

      {/* Botón para desplazarse hacia arriba */}
      <button
        className="scroll-to-top-button fixed bottom-5 right-5 p-2 w-12 h-12 rounded-full bg-blue-500 text-white shadow-md 
                   hidden transition-opacity duration-300 hover:bg-blue-600"
        onClick={handleScrollToTop}
      >
        ↑
      </button>
    </div>
  );
};

export default Timeline;
