// src/components/FoodForm.js

import React, { useState } from 'react';
import { useTheme } from '../../ThemeContext'; // Importamos el contexto del tema
import foodDataset from './FoodDataset';  // Importa el dataset de alimentos

const normalizeText = (text) => {
  return text
    .toLowerCase()
    .normalize('NFD')  // Descomponer caracteres con tilde
    .replace(/[\u0300-\u036f]/g, '');  // Eliminar los diacríticos (acentos)
};

/**
 * Componente de formulario para agregar alimentos.
 *
 * @param {function} onSubmit - Función para manejar el envío de datos al componente padre.
 * @returns {JSX.Element}
 */
const FoodForm = ({ onSubmit }) => {
  const [food, setFood] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [totalNutrition, setTotalNutrition] = useState({
    calories: 0, fat: 0, carbohydrates: 0, protein: 0, fiber: 0, sodium: 0
  });

  const { theme } = useTheme(); // Acceder al tema actual desde el contexto

  const handleFoodChange = (e) => {
    setFood(e.target.value);
    handleFilterResults(e.target.value);
  };

  const handleFilterResults = (query) => {
    const normalizedQuery = normalizeText(query);
    const filtered = foodDataset.filter(item =>
      normalizeText(item.name).includes(normalizedQuery)
    );
    setFilteredResults(filtered);
  };

  const handleSelectFood = (selectedItem) => {
    setFood('');  // Resetear campo de búsqueda
    setFilteredResults([]);  // Limpiar resultados de búsqueda

    // Verificar si el alimento ya está seleccionado
    const existingFood = selectedFoods.find(item => item.name === selectedItem.name);
    if (existingFood) {
      handleQuantityChange(selectedFoods.indexOf(existingFood), existingFood.quantity + 1);
    } else {
      setSelectedFoods([...selectedFoods, { ...selectedItem, quantity: 1 }]);
      updateTotalNutrition(selectedItem, 1);  // 1 es la cantidad inicial
    }
  };

  const updateTotalNutrition = (foodItem, quantityDifference) => {
    setTotalNutrition(prevTotal => ({
      calories: prevTotal.calories + (foodItem.calories * quantityDifference),
      fat: prevTotal.fat + (foodItem.fat * quantityDifference),
      carbohydrates: prevTotal.carbohydrates + (foodItem.carbohydrates * quantityDifference),
      protein: prevTotal.protein + (foodItem.protein * quantityDifference),
      fiber: prevTotal.fiber + (foodItem.fiber * quantityDifference),
      sodium: prevTotal.sodium + (foodItem.sodium * quantityDifference)
    }));
  };

  const handleQuantityChange = (index, newQuantity) => {
    if (newQuantity === '') {
      const updatedFoods = selectedFoods.map((item, i) =>
        i === index ? { ...item, quantity: '' } : item
      );
      setSelectedFoods(updatedFoods);
      return;
    }

    const parsedQuantity = parseFloat(newQuantity);

    if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
      const foodToUpdate = selectedFoods[index];
      const oldQuantity = foodToUpdate.quantity;

      const updatedFoods = selectedFoods.map((item, i) =>
        i === index ? { ...item, quantity: parsedQuantity } : item
      );
      setSelectedFoods(updatedFoods);

      const quantityDifference = parsedQuantity - oldQuantity;
      updateTotalNutrition(foodToUpdate, quantityDifference);
    }
  };

  const handleRemoveFood = (index) => {
    const foodToRemove = selectedFoods[index];
    const updatedFoods = selectedFoods.filter((_, i) => i !== index);
    setSelectedFoods(updatedFoods);

    updateTotalNutrition(foodToRemove, -foodToRemove.quantity);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedFoods.length > 0) {
      const foodDetails = selectedFoods.map(item => `${item.name} - ${item.servingSize} x ${item.quantity}`).join(', ');
      
      const nutritionDetails = {
        calories: totalNutrition.calories.toFixed(2),
        fat: totalNutrition.fat.toFixed(2),
        carbohydrates: totalNutrition.carbohydrates.toFixed(2),
        protein: totalNutrition.protein.toFixed(2),
        fiber: totalNutrition.fiber.toFixed(2),
        sodium: totalNutrition.sodium.toFixed(2)
      };
  
      const formData = {
        food: foodDetails,
        nutritionDetails: nutritionDetails // Asegúrate de que esto sea un objeto
      };
  
      onSubmit(formData);
  
      // **Almacenamiento en LocalStorage Mejorado**
      try {
        // Obtener datos existentes
        const data = localStorage.getItem('nutritionData');
        let existingData = [];

        if (data) {
          try {
            existingData = JSON.parse(data);
            if (!Array.isArray(existingData)) {
              console.warn('"nutritionData" no es un array. Reiniciando a un array vacío.');
              existingData = [];
            }
          } catch (parseError) {
            console.error('Error al parsear "nutritionData":', parseError);
            console.warn('Reiniciando "nutritionData" a un array vacío.');
            existingData = [];
          }
        }

        // Preparar el nuevo dato a almacenar
        const newNutritionData = {
          timestamp: new Date().toISOString(),
          ...formData
        };

        // Agregar el nuevo dato al array existente
        const updatedNutritionData = [...existingData, newNutritionData];

        // Guardar de nuevo en LocalStorage
        localStorage.setItem('nutritionData', JSON.stringify(updatedNutritionData));
      } catch (error) {
        console.error('Error al almacenar en localStorage:', error);
        // Opcional: Puedes optar por limpiar la clave si está corrupta
        // localStorage.removeItem('nutritionData');
      }
  
      // **Fin Almacenamiento en LocalStorage Mejorado**
  
      // Resetear el formulario
      setSelectedFoods([]);
      setTotalNutrition({ calories: 0, fat: 0, carbohydrates: 0, protein: 0, fiber: 0, sodium: 0 });
    }
  };

  // Clases condicionales basadas en el tema
  const themeClasses = theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800';
  const inputThemeClasses = theme === 'dark' 
    ? 'bg-gray-700 text-white border-gray-600 placeholder-gray-400 focus:bg-gray-600 focus:text-white'
    : 'bg-white text-gray-700 border-gray-300 placeholder-gray-500 focus:bg-white focus:text-gray-700';
  const resultsThemeClasses = theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-blue-100';

  return (
    <div className={`food-form-container p-4 shadow-lg rounded-lg max-w-lg mx-auto ${themeClasses}`}>
      <form className="compact-form space-y-6" onSubmit={handleFormSubmit}>
        <label className="block text-lg font-semibold">Buscar Alimento:</label>
        <input
          type="text"
          name="food"
          value={food}
          onChange={handleFoodChange}
          className={`input-search block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${inputThemeClasses}`}
          placeholder="Buscar alimento..."
        />

        {filteredResults.length > 0 && (
          <ul className={`food-results-list border rounded-lg mt-2 max-h-40 overflow-y-auto ${theme === 'dark' ? 'border-gray-600' : 'border-gray-200'}`}>
            {filteredResults.map((item, index) => (
              <li key={index} className={`food-result-item cursor-pointer px-3 py-2 ${resultsThemeClasses}`} onClick={() => handleSelectFood(item)}>
                {item.name} - <span className="text-sm">{item.servingSize}</span>
              </li>
            ))}
          </ul>
        )}

        {selectedFoods.length > 0 && (
          <div>
            <h3 className="text-xl font-semibold mb-4">Alimentos seleccionados:</h3>
            <ul className="selected-foods-list space-y-3">
              {selectedFoods.map((item, index) => (
                <li key={index} className={`selected-food-item flex flex-col md:flex-row items-center justify-between p-3 rounded-lg shadow-sm ${themeClasses}`}>
                  <div className="food-info text-sm flex-grow mb-2 md:mb-0">
                    <p className="font-medium">{item.name}</p>
                    <p>Porción: {item.servingSize}</p>
                    <p className="mt-1">Calorías: {(item.calories * item.quantity).toFixed(2)} kcal</p>
                    <p>Grasa: {(item.fat * item.quantity).toFixed(2)} g</p>
                    <p>Carbohidratos: {(item.carbohydrates * item.quantity).toFixed(2)} g</p>
                    <p>Proteína: {(item.protein * item.quantity).toFixed(2)} g</p>
                    <p>Fibra: {(item.fiber * item.quantity).toFixed(2)} g</p>
                    <p>Sodio: {(item.sodium * item.quantity).toFixed(2)} mg</p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="number"
                      value={item.quantity}
                      min="0.1"
                      step="0.1"
                      className={`food-quantity-input w-16 px-2 py-1 border rounded-md focus:outline-none focus:ring focus:ring-blue-300 ${inputThemeClasses}`}
                      onChange={(e) => handleQuantityChange(index, e.target.value)}
                    />
                    <button type="button" className="remove-food-button ml-3 px-3 py-2 text-sm bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300" onClick={() => handleRemoveFood(index)}>Eliminar</button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {selectedFoods.length > 0 && (
          <button type="submit" className="submit-button w-full py-2 mt-4 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-300">Guardar</button>
        )}
      </form>
    </div>
  );
};

export default FoodForm;
