// healthCalculations.js

export const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m =
      today.getMonth() - birthDate.getMonth();
    if (
      m < 0 ||
      (m === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  
  export const calculateBMI = (weight, height) => {
    if (weight && height) {
      const heightInMeters = height / 100;
      return (weight / (heightInMeters * heightInMeters)).toFixed(2);
    }
    return null;
  };
  
  export const calculateBMIPercentile = (age, sex, bmi) => {
    if (!age || age >= 18 || !bmi) return null;
    const percentile = Math.min(Math.max((bmi - 14) * 5, 1), 99).toFixed(1);
    return percentile;
  };
  
  export const calculateBodyFat = (
    sex,
    weight,
    height,
    age,
    neck,
    waist,
    hip
  ) => {
    if (!age) return null;
  
    if (sex === 'male') {
      if (!neck || !waist) return null;
      if (waist - neck <= 0) return null;
    } else if (sex === 'female') {
      if (!neck || !waist || !hip) return null;
      if (waist + hip - neck <= 0) return null;
    } else {
      return null;
    }
  
    let result;
  
    weight = parseFloat(weight);
    height = parseFloat(height);
    neck = parseFloat(neck);
    waist = parseFloat(waist);
    hip = parseFloat(hip);
  
    if (sex === 'male') {
      result =
        86.01 * Math.log10(waist - neck) -
        70.041 * Math.log10(height) +
        36.76;
    } else {
      result =
        163.205 * Math.log10(waist + hip - neck) -
        97.684 * Math.log10(height) -
        78.387;
    }
  
    return result.toFixed(2);
  };
  
  export const calculateTDEE = (
    sex,
    weight,
    height,
    age,
    activityFactor
  ) => {
    if (!age) return { bmr: null, tdee: null };
    let bmr;
    weight = parseFloat(weight);
    height = parseFloat(height);
  
    if (age >= 18) {
      if (sex === 'male') {
        bmr =
          88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      } else {
        bmr =
          447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      }
    } else {
      if (sex === 'male') {
        if (age <= 3) {
          bmr = 59.512 * weight - 30.4;
        } else if (age <= 10) {
          bmr = 22.706 * weight + 504.3;
        } else {
          bmr = 17.686 * weight + 658.2;
        }
      } else {
        if (age <= 3) {
          bmr = 58.317 * weight - 31.1;
        } else if (age <= 10) {
          bmr = 20.315 * weight + 485.9;
        } else {
          bmr = 13.384 * weight + 692.6;
        }
      }
    }
  
    const tdee = (bmr * activityFactor).toFixed(2);
    return { bmr: bmr.toFixed(2), tdee };
  };
  
  export const generateSummary = (data) => {
    let summary = '';
  
    const age = data.age;
    const bmi = parseFloat(data.bmi);
    const weight = parseFloat(data.weight);
    const height = parseFloat(data.height) / 100;
  
    if (age >= 18) {
      if (bmi < 16.0) {
        const targetWeight = 18.5 * height * height;
        const weightToGain = (targetWeight - weight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición severa</span>. Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
      } else if (bmi >= 16.0 && bmi <= 16.9) {
        const targetWeight = 18.5 * height * height;
        const weightToGain = (targetWeight - weight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición moderada</span>. Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
      } else if (bmi >= 17.0 && bmi <= 18.4) {
        const targetWeight = 18.5 * height * height;
        const weightToGain = (targetWeight - weight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición leve</span>. Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
      } else if (bmi >= 18.5 && bmi <= 24.9) {
        summary += 'Tu IMC está en el <span class="text-green-500 font-bold">rango normal</span>. ¡Continúa con tus hábitos saludables! ';
      } else if (bmi >= 25.0 && bmi <= 29.9) {
        const targetWeight = 24.9 * height * height;
        const weightToLose = (weight - targetWeight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">sobrepeso</span>. Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
      } else if (bmi >= 30.0 && bmi <= 34.9) {
        const targetWeight = 24.9 * height * height;
        const weightToLose = (weight - targetWeight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado I</span>. Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
      } else if (bmi >= 35.0 && bmi <= 39.9) {
        const targetWeight = 24.9 * height * height;
        const weightToLose = (weight - targetWeight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado II</span>. Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
      } else if (bmi >= 40.0) {
        const targetWeight = 24.9 * height * height;
        const weightToLose = (weight - targetWeight).toFixed(1);
        summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado III (obesidad mórbida)</span>. Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
      }
    } else {
      const bmiPercentile = parseFloat(data.bmiPercentile);
      if (bmiPercentile < 5) {
        summary += `Tu IMC está por debajo del percentil 5 (${bmiPercentile} percentil), lo que indica <span class="text-red-500 font-bold">bajo peso</span>. Considera estrategias para aumentar de peso de manera saludable. `;
      } else if (bmiPercentile >= 5 && bmiPercentile < 85) {
        summary += `Tu IMC está en un <span class="text-green-500 font-bold">rango saludable</span> (${bmiPercentile} percentil). Sigue manteniendo hábitos saludables. `;
      } else if (bmiPercentile >= 85 && bmiPercentile < 95) {
        summary += `Tu IMC está en el percentil ${bmiPercentile}, indicando <span class="text-red-500 font-bold">sobrepeso</span>. Considera ajustar tu dieta y aumentar la actividad física. `;
      } else {
        summary += `Tu IMC está por encima del percentil 95 (${bmiPercentile} percentil), lo que indica <span class="text-red-500 font-bold">obesidad</span>. Es importante trabajar en un plan para reducir el peso de manera saludable. `;
      }
    }
  
    if (data.bodyFat) {
      const bodyFat = parseFloat(data.bodyFat);
      let idealBodyFatRange = { min: 0, max: 0 };
  
      if (age >= 18) {
        if (data.sex === 'male') {
          idealBodyFatRange = { min: 8, max: 19 };
        } else {
          idealBodyFatRange = { min: 21, max: 33 };
        }
      } else {
        if (data.sex === 'male') {
          idealBodyFatRange = { min: 12, max: 20 };
        } else {
          idealBodyFatRange = { min: 15, max: 24 };
        }
      }
  
      if (bodyFat < idealBodyFatRange.min) {
        const percentToGain = (idealBodyFatRange.min - bodyFat).toFixed(1);
        summary += `Tu porcentaje de grasa corporal es <span class="text-red-500 font-bold">bajo</span>. Necesitas aumentar aproximadamente ${percentToGain}% para alcanzar un nivel saludable. `;
      } else if (bodyFat > idealBodyFatRange.max) {
        const percentToLose = (bodyFat - idealBodyFatRange.max).toFixed(1);
        summary += `Tu porcentaje de grasa corporal es <span class="text-red-500 font-bold">alto</span>. Necesitas reducir aproximadamente ${percentToLose}% para alcanzar un nivel saludable. `;
      } else {
        summary += 'Tu porcentaje de grasa corporal está dentro del <span class="text-green-500 font-bold">rango ideal</span>. ¡Excelente trabajo! ';
      }
    }
  
    if (data.tdee) {
      const tdee = parseFloat(data.tdee);
  
      if (bmi < 18.5 || (age < 18 && data.bmiPercentile < 5)) {
        const surplusCalories = 500;
        summary += `Para ganar peso, considera aumentar tu ingesta calórica en unas ${surplusCalories} calorías diarias, consumiendo alrededor de ${(tdee + surplusCalories).toFixed(0)} calorías al día. `;
      } else if (bmi >= 25 || (age < 18 && data.bmiPercentile >= 85)) {
        const deficitCalories = 500;
        summary += `Para perder peso, considera reducir tu ingesta calórica en unas ${deficitCalories} calorías diarias, consumiendo alrededor de ${(tdee - deficitCalories).toFixed(0)} calorías al día. `;
      } else {
        summary += `Para mantener tu peso actual, consume aproximadamente ${tdee.toFixed(0)} calorías diarias. `;
      }
    }
  
    if (data.activityFactor) {
      if (data.activityFactor <= 1.375) {
        summary += 'Considera aumentar tu nivel de actividad física para mejorar tu salud general. ';
      }
    }
  
    return summary || 'Completa tus métricas para obtener un resumen personalizado.';
  };
  