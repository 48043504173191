// src/Components/Dashboard/CalendarComponent/dataManager.js

/**
 * Generic function to load data from localStorage.
 * @param {string} key - The key under which the data is stored.
 * @returns {any} - The parsed data from localStorage.
 */
export const loadFromLocalStorage = (key) => {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : null;
};

/**
 * Generic function to save data to localStorage.
 * @param {string} key - The key under which to store the data.
 * @param {any} data - The data to be stored.
 */
export const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

/**
 * Remove an item from localStorage.
 * @param {string} key - The key of the item to remove.
 */
export const removeItem = (key) => {
  localStorage.removeItem(key);
};

// Funciones específicas para healthMetrics
export const loadHealthMetrics = () => {
  return loadFromLocalStorage('healthMetrics');
};

export const saveHealthMetrics = (metrics) => {
  saveToLocalStorage('healthMetrics', metrics);
};

export const deleteHealthMetrics = () => {
  removeItem('healthMetrics');
};

// Funciones específicas para events (solo para usuarios no autenticados)
export const loadEvents = () => {
  return loadFromLocalStorage('events') || [];
};

export const saveEvents = (events) => {
  saveToLocalStorage('events', events);
};

// Funciones específicas para planData
export const loadPlanData = () => {
  return loadFromLocalStorage('planData');
};

export const savePlanData = (planData) => {
  saveToLocalStorage('planData', planData);
};

// Funciones específicas para projections
export const loadProjections = () => {
  return loadFromLocalStorage('projections') || [];
};

export const saveProjections = (projections) => {
  saveToLocalStorage('projections', projections);
};

// Funciones para resetear el calendario
export const resetCalendar = (planId) => {
  removeItem('planData');
  removeItem('projections');
  removeItem('events'); // Solo para usuarios no autenticados
};
