// src/components/DayTracking.js

import React, { useState } from 'react';
import HourTracking from './HourTracking'; // Asegúrate de que esta ruta es correcta
import useDayTracking from './useDayTracking'; // Ajusta la ruta si es necesario
import { FaSmile as MoodIcon } from 'react-icons/fa';
import { FaHeartbeat as StateIcon } from 'react-icons/fa';
import { FaDumbbell as WorkoutIcon } from 'react-icons/fa';
import { FaUtensils as FoodIcon } from 'react-icons/fa';
import { useTheme } from '../../ThemeContext'; // Ajusta la ruta si es necesario

const DayTracking = ({ onClose, eventDate, planId, metrics, onDataChange }) => { // Añadir onDataChange
  const { theme } = useTheme();
  const [selectedHour, setSelectedHour] = useState(null);

  // Usa el hook useDayTracking
  const {
    registeredData,
    addEntry,
    deleteEntry,
  } = useDayTracking(planId, eventDate, onDataChange); // Pasar onDataChange al hook

  const iconComponents = {
    mood: MoodIcon,
    state: StateIcon,
    workout: WorkoutIcon,
    food: FoodIcon,
  };

  const hours = Array.from({ length: 24 }, (_, i) => i);

  const getFilteredDataForDate = (hour) => {
    return registeredData[hour] || [];
  };

  const handleSaveData = (hour, data) => {
    addEntry(hour, data);
    setSelectedHour(null);
  };

  const handleDeleteData = (hour, id) => {
    deleteEntry(hour, id);
  };

  const handleBack = () => {
    setSelectedHour(null);
  };

  return (
    <div
      className={`${
        theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
      } border ${
        theme === 'dark' ? 'border-gray-700' : 'border-gray-300'
      } rounded-lg p-4 shadow-lg max-h-screen overflow-y-auto`}
    >
      <button
        onClick={onClose}
        className="bg-red-500 text-white px-2 py-1 rounded-full hover:bg-red-600 transition-transform transform hover:scale-105"
      >
        &times;
      </button>
      {selectedHour === null ? (
        <div className="grid grid-cols-2 gap-y-0.5 gap-x-1 flex-grow">
          {hours.map((hour) => (
            <button
              key={hour}
              className={`${
                theme === 'dark' ? 'bg-gray-700 text-gray-200 hover:bg-gray-600' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
              } text-xs p-1 rounded-md transition-transform transform hover:scale-105`}
              onClick={() => setSelectedHour(hour)}
            >
              {`${hour.toString().padStart(2, '0')}:00`}
              {getFilteredDataForDate(hour).length > 0 && (
                <span className="flex justify-center items-center mt-1 space-x-1">
                  {getFilteredDataForDate(hour).map((entry, index) => {
                    const IconComponent = iconComponents[entry.type];
                    return IconComponent ? (
                      <IconComponent key={index} className="w-3 h-3" />
                    ) : null;
                  })}
                </span>
              )}
            </button>
          ))}
        </div>
      ) : (
        <HourTracking
          hour={selectedHour}
          data={getFilteredDataForDate(selectedHour)}
          onSave={(data) => handleSaveData(selectedHour, data)}
          onDelete={(id) => handleDeleteData(selectedHour, id)}
          onBack={handleBack}
          metrics={metrics}
        />
      )}
    </div>
  );
};

export default DayTracking;
