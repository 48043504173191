// src/components/Dashboard.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from '../ThemeContext';
import ThemeSwitch from './ThemeSwitch';
import CalendarComponent from './CalendarComponent/CalendarComponent';
import Timeline from './Timeline/TimeLine';
import { useAuth } from '../contexts/AuthContext';
import { PlanProvider } from '../contexts/PlanContext';
import  EntrySummary  from './EntrySummary';

// Importar íconos más adecuados
import { FaRegCalendarCheck, FaStream } from 'react-icons/fa';

const Dashboard = () => {
  const { theme, isSidebarVisible, toggleSidebar } = useTheme();
  const { user } = useAuth();
  const [activeComponent, setActiveComponent] = useState('calendar');
  const dashboardRef = useRef(null);

  useEffect(() => {
    const savedComponent = localStorage.getItem('activeComponent');
    if (savedComponent) {
      setActiveComponent(savedComponent);
    }
  }, []);

  const handleComponentChange = (component) => {
    setActiveComponent(component);
    localStorage.setItem('activeComponent', component);
  };

  const handleUserInteraction = useCallback(
    (event) => {
      if (isSidebarVisible) {
        toggleSidebar();
      }
    },
    [isSidebarVisible, toggleSidebar]
  );

  useEffect(() => {
    const dashboardCurrent = dashboardRef.current;

    if (dashboardCurrent) {
      dashboardCurrent.addEventListener('click', handleUserInteraction);
      dashboardCurrent.addEventListener('scroll', handleUserInteraction);
    }

    return () => {
      if (dashboardCurrent) {
        dashboardCurrent.removeEventListener('click', handleUserInteraction);
        dashboardCurrent.removeEventListener('scroll', handleUserInteraction);
      }
    };
  }, [handleUserInteraction]);

  return (
    <div
      ref={dashboardRef}
      className={`flex flex-col flex-grow transition-colors duration-300 font-roboto 
        ${theme === 'dark' ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900'} 
        ${isSidebarVisible ? '' : 'w-full'}`}
    >
      {/* Interruptor de Tema */}
      <div className="flex justify-end p-4">
        <ThemeSwitch />
      </div>

      {/* Mensaje de Bienvenida */}
      <div className="text-center mb-4">
        <h1 className="text-2xl font-semibold">
          Bienvenido{user ? `, ${user.displayName}` : ''}!
        </h1>
        <p className="text-gray-700 dark:text-gray-300">
          {user
            ? 'Explora tus métricas de salud y actividades.'
            : 'Regístrate o inicia sesión para acceder a todas las funcionalidades.'}
        </p>
      </div>

      {/* Botones de Navegación */}
      <div className="flex gap-4 mt-5 md:mt-10 px-4">
        <button
          className={`flex-1 py-2 px-4 rounded-md border transition flex items-center justify-center
            ${
              activeComponent === 'calendar'
                ? 'bg-blue-600 text-white border-blue-600 shadow-md font-semibold'
                : theme === 'dark'
                ? 'bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700'
                : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-200'
            }`}
          onClick={() => handleComponentChange('calendar')}
        >
          <FaRegCalendarCheck className="mr-2" />
          Calendario
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded-md border transition flex items-center justify-center
            ${
              activeComponent === 'timeline'
                ? 'bg-blue-600 text-white border-blue-600 shadow-md font-semibold'
                : theme === 'dark'
                ? 'bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700'
                : 'bg-white text-gray-800 border-gray-300 hover:bg-gray-200'
            }`}
          onClick={() => handleComponentChange('timeline')}
        >
          <FaStream className="mr-2" />
          Línea de Tiempo
        </button>
      </div>

{/* Componente Activo */}
<div className="flex-grow p-4 overflow-y-auto">
  <PlanProvider>
    {activeComponent === 'calendar' ? (
      <>
        <CalendarComponent className="h-full" />
        <EntrySummary />
      </>
    ) : (
      <Timeline className="h-full" />
    )}
  </PlanProvider>
</div>
    </div>
  );
};

export default Dashboard;
