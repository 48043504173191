import React, { useState } from 'react';
import { usePlan } from '../contexts/PlanContext';
import { useTheme } from '../ThemeContext'; 
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { 
  RiBarChartFill, 
  RiRestaurantFill, 
  RiRunFill, 
  RiEmotionHappyLine, 
  RiPulseFill 
} from 'react-icons/ri';

const getUniqueDates = (entries) => {
  const dates = new Set(entries.map(e => e.date));
  return dates.size;
};

const getMacroDistribution = (calorias, proteinas, carbohidratos, grasas) => {
  const totalCals = calorias || 0;
  const pCals = (proteinas || 0) * 4;
  const cCals = (carbohidratos || 0) * 4;
  const gCals = (grasas || 0) * 9;

  const pPercent = totalCals > 0 ? (pCals / totalCals) * 100 : 0;
  const cPercent = totalCals > 0 ? (cCals / totalCals) * 100 : 0;
  const gPercent = totalCals > 0 ? (gCals / totalCals) * 100 : 0;

  return { pPercent, cPercent, gPercent };
};

const EntrySummary = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showFoodDetails, setShowFoodDetails] = useState(false);
  const [showWorkoutDetails, setShowWorkoutDetails] = useState(false);
  const [showMoodDetails, setShowMoodDetails] = useState(false);
  const [showStateDetails, setShowStateDetails] = useState(false);

  const [foodView, setFoodView] = useState('total'); 
  const [workoutView, setWorkoutView] = useState('total');

  const { entries } = usePlan();
  const { theme } = useTheme();

  const totalEntries = entries.length;

  // Agrupar por tipo
  const entriesByType = entries.reduce((acc, entry) => {
    const { type } = entry;
    acc[type] = acc[type] || [];
    acc[type].push(entry);
    return acc;
  }, {});

  // Alimentación
  const foodEntries = entriesByType.food || [];
  const foodTotals = { calorias: 0, proteinas: 0, carbohidratos: 0, grasas: 0, sodio: 0 };
  foodEntries.forEach(entry => {
    const nutrition = entry.data?.nutritionDetails || {};
    foodTotals.calorias += nutrition.calories || 0;
    foodTotals.proteinas += nutrition.protein || 0;
    foodTotals.carbohidratos += nutrition.carbohydrates || 0;
    foodTotals.grasas += nutrition.fat || 0;
    foodTotals.sodio += nutrition.sodium || 0;
  });
  const foodUniqueDays = getUniqueDates(foodEntries);
  const foodDaily = foodUniqueDays > 0 
    ? {
        calorias: (foodTotals.calorias / foodUniqueDays).toFixed(1),
        proteinas: (foodTotals.proteinas / foodUniqueDays).toFixed(1),
        carbohidratos: (foodTotals.carbohidratos / foodUniqueDays).toFixed(1),
        grasas: (foodTotals.grasas / foodUniqueDays).toFixed(1),
        sodio: (foodTotals.sodio / foodUniqueDays).toFixed(1),
      }
    : foodTotals;
  const foodStats = { 
    count: foodEntries.length, 
    totals: foodTotals, 
    daily: foodDaily, 
    uniqueDays: foodUniqueDays 
  };

  // Ejercicio
  const workoutEntries = entriesByType.workout || [];
  let totalDuracion = 0;
  let totalCaloriasQuemadas = 0;
  const workoutTypesCount = {};
  workoutEntries.forEach(entry => {
    const { data } = entry;
    totalDuracion += data.duration || 0;
    totalCaloriasQuemadas += data.caloriesBurned || 0;
    if (data.workoutType) {
      workoutTypesCount[data.workoutType] = (workoutTypesCount[data.workoutType] || 0) + 1;
    }
  });
  const workoutUniqueDays = getUniqueDates(workoutEntries);
  const workoutDaily = workoutUniqueDays > 0 
    ? {
        duracion: (totalDuracion / workoutUniqueDays).toFixed(1),
        caloriasQuemadas: (totalCaloriasQuemadas / workoutUniqueDays).toFixed(1),
      }
    : { duracion: totalDuracion, caloriasQuemadas: totalCaloriasQuemadas };
  const workoutStats = {
    count: workoutEntries.length,
    totalDuracion,
    totalCaloriasQuemadas,
    workoutTypesCount,
    uniqueDays: workoutUniqueDays,
    daily: workoutDaily
  };

  // Estado de Ánimo
  const moodEntries = entriesByType.mood || [];
  const moodCount = {};
  moodEntries.forEach(entry => {
    const titulo = entry.data?.mood || 'Sin título';
    moodCount[titulo] = (moodCount[titulo] || 0) + 1;
  });
  const moodUniqueDays = getUniqueDates(moodEntries);
  const moodStats = { count: moodEntries.length, moodCount, uniqueDays: moodUniqueDays };

  // Estado Físico
  const stateEntries = entriesByType.state || [];
  const stateList = stateEntries.map(entry => entry.data?.state || 'Estado sin título');
  const stateUniqueDays = getUniqueDates(stateEntries);
  const stateStats = { count: stateEntries.length, stateList, uniqueDays: stateUniqueDays };

  const { pPercent, cPercent, gPercent } = getMacroDistribution(
    foodStats.totals?.calorias, 
    foodStats.totals?.proteinas, 
    foodStats.totals?.carbohidratos, 
    foodStats.totals?.grasas
  );

  // Estilos
  const buttonClasses = `
    flex items-center justify-center gap-2 py-2 px-4 rounded-md font-semibold transition-all duration-300
    ${isOpen
      ? (theme === 'dark' ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-700 hover:bg-indigo-700')
      : (theme === 'dark' ? 'bg-indigo-500 hover:bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700')}
    text-white hover:shadow-lg
  `;

  const containerClasses = `
    mt-4 p-4 rounded-md shadow-lg transition-colors duration-300
    ${theme === 'dark' ? 'bg-gray-800 text-gray-200 border-gray-700' : 'bg-white text-gray-800'}
  `;

  const headingBorderColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';
  const textSecondary = theme === 'dark' ? 'text-gray-300' : 'text-gray-700';
  const textTertiary = theme === 'dark' ? 'text-gray-400' : 'text-gray-600';
  const textQuaternary = theme === 'dark' ? 'text-gray-400' : 'text-gray-500';
  const dividerColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';

  const previewCounters = [
    { icon: <RiRestaurantFill className="w-5 h-5" />, count: foodStats.count, label: 'Alimentación' },
    { icon: <RiRunFill className="w-5 h-5" />, count: workoutStats.count, label: 'Ejercicio' },
    { icon: <RiEmotionHappyLine className="w-5 h-5" />, count: moodStats.count, label: 'Ánimo' },
    { icon: <RiPulseFill className="w-5 h-5" />, count: stateStats.count, label: 'Estado Físico' },
  ];

  return (
    <div className="p-4 transition-colors duration-300 ease-in-out relative">
      {/* Barra de vista previa con transición */}
      <div className="flex items-center gap-4 mb-4 relative">
        <button
          className={buttonClasses}
          onClick={() => {
            setIsOpen(prev => !prev);
            // Opcional: reiniciar estados detallados al contraer
            if (isOpen) {
              setShowFoodDetails(false);
              setShowWorkoutDetails(false);
              setShowMoodDetails(false);
              setShowStateDetails(false);
            }
          }}
          aria-expanded={isOpen}
          aria-label={isOpen ? 'Contraer resumen de entradas' : 'Expandir resumen de entradas'}
        >
          {isOpen ? 'Contraer Resumen' : 'Ver Resumen'}
          {isOpen ? <MdExpandLess className="w-5 h-5" /> : <MdExpandMore className="w-5 h-5" />}
        </button>

        {/* Contenedores de íconos con transición */}
        <div className={`
          flex items-center gap-4 transition-all duration-700 ease-in-out 
          ${isOpen ? 'opacity-0 translate-y-[-20px]' : 'opacity-100 translate-y-0'}
        `}>
          {previewCounters.map((item, idx) => (
            <div 
              key={idx} 
              className="flex items-center gap-1 text-sm transition-all duration-700 ease-in-out transform"
            >
              {item.icon}
              <span className="font-semibold">{item.count}</span>
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <div 
          className={`${containerClasses} transform transition-all duration-700 ease-in-out 
          ${isOpen ? 'opacity-100 translate-y-0 delay-200' : 'opacity-0 -translate-y-2'}`}
          style={{ transitionProperty: 'opacity, transform' }}
        >
          <div className={`mb-4 pb-2 border-b ${headingBorderColor}`}>
            <h2 className="text-lg font-semibold flex items-center gap-2">
              <RiBarChartFill className="w-5 h-5" />
              Resumen de tus Registros
            </h2>
            <p className={`mt-1 text-sm ${textSecondary}`}>
              Total de registros: {totalEntries}
            </p>
          </div>

          {totalEntries === 0 ? (
            <p className={`text-sm ${textTertiary}`}>
              Aún no tienes registros. Agrega entradas para ver un análisis detallado.
            </p>
          ) : (
            <div className="space-y-8">
              
              {/* Alimentación */}
              {foodStats.count > 0 && (
                <div className={`
                  transition-all duration-700 ease-in-out 
                  ${showFoodDetails ? 'opacity-100' : 'opacity-100'}
                `}>
                  <div 
                    className="cursor-pointer flex items-center justify-between" 
                    onClick={() => setShowFoodDetails(prev => !prev)}
                  >
                    <h3 className="text-md font-semibold flex items-center gap-2 mb-2">
                      {/* Ícono grande que aparece como continuación del anterior */}
                      <RiRestaurantFill className={`w-5 h-5 transition-transform duration-700 ${showFoodDetails ? 'scale-125 text-red-500' : ''}`} />
                      Alimentación
                    </h3>
                    {showFoodDetails ? <MdExpandLess /> : <MdExpandMore />}
                  </div>
                  {showFoodDetails && (
                    <div className="overflow-hidden transition-all duration-700 max-h-screen opacity-100 pt-2">
                      <p className={`text-sm ${textSecondary}`}>
                        Entradas: {foodStats.count} {foodStats.uniqueDays > 0 && `| Días registrados: ${foodStats.uniqueDays}`}
                      </p>
                      <div className="mt-2 text-sm flex items-center gap-2">
                        <button 
                          onClick={() => setFoodView('total')} 
                          className={`px-2 py-1 rounded ${foodView === 'total' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                          Total
                        </button>
                        <button 
                          onClick={() => setFoodView('daily')} 
                          className={`px-2 py-1 rounded ${foodView === 'daily' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                          Promedio Diario
                        </button>
                      </div>
                      <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                        <div className={`${textTertiary}`}>Calorías:</div>
                        <div>{foodView === 'total' ? foodStats.totals.calorias : foodStats.daily.calorias} kcal</div>

                        <div className={`${textTertiary}`}>Proteínas:</div>
                        <div>{foodView === 'total' ? foodStats.totals.proteinas : foodStats.daily.proteinas} g</div>

                        <div className={`${textTertiary}`}>Carbohidratos:</div>
                        <div>{foodView === 'total' ? foodStats.totals.carbohidratos : foodStats.daily.carbohidratos} g</div>

                        <div className={`${textTertiary}`}>Grasas:</div>
                        <div>{foodView === 'total' ? foodStats.totals.grasas : foodStats.daily.grasas} g</div>

                        <div className={`${textTertiary}`}>Sodio:</div>
                        <div>{foodView === 'total' ? foodStats.totals.sodio : foodStats.daily.sodio} mg</div>
                      </div>

                      {foodStats.totals.calorias > 0 && (
                        <div className="mt-4">
                          <p className={`text-sm ${textTertiary} font-semibold mb-1`}>Distribución de Macronutrientes:</p>
                          <div className="w-full h-4 bg-gray-200 rounded-full relative overflow-hidden">
                            <div 
                              className="h-full bg-red-500"
                              style={{ width: `${pPercent}%` }}
                              title={`Proteínas: ${pPercent.toFixed(1)}%`} 
                            ></div>
                            <div 
                              className="h-full bg-blue-500 absolute left-0"
                              style={{ left: `${pPercent}%`, width: `${cPercent}%` }}
                              title={`Carbohidratos: ${cPercent.toFixed(1)}%`}
                            ></div>
                            <div 
                              className="h-full bg-yellow-500 absolute left-0"
                              style={{ left: `${pPercent + cPercent}%`, width: `${gPercent}%` }}
                              title={`Grasas: ${gPercent.toFixed(1)}%`}
                            ></div>
                          </div>
                          <div className="flex justify-between text-xs mt-1">
                            <span>P: {pPercent.toFixed(0)}%</span>
                            <span>C: {cPercent.toFixed(0)}%</span>
                            <span>G: {gPercent.toFixed(0)}%</span>
                          </div>
                        </div>
                      )}

                      <hr className={`mt-4 ${dividerColor}`} />
                      <p className={`mt-2 text-xs ${textQuaternary}`}>
                        Llevar un registro nutricional ayuda a tener control sobre tu dieta.
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* Ejercicio */}
              {workoutStats.count > 0 && (
                <div className="transition-all duration-700 ease-in-out">
                  <div 
                    className="cursor-pointer flex items-center justify-between" 
                    onClick={() => setShowWorkoutDetails(prev => !prev)}
                  >
                    <h3 className="text-md font-semibold flex items-center gap-2 mb-2">
                      <RiRunFill className={`w-5 h-5 transition-transform duration-700 ${showWorkoutDetails ? 'scale-125 text-green-500' : ''}`} />
                      Ejercicio
                    </h3>
                    {showWorkoutDetails ? <MdExpandLess /> : <MdExpandMore />}
                  </div>
                  {showWorkoutDetails && (
                    <div className="overflow-hidden transition-all duration-700 max-h-screen opacity-100 pt-2">
                      <p className={`text-sm ${textSecondary}`}>
                        Sesiones: {workoutStats.count} {workoutStats.uniqueDays > 0 && `| Días registrados: ${workoutStats.uniqueDays}`}
                      </p>
                      <div className="mt-2 text-sm flex items-center gap-2">
                        <button 
                          onClick={() => setWorkoutView('total')} 
                          className={`px-2 py-1 rounded ${workoutView === 'total' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                          Total
                        </button>
                        <button 
                          onClick={() => setWorkoutView('daily')} 
                          className={`px-2 py-1 rounded ${workoutView === 'daily' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                          Promedio Diario
                        </button>
                      </div>
                      <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                        <div className={`${textTertiary}`}>Duración:</div>
                        <div>{workoutView === 'total' ? workoutStats.totalDuracion : workoutStats.daily.duracion} min</div>

                        <div className={`${textTertiary}`}>Calorías quemadas:</div>
                        <div>{workoutView === 'total' ? workoutStats.totalCaloriasQuemadas : workoutStats.daily.caloriasQuemadas} kcal</div>
                      </div>

                      {Object.keys(workoutStats.workoutTypesCount).length > 0 && (
                        <div className="mt-4">
                          <div className={`mb-1 text-sm ${textTertiary} font-semibold`}>
                            Frecuencia por tipo de ejercicio:
                          </div>
                          <ul className="text-sm ml-4 list-disc space-y-1">
                            {Object.entries(workoutStats.workoutTypesCount).map(([type, cnt]) => (
                              <li key={type}>{type}: {cnt} veces</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <hr className={`mt-4 ${dividerColor}`} />
                      <p className={`mt-2 text-xs ${textQuaternary}`}>
                        Mantén una rutina constante para mejorar tu estado físico.
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* Estado de Ánimo */}
              {moodStats.count > 0 && (
                <div className="transition-all duration-700 ease-in-out">
                  <div 
                    className="cursor-pointer flex items-center justify-between"
                    onClick={() => setShowMoodDetails(prev => !prev)}
                  >
                    <h3 className="text-md font-semibold flex items-center gap-2 mb-2">
                      <RiEmotionHappyLine className={`w-5 h-5 transition-transform duration-700 ${showMoodDetails ? 'scale-125 text-yellow-500' : ''}`} />
                      Estado de Ánimo
                    </h3>
                    {showMoodDetails ? <MdExpandLess /> : <MdExpandMore />}
                  </div>
                  {showMoodDetails && (
                    <div className="overflow-hidden transition-all duration-700 max-h-screen opacity-100 pt-2">
                      <p className={`text-sm ${textSecondary}`}>
                        Entradas: {moodStats.count} {moodStats.uniqueDays > 0 && `| Días registrados: ${moodStats.uniqueDays}`}
                      </p>
                      <div className="mt-2">
                        <div className={`mb-1 text-sm ${textTertiary} font-semibold`}>
                          Frecuencia por estado:
                        </div>
                        <ul className="text-sm ml-4 list-disc space-y-1">
                          {Object.entries(moodStats.moodCount).map(([moodTitle, count]) => (
                            <li key={moodTitle}>{moodTitle}: {count}</li>
                          ))}
                        </ul>
                      </div>
                      <hr className={`mt-4 ${dividerColor}`} />
                      <p className={`mt-2 text-xs ${textQuaternary}`}>
                        Analizar tu estado de ánimo puede mejorar tu bienestar emocional.
                      </p>
                    </div>
                  )}
                </div>
              )}

              {/* Estado Físico */}
              {stateStats.count > 0 && (
                <div className="transition-all duration-700 ease-in-out">
                  <div 
                    className="cursor-pointer flex items-center justify-between"
                    onClick={() => setShowStateDetails(prev => !prev)}
                  >
                    <h3 className="text-md font-semibold flex items-center gap-2 mb-2">
                      <RiPulseFill className={`w-5 h-5 transition-transform duration-700 ${showStateDetails ? 'scale-125 text-pink-500' : ''}`} />
                      Estado Físico
                    </h3>
                    {showStateDetails ? <MdExpandLess /> : <MdExpandMore />}
                  </div>
                  {showStateDetails && (
                    <div className="overflow-hidden transition-all duration-700 max-h-screen opacity-100 pt-2">
                      <p className={`text-sm ${textSecondary}`}>
                        Entradas: {stateStats.count} {stateStats.uniqueDays > 0 && `| Días registrados: ${stateStats.uniqueDays}`}
                      </p>
                      {stateStats.stateList.length > 0 && (
                        <div className="mt-2">
                          <div className={`mb-1 text-sm ${textTertiary} font-semibold`}>
                            Registros:
                          </div>
                          <ul className="text-sm ml-4 list-disc space-y-1">
                            {stateStats.stateList.map((st, idx) => (
                              <li key={idx}>{st}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <hr className={`mt-4 ${dividerColor}`} />
                      <p className={`mt-2 text-xs ${textQuaternary}`}>
                        Monitorear tus síntomas y mediciones contribuye a tu salud a largo plazo.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EntrySummary;
