import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * CircularProgressBar Component
 *
 * Displays a circular progress bar with a sophisticated gradient.
 * It handles values within and beyond the maximum limit,
 * providing visual cues through color changes and over-limit indications.
 */
const CircularProgressBar = ({
  maxValue = 1800,
  currentValue = 0,
  size = 200,
  strokeWidth = 20, // Increased stroke width
  colors = {
    start: '#4caf50',   // Green
    mid1: '#aeea00',    // Lime
    mid2: '#ffeb3b',    // Yellow
    end: '#f44336',     // Red
    over: '#d32f2f',    // Dark Red
  },
}) => {
  const [progress, setProgress] = useState(0);
  const [overAmount, setOverAmount] = useState(0);

  const normalizedSize = size;
  const radius = (normalizedSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Calculate percentage and over amount
  useEffect(() => {
    const percentage = Math.min((currentValue / maxValue) * 100, 100);
    setProgress(percentage);
    if (currentValue > maxValue) {
      setOverAmount(Math.round((currentValue - maxValue) * 100) / 100);
    } else {
      setOverAmount(0);
    }
  }, [currentValue, maxValue]);

  // Determine stroke color based on progress
  const getStrokeColor = () => {
    if (currentValue <= maxValue) {
      return `url(#gradient)`;
    } else {
      return colors.over;
    }
  };

  // Round maxValue to 2 decimal places if necessary
  const roundedMaxValue = Math.round(maxValue * 100) / 100;

  return (
    <div
      className="relative flex items-center justify-center"
      style={{ width: size, height: size }}
      aria-valuenow={currentValue}
      aria-valuemin={0}
      aria-valuemax={maxValue}
      role="progressbar"
    >
      <svg
        width={normalizedSize}
        height={normalizedSize}
        viewBox={`0 0 ${normalizedSize} ${normalizedSize}`} // Added viewBox
      >
        <defs>
          <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="0%">
            <stop offset="0%" stopColor={colors.start} />
            <stop offset="50%" stopColor={colors.mid1} />
            <stop offset="75%" stopColor={colors.mid2} />
            <stop offset="100%" stopColor={colors.end} />
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="4" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        {/* Background Circle */}
        <circle
          fill="rgba(0, 0, 0, 0.1)" // Increased opacity for better contrast
          stroke="#e0e0e0" // Explicit light gray color
          strokeWidth={strokeWidth}
          r={radius}
          cx={normalizedSize / 2}
          cy={normalizedSize / 2}
        />
        {/* Progress Circle */}
        <circle
          stroke={getStrokeColor()}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          r={radius}
          cx={normalizedSize / 2}
          cy={normalizedSize / 2}
          className="transition-all duration-1000 ease-out transform rotate-[-90deg] origin-center"
          filter="url(#glow)" // Apply glow effect
        />
        {/* Over Limit Circle */}
        {overAmount > 0 && (
          <circle
            stroke={colors.over}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - (progress / 100) * circumference}
            r={radius}
            cx={normalizedSize / 2}
            cy={normalizedSize / 2}
            className="transition-all duration-1000 ease-out transform rotate-[-90deg] origin-center"
            filter="url(#glow)" // Apply glow effect
          />
        )}
      </svg>
      {/* Center Text */}
      <div
        className="absolute flex flex-col items-center justify-center text-center bg-white bg-opacity-70 rounded-full p-2"
        style={{
          width: size * 0.8,
          height: size * 0.8,
          borderRadius: '50%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden', // Ensure content doesn't overflow
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Línea 1: Ingesta Actual */}
        <div
          className="text-lg font-bold text-gray-800"
          style={{
            fontSize: size * 0.12,
            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            whiteSpace: 'nowrap', // Prevent wrapping
          }}
        >
          {`${currentValue} kcal`}
        </div>
        {/* Línea 2: Ingesta Máxima */}
        <div
          className="text-md text-gray-700"
          style={{
            fontSize: size * 0.10,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            whiteSpace: 'nowrap', // Prevent wrapping
          }}
        >
          {`de ${roundedMaxValue} kcal`}
        </div>
        {/* Línea 3: Porcentaje */}
        <div
          className="text-md text-gray-700"
          style={{
            fontSize: size * 0.10,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            whiteSpace: 'nowrap', // Prevent wrapping
          }}
        >
          {`${Math.round((currentValue / maxValue) * 100)}%`}
        </div>
        {/* Línea 4: Over Amount (si aplica) */}
        {overAmount > 0 && (
          <div
            className="mt-1 text-red-600 font-semibold"
            style={{
              fontSize: size * 0.08,
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
              whiteSpace: 'nowrap', // Prevent wrapping
            }}
          >
            +{overAmount} kcal
          </div>
        )}
      </div>
    </div>
  );
};

// Prop Types for validation
CircularProgressBar.propTypes = {
  maxValue: PropTypes.number,
  currentValue: PropTypes.number.isRequired,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  colors: PropTypes.shape({
    start: PropTypes.string,
    mid1: PropTypes.string,
    mid2: PropTypes.string,
    end: PropTypes.string,
    over: PropTypes.string,
  }),
};

export default CircularProgressBar;
