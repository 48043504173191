// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';

// Crear el contexto de autenticación
const AuthContext = createContext();

// Hook personalizado para usar el AuthContext
export const useAuth = () => useContext(AuthContext);

// URL base para los endpoints de autenticación
const BASE_URL = 'https://api.carendary.com'; // Asegúrate de que este es el URL correcto de tu backend

// Componente AuthProvider
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Almacena la información del usuario
  const [loading, setLoading] = useState(true); // Indica si los datos del usuario están cargando
  const [error, setError] = useState(null); // Almacena errores

  // Función para iniciar sesión redirigiendo al backend
  const login = () => {
    console.log('Iniciando sesión redirigiendo a /auth/google');
    window.location.href = `${BASE_URL}/auth/google`;
  };

  // Función para cerrar sesión redirigiendo al backend
  const logout = () => {
    console.log('Cerrando sesión redirigiendo a /logout');
    window.location.href = `${BASE_URL}/logout`;
  };

  // Función para obtener la información del usuario
  const fetchUser = async () => {
    try {
      console.log('Obteniendo información del usuario desde /api/user');
      const response = await fetch(`${BASE_URL}/api/user`, {
        method: 'GET',
        credentials: 'include', // Incluye las cookies en la solicitud
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Respuesta de /api/user:', response.status);
      const responseText = await response.text();
      console.log('Contenido de la respuesta:', responseText);

      if (response.status === 200) {
        const data = JSON.parse(responseText);
        console.log('Datos del usuario:', data.user);
        setUser(data.user);
      } else {
        console.log('No autenticado, estado:', response.status);
        setUser(null);
      }
    } catch (error) {
      console.error('Error al obtener el usuario:', error);
      setError(error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  // Efecto para obtener la información del usuario cuando el componente se monta
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, error, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
