// HealthMetricsForm.js

import React from 'react';
import { useTheme } from '../../ThemeContext';
import useHealthMetrics from './useHealthMetrics';
import MeasurementOfNeck from './MeasurementOfNeck';
import MeasurementOfWaist from './MeasurementOfWaist';
import MeasurementOfHips from './MeasurementOfHips';

const HealthMetricsForm = () => {
  const {
    formData,
    handleSubmit,
    handleDelete,
    handleInputChange,
    errorMessages,
    isSaved,
    isPlanActive,
    showAlert,
    activityLevels,
    handleActivityFactorChange,
    generateSummary,
    resultsRef,
    visibleSVG,
    toggleSVG,
    svgContainerRef,
  } = useHealthMetrics();

  const { theme } = useTheme();

  return (
    <div
      className={`w-full max-w-md mx-auto p-6 rounded-lg shadow-md ${
        theme === 'light'
          ? 'bg-white text-gray-700'
          : 'bg-gray-800 text-gray-300'
      } transition-colors duration-300`}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-col">
          <label htmlFor="dob" className="mb-1 font-semibold text-sm">
            Fecha de Nacimiento:
          </label>
          <input
            type="date"
            name="dob"
            id="dob"
            value={formData.dob}
            onChange={handleInputChange}
            required
            disabled={isPlanActive} // This will now work correctly
            className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
              theme === 'light'
                ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="sex" className="mb-1 font-semibold text-sm">
            Sexo:
          </label>
          <select
            name="sex"
            id="sex"
            value={formData.sex}
            onChange={handleInputChange}
            required
            disabled={isPlanActive}
            className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
              theme === 'light'
                ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                : 'bg-gray-900 border-gray-600 focus:ring-green-200 text-gray-100 focus:bg-gray-700'
            }`}
          >
            <option value="male">Hombre</option>
            <option value="female">Mujer</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="weight" className="mb-1 font-semibold text-sm">
            Peso (kg):
          </label>
          <input
            type="number"
            name="weight"
            id="weight"
            value={formData.weight}
            onChange={handleInputChange}
            required
            disabled={isPlanActive}
            min="0"
            step="0.1"
            className={`p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
              theme === 'light'
                ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="height" className="mb-1 font-semibold text-sm">
            Estatura (cm):
          </label>
          <input
            type="number"
            name="height"
            id="height"
            value={formData.height}
            onChange={handleInputChange}
            required
            disabled={isPlanActive}
            min="0"
            step="0.1"
            className={`p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
              theme === 'light'
                ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
            }`}
          />
        </div>

        {/* Factor de Actividad */}
        <div className="flex flex-col">
          <label htmlFor="activityFactor" className="mb-1 font-semibold text-sm">
            Factor de Actividad Física:
          </label>
          <input
            type="range"
            name="activityFactor"
            id="activityFactor"
            min="1.2"
            max="1.9"
            step="0.175"
            value={formData.activityFactor}
            onChange={handleActivityFactorChange}
            required
            className="w-full"
            disabled={isPlanActive}
          />
          <span className="mt-1 text-sm">
            {
              activityLevels.find(
                (level) =>
                  level.value === formData.activityFactor
              )?.label || 'Selecciona un nivel de actividad'
            }
          </span>
        </div>

        {/* Sección de Inputs Opcionales */}
        <div
          className={`p-4 rounded-md ${
            theme === 'light' ? 'bg-gray-50' : 'bg-gray-700'
          }`}
        >
          <h3 className="text-md font-semibold mb-1">
            Calcular Porcentaje de Grasa
          </h3>
          <p className="text-xs mb-4">
            El porcentaje de grasa corporal puede ser útil para mejorar las medidas de proyección de tu cuerpo.
          </p>

          {/* Cuello */}
          <div className="flex flex-col relative">
            <label htmlFor="neck" className="mb-1 font-semibold text-sm">
              Perímetro del Cuello (cm):{' '}
              <span className="text-gray-500">(Opcional)</span>
            </label>
            <div className="relative">
              <input
                type="number"
                name="neck"
                id="neck"
                value={formData.neck}
                onChange={handleInputChange}
                min="0"
                step="0.1"
                disabled={isPlanActive}
                className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                  theme === 'light'
                    ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                    : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                }`}
              />
              <button
                type="button"
                onClick={() => toggleSVG('neck')}
                className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                aria-label="Cómo medir el perímetro del cuello"
              >
                ?
              </button>
            </div>
            {visibleSVG === 'neck' && (
              <div
                className="mt-2"
                ref={svgContainerRef}
                tabIndex="-1"
              >
                <MeasurementOfNeck />
              </div>
            )}
          </div>

          {/* Cintura */}
          <div className="flex flex-col relative mt-4">
            <label htmlFor="waist" className="mb-1 font-semibold text-sm">
              Perímetro de la Cintura (cm):{' '}
              <span className="text-gray-500">(Opcional)</span>
            </label>
            <div className="relative">
              <input
                type="number"
                name="waist"
                id="waist"
                value={formData.waist}
                onChange={handleInputChange}
                min="0"
                step="0.1"
                disabled={isPlanActive}
                className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                  theme === 'light'
                    ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                    : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                }`}
              />
              <button
                type="button"
                onClick={() => toggleSVG('waist')}
                className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                aria-label="Cómo medir el perímetro de la cintura"
              >
                ?
              </button>
            </div>
            {visibleSVG === 'waist' && (
              <div
                className="mt-2"
                ref={svgContainerRef}
                tabIndex="-1"
              >
                <MeasurementOfWaist />
              </div>
            )}
          </div>

          {/* Cadera (Solo para mujeres) */}
          {formData.sex === 'female' && (
            <div className="flex flex-col relative mt-4">
              <label htmlFor="hip" className="mb-1 font-semibold text-sm">
                Perímetro de la Cadera (cm):{' '}
                <span className="text-gray-500">(Opcional)</span>
              </label>
              <div className="relative">
                <input
                  type="number"
                  name="hip"
                  id="hip"
                  value={formData.hip}
                  onChange={handleInputChange}
                  min="0"
                  step="0.1"
                  disabled={isPlanActive}
                  className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                  }`}
                />
                <button
                  type="button"
                  onClick={() => toggleSVG('hip')}
                  className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                  aria-label="Cómo medir el perímetro de la cadera"
                >
                  ?
                </button>
              </div>
              {visibleSVG === 'hip' && (
                <div
                  className="mt-2"
                  ref={svgContainerRef}
                  tabIndex="-1"
                >
                  <MeasurementOfHips />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Mensajes de Error */}
        {errorMessages.length > 0 && (
          <div className="mt-4 p-2 bg-red-200 text-red-700 rounded-md">
            {errorMessages.map((error, index) => (
              <p key={index} className="text-sm">
                {error}
              </p>
            ))}
          </div>
        )}

        {/* Botones */}
        <div className="flex space-x-2 justify-center mt-6">
          <button
            type="submit"
            disabled={isSaved}
            className={`px-4 py-2 rounded-md font-semibold focus:outline-none focus:ring-2 transition-all duration-300 ${
              isSaved
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed opacity-50'
                : theme === 'light'
                ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-300'
                : 'bg-blue-700 text-white hover:bg-blue-800 focus:ring-green-400'
            }`}
          >
            Guardar
          </button>
          <button
            type="button"
            onClick={handleDelete}
            disabled={isPlanActive}
            className={`px-4 py-2 rounded-md font-semibold focus:outline-none focus:ring-2 transition-all duration-300 ${
              isPlanActive
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed opacity-50'
                : theme === 'light'
                ? 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-300'
                : 'bg-red-700 text-white hover:bg-red-800 focus:ring-red-400'
            }`}
          >
            Eliminar
          </button>
        </div>

        {/* Alerta de Guardado */}
        {showAlert && (
          <div className="mt-4 p-2 bg-green-200 text-green-700 text-center rounded-md animate-fade-in">
            Datos actualizados.
          </div>
        )}
      </form>

      {/* Resultados */}
      <div ref={resultsRef} className="mt-8 text-center">
        <h2 className="text-lg font-semibold">Tus Métricas</h2>
        {formData.bmi && (
          <p className="mt-2">
            <span className="font-medium">IMC:</span>{' '}
            <span className="text-blue-500">{formData.bmi}</span>
          </p>
        )}
        {formData.age < 18 && formData.bmiPercentile && (
          <p className="mt-2">
            <span className="font-medium">Percentil de IMC:</span>{' '}
            <span className="text-blue-500">{formData.bmiPercentile}</span>
          </p>
        )}
        {formData.bodyFat && (
          <p className="mt-2">
            <span className="font-medium">% de Grasa Corporal:</span>{' '}
            <span className="text-blue-500">{formData.bodyFat}</span>
          </p>
        )}
        {formData.tdee && (
          <p className="mt-2">
            <span className="font-medium">Gasto Calórico Total Diario (TDEE):</span>{' '}
            <span className="text-blue-500">{formData.tdee}</span> calorías
          </p>
        )}
        {formData.bmr && (
          <p className="mt-2">
            <span className="font-medium">Tasa Metabólica Basal (BMR):</span>{' '}
            <span className="text-blue-500">{formData.bmr}</span> calorías
          </p>
        )}
      </div>

      {/* Resumen del Estado Físico */}
      {formData.bmi && (
        <div className="mt-8 text-left">
          <h2 className="text-lg font-semibold">Resumen de tu Estado Físico</h2>
          <p
            className="mt-2"
            dangerouslySetInnerHTML={{ __html: generateSummary(formData) }}
          ></p>
        </div>
      )}
    </div>
  );
};

export default HealthMetricsForm;
