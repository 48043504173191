import React from 'react';

const MeasurementOfWaist = () => {
    const styles = `
        /* Animaciones */
        @keyframes moveLeftArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(-10deg); }
        }

        @keyframes moveRightArm {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(10deg); }
        }

        @keyframes adjustTape {
            0%, 100% { rx: 22; ry: 7; }
            50% { rx: 18; ry: 5; }
        }

        /* Aplicar animaciones a los grupos de brazos */
        #left-arm-group {
            transform-origin: 40px 80px; /* Origen en el hombro izquierdo */
            animation: moveLeftArm 2s infinite ease-in-out;
        }

        #right-arm-group {
            transform-origin: 60px 80px; /* Origen en el hombro derecho */
            animation: moveRightArm 2s infinite ease-in-out;
        }

        /* Animación de la cinta métrica */
        #tape-ellipse {
            animation: adjustTape 2s infinite ease-in-out;
        }

        /* Estilos de los marcadores */
        .tape-marker {
            stroke: #fff;
            stroke-width: 0.5;
        }

        /* Estilos de los números */
        .tape-number {
            font-size: 3px;
            fill: #fff;
            text-anchor: middle;
            dominant-baseline: middle;
            font-family: Arial, sans-serif;
        }

        /* Dedos */
        .finger {
            stroke: #000;
            stroke-width: 0.3;
        }

        /* Texturas y Gradientes */
        .hair {
            fill: url(#hairGradient);
        }

        .shirt {
            fill: url(#shirtTexture);
        }

        /* Detalles adicionales */
        .outline {
            stroke: #000;
            stroke-width: 0.5;
            fill: none;
        }
    `;

    return (
        <svg width="100" height="160" viewBox="0 0 100 160" xmlns="http://www.w3.org/2000/svg">
            <style>{styles}</style>
            {/* Definición de texturas y gradientes */}
            <defs>
                {/* Gradiente para el cabello */}
                <linearGradient id="hairGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: "#4A2C2A", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#7B3F00", stopOpacity: 1 }} />
                </linearGradient>

                {/* Textura para la camiseta */}
                <pattern id="shirtTexture" patternUnits="userSpaceOnUse" width="4" height="4">
                    <circle cx="2" cy="2" r="0.5" fill="#ffffff" opacity="0.2" />
                </pattern>

                {/* Gradiente para la piel */}
                <linearGradient id="skinGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style={{ stopColor: "#ffdbac", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#e0ac69", stopOpacity: 1 }} />
                </linearGradient>

                {/* Gradiente para la cinta métrica */}
                <linearGradient id="tapeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: "#d32f2f", stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: "#f44336", stopOpacity: 1 }} />
                </linearGradient>
            </defs>

            {/* Cuerpo de la Persona */}
            <g id="person">
                {/* Cabeza */}
                <circle cx="50" cy="40" r="15" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />

                {/* Cabello */}
                <path
                    className="hair"
                    d="
                        M35,35 
                        Q50,15 65,35 
                        L65,30 
                        Q50,10 35,30 
                        Z"
                    stroke="#000"
                    strokeWidth="0.5"
                    fill="url(#hairGradient)"
                />

                {/* Detalles Faciales */}
                {/* Ojos */}
                <ellipse cx="44" cy="42" rx="2" ry="3" fill="#fff" stroke="#000" strokeWidth="0.3" />
                <circle cx="44" cy="42" r="1" fill="#000" />
                <ellipse cx="56" cy="42" rx="2" ry="3" fill="#fff" stroke="#000" strokeWidth="0.3" />
                <circle cx="56" cy="42" r="1" fill="#000" />
                {/* Cejas */}
                <path d="M42,39 Q44,37 46,39" stroke="#000" strokeWidth="0.3" fill="none" />
                <path d="M54,39 Q56,37 58,39" stroke="#000" strokeWidth="0.3" fill="none" />
                {/* Boca */}
                <path d="M44,48 Q50,52 56,48" stroke="#000" strokeWidth="0.3" fill="none" />

                {/* Cuello */}
                <rect x="47" y="55" width="6" height="8" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />

                {/* Cuerpo con textura */}
                <rect
                    className="shirt"
                    x="35"
                    y="60"
                    width="30"
                    height="50"
                    stroke="#000"
                    strokeWidth="0.5"
                    rx="5"
                />

                {/* Hombros */}
                <line x1="35" y1="60" x2="25" y2="70" stroke="#000" strokeWidth="0.5" />
                <line x1="65" y1="60" x2="75" y2="70" stroke="#000" strokeWidth="0.5" />

                {/* Brazos */}
                {/* Brazo Izquierdo (animado) */}
                <g id="left-arm-group">
                    {/* Brazo Superior */}
                    <path
                        d="M25,70 Q20,90 30,100"
                        fill="url(#skinGradient)"
                        stroke="#000"
                        strokeWidth="0.5"
                    />
                    {/* Mano Izquierda */}
                    <circle cx="30" cy="100" r="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />
                    {/* Dedos Izquierdos */}
                    <line className="finger" x1="28.5" y1="98.5" x2="27" y2="97" />
                    <line className="finger" x1="31.5" y1="98.5" x2="33" y2="97" />
                </g>

                {/* Brazo Derecho (animado) */}
                <g id="right-arm-group">
                    {/* Brazo Superior */}
                    <path
                        d="M75,70 Q80,90 70,100"
                        fill="url(#skinGradient)"
                        stroke="#000"
                        strokeWidth="0.5"
                    />
                    {/* Mano Derecha */}
                    <circle cx="70" cy="100" r="3" fill="url(#skinGradient)" stroke="#000" strokeWidth="0.5" />
                    {/* Dedos Derechos */}
                    <line className="finger" x1="68.5" y1="98.5" x2="67" y2="97" />
                    <line className="finger" x1="71.5" y1="98.5" x2="73" y2="97" />
                </g>

                {/* Piernas */}
                <rect x="40" y="110" width="8" height="30" fill="#555" stroke="#000" strokeWidth="0.5" />
                <rect x="52" y="110" width="8" height="30" fill="#555" stroke="#000" strokeWidth="0.5" />
            </g>

            {/* Cinta Métrica alrededor de la Cintura */}
            <g id="tape-measure">
                {/* Cinta Métrica (elipse con animación) */}
                <ellipse
                    id="tape-ellipse"
                    cx="50"
                    cy="85"
                    rx="22"
                    ry="7"
                    stroke="url(#tapeGradient)"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                />

                {/* Marcadores de la cinta métrica */}
                <g className="tape-markers">
                    {/* Marcadores a lo largo de la elipse */}
                    <line className="tape-marker" x1="50" y1="78" x2="50" y2="80" />
                    <line className="tape-marker" x1="63" y1="80" x2="65" y2="82" />
                    <line className="tape-marker" x1="72" y1="85" x2="74" y2="85" />
                    <line className="tape-marker" x1="63" y1="90" x2="65" y2="88" />
                    <line className="tape-marker" x1="50" y1="92" x2="50" y2="94" />
                    <line className="tape-marker" x1="37" y1="90" x2="35" y2="88" />
                    <line className="tape-marker" x1="28" y1="85" x2="26" y2="85" />
                    <line className="tape-marker" x1="37" y1="80" x2="35" y2="82" />
                </g>

                {/* Números de Centímetros */}
                <g className="tape-numbers">
                    <text className="tape-number" x="50" y="75">80</text>
                    <text className="tape-number" x="65" y="82">85</text>
                    <text className="tape-number" x="74" y="85">90</text>
                    <text className="tape-number" x="65" y="88">85</text>
                    <text className="tape-number" x="50" y="95">80</text>
                    <text className="tape-number" x="35" y="88">75</text>
                    <text className="tape-number" x="26" y="85">70</text>
                    <text className="tape-number" x="35" y="82">75</text>
                </g>
            </g>
        </svg>
    );
};

export default MeasurementOfWaist;
