import React, { createContext, useState, useContext, useEffect } from 'react';

// Crear el contexto del tema y sidebar
const ThemeContext = createContext();

// Hook para usar el contexto del tema
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  // Estado para el tema
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    } else {
      const currentHour = new Date().getHours();
      return (currentHour >= 18 || currentHour < 6) ? 'dark' : 'light';
    }
  };

  const [theme, setTheme] = useState(getInitialTheme);

  // Estado para la visibilidad de la sidebar
  const getInitialSidebarVisibility = () => {
    const savedVisibility = localStorage.getItem('isSidebarVisible');
    return savedVisibility ? JSON.parse(savedVisibility) : true; // True por defecto
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(getInitialSidebarVisibility);

  // Función para alternar el tema
  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return newTheme;
    });
  };

  // Función para alternar la visibilidad de la sidebar
  const toggleSidebar = () => {
    setIsSidebarVisible((prevVisible) => {
      const newVisibility = !prevVisible;
      localStorage.setItem('isSidebarVisible', JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  // Aplicar la clase para el tema
  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isSidebarVisible, toggleSidebar }}>
      {children}
    </ThemeContext.Provider>
  );
};
