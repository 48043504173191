// ChatWindow.js
import React, { useRef, useEffect, useState } from 'react';
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineSend,
  AiOutlineArrowDown,
} from 'react-icons/ai';
import { motion, AnimatePresence } from 'framer-motion';
import ChatHistory from './ChatHistory';
import { FaSpinner } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CanvasComponent from './CanvasComponent';

const ChatWindow = ({
  handlers,
  theme,
  toggleChat,
  isSidebarOpen,
  handleToggleSidebar,
  sidebarRef,
  toggleButtonRef,
  chats,
  currentChatId,
  handleSelectChat,
  handleNewChat,
  handleRenameChat,
  handleDeleteChat,
  currentChat,
  input,
  setInput,
  handleSend,
  isLoading,
}) => {
  const chatVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  const sidebarVariants = {
    hidden: { x: -300 },
    visible: { x: 0 },
    exit: { x: -300 },
  };

  const preprocessMessage = (text) => {
    return text.replace(/\*\*(.+?)\*\*/g, '## $1');
  };

  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    if (isAtBottom && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentChat ? currentChat.messages : null, isAtBottom]);

  useEffect(() => {
    const messageContainer = messageContainerRef.current;

    const handleScroll = () => {
      if (!messageContainer) return;

      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const atBottom = scrollHeight - scrollTop <= clientHeight + 10;
      setIsAtBottom(atBottom);
    };

    if (messageContainer) {
      messageContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messageContainer) {
        messageContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <motion.div
      {...handlers}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={chatVariants}
      transition={{ duration: 0.3 }}
      className={`fixed inset-0 flex flex-col ${
        theme === 'dark' ? 'bg-gray-900' : 'bg-white'
      } transition-colors duration-300 z-50`}
    >
      {/* Botón de cierre en forma de pestaña en el centro superior */}
      <button
        onClick={toggleChat}
        className="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full p-2 shadow-lg focus:outline-none z-50"
        aria-label="Cerrar chat"
      >
        <AiOutlineClose size={24} />
      </button>

      {/* Encabezado del chat */}
      <div
        className={`relative flex items-center justify-center p-4 ${
          theme === 'dark'
            ? 'bg-gradient-to-r from-gray-800 to-gray-700'
            : 'bg-gradient-to-r from-blue-400 to-blue-500'
        }`}
      >
        {/* Botón para mostrar/ocultar la barra lateral */}
        <button
          onClick={handleToggleSidebar}
          ref={toggleButtonRef}
          className="absolute left-4 bg-transparent text-white p-2 rounded-full focus:outline-none z-10"
          aria-label="Mostrar u ocultar barra lateral"
        >
          <AiOutlineMenu size={24} />
        </button>

        {/* Nombre del asistente centrado y responsivo */}
        <h2 className="text-center text-white text-base md:text-lg px-4 font-semibold">
          <span className="block md:hidden">AMI</span>
          <span className="hidden md:block">
            Asistente Médico Inteligente (AMI)
          </span>
        </h2>
      </div>

      {/* Resto del componente */}
      <div className="flex flex-1 relative overflow-hidden">
        {/* Animación de la barra lateral */}
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              ref={sidebarRef}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={sidebarVariants}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className="w-64 md:w-64 sm:w-48 flex-shrink-0 bg-gray-800"
            >
              <ChatHistory
                chats={chats}
                currentChatId={currentChatId}
                handleSelectChat={handleSelectChat}
                handleNewChat={handleNewChat}
                handleRenameChat={handleRenameChat}
                handleDeleteChat={handleDeleteChat}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Área de mensajes e input */}
        <div className="flex-1 flex flex-col relative">
          {/* Historial de mensajes o input centrado */}
          {currentChat && currentChat.messages.length > 0 ? (
            <>
              <div
                ref={messageContainerRef}
                className={`flex-1 p-4 overflow-y-auto ${
                  theme === 'dark' ? 'text-white' : 'text-gray-800'
                }`}
              >
                {currentChat.messages.map((msg, index) => (
                  <motion.div
                    key={`${msg.id}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                    className={`mb-4 flex ${
                      msg.sender === 'user'
                        ? 'justify-end'
                        : 'justify-start'
                    }`}
                  >
                    {msg.cardData && Object.keys(msg.cardData).length > 0 ? (
                      // Renderiza el mensaje y el CanvasComponent juntos
                      <div
                        className={`flex flex-col md:flex-row items-center w-auto ${
                          msg.sender === 'user'
                            ? 'bg-blue-500 text-white'
                            : theme === 'dark'
                            ? 'bg-gray-700 text-white'
                            : 'bg-gray-200 text-gray-800'
                        } p-4 rounded-2xl shadow-md break-words max-w-full md:max-w-3xl`}
                      >
                        <div className="flex-1 mb-4 md:mb-0 md:mr-4">
                          {/* Renderizar el mensaje usando ReactMarkdown */}
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {preprocessMessage(msg.text)}
                          </ReactMarkdown>
                        </div>
                        <div className="flex-shrink-0 w-full md:w-auto">
                          {/* Renderiza el componente Canvas con cardData */}
                          <CanvasComponent cardData={msg.cardData} />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`px-4 py-2 rounded-2xl shadow-md ${
                          msg.sender === 'user'
                            ? 'bg-blue-500 text-white'
                            : theme === 'dark'
                            ? 'bg-gray-700 text-white'
                            : 'bg-gray-200 text-gray-800'
                        } w-auto break-words max-w-full md:max-w-3xl`}
                      >
                        {/* Renderizar el mensaje usando ReactMarkdown */}
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {preprocessMessage(msg.text)}
                        </ReactMarkdown>
                      </div>
                    )}
                  </motion.div>
                ))}

                {/* Mostrar spinner de carga si está cargando */}
                {isLoading && (
                  <div className="mb-4 flex justify-center">
                    <div
                      className={`px-4 py-2 rounded-2xl shadow-md ${
                        theme === 'dark'
                          ? 'bg-gray-700 text-white'
                          : 'bg-gray-200 text-gray-800'
                      } w-auto break-words flex items-center max-w-full md:max-w-3xl`}
                    >
                      <FaSpinner className="animate-spin mr-2" />
                      Procesando...
                    </div>
                  </div>
                )}

                {/* Referencia para el scroll al final */}
                <div ref={messagesEndRef} />
              </div>

              {/* Botón para hacer scroll al final */}
              {!isAtBottom && (
                <button
                  onClick={() => {
                    if (messagesEndRef.current) {
                      messagesEndRef.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }}
                  className="absolute bottom-24 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg focus:outline-none"
                  aria-label="Ir al último mensaje"
                >
                  <AiOutlineArrowDown size={24} />
                </button>
              )}

              {/* Input para enviar mensajes */}
              <div
                className={`p-4 flex ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'
                }`}
              >
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className={`flex-1 p-3 border-none ${
                    theme === 'dark'
                      ? 'bg-gray-700 text-white placeholder-gray-400'
                      : 'bg-white text-gray-800 placeholder-gray-500'
                  } rounded-l-full focus:outline-none`}
                  placeholder="Escribe tu mensaje..."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSend();
                    }
                  }}
                  disabled={isLoading}
                />
                <button
                  onClick={handleSend}
                  className={`${
                    theme === 'dark'
                      ? 'bg-gradient-to-r from-blue-500 to-purple-600'
                      : 'bg-blue-500'
                  } text-white px-4 py-2 rounded-r-full focus:outline-none flex items-center justify-center w-20 sm:w-24 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  aria-label="Enviar mensaje"
                  disabled={isLoading}
                >
                  <AiOutlineSend size={20} />
                </button>
              </div>
            </>
          ) : (
            // Si no hay mensajes, mostrar el input centrado
            <div
              className={`flex-1 flex flex-col items-center justify-center ${
                theme === 'dark' ? 'text-white' : 'text-gray-800'
              }`}
            >
              <div className="mb-8 text-center text-gray-500">
                {currentChat
                  ? 'El chat está vacío. Escribe un mensaje para comenzar la conversación.'
                  : 'No hay chats disponibles. Crea uno nuevo haciendo clic en el botón "+". Pero verifica antes, que ya hayas llenado tus datos y creado un plan, de lo contrario no funcionará el chat'}
              </div>
              <div className="w-full max-w-md px-4">
                <div
                  className={`flex ${
                    theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'
                  } rounded-full shadow-md`}
                >
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className={`flex-1 p-3 border-none ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-white placeholder-gray-400'
                        : 'bg-white text-gray-800 placeholder-gray-500'
                    } rounded-l-full focus:outline-none`}
                    placeholder="Escribe tu mensaje..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSend();
                      }
                    }}
                    disabled={isLoading}
                  />
                  <button
                    onClick={handleSend}
                    className={`${
                      theme === 'dark'
                        ? 'bg-gradient-to-r from-blue-500 to-purple-600'
                        : 'bg-blue-500'
                    } text-white px-4 py-2 rounded-r-full focus:outline-none flex items-center justify-center w-20 sm:w-24 ${
                      isLoading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    aria-label="Enviar mensaje"
                    disabled={isLoading}
                  >
                    <AiOutlineSend size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ChatWindow;
