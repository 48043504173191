// useFormValidation.js

import { calculateAge } from './healthCalculations';

const useFormValidation = () => {
  const validateForm = (formData) => {
    const age = calculateAge(formData.dob);
    let errors = [];

    // Validaciones
    if (age === null || age < 4) {
      errors.push('Debes tener al menos 4 años.');
    }

    if (!formData.weight || formData.weight <= 0) {
      errors.push('Ingresa un peso válido.');
    }

    if (!formData.height || formData.height <= 0) {
      errors.push('Ingresa una estatura válida.');
    }

    if (!formData.activityFactor) {
      errors.push('Selecciona un factor de actividad física.');
    }

    const optionalFieldsFilled = formData.neck || formData.waist || formData.hip;
    if (optionalFieldsFilled) {
      if (formData.sex === 'male') {
        if (!formData.neck || !formData.waist) {
          errors.push('Para calcular el porcentaje de grasa corporal, completa cuello y cintura.');
        }
      } else {
        if (!formData.neck || !formData.waist || !formData.hip) {
          errors.push('Para calcular el porcentaje de grasa corporal, completa cuello, cintura y cadera.');
        }
      }
    }

    return {
      errors,
      isValid: errors.length === 0,
      age,
    };
  };

  return {
    validateForm,
  };
};

export default useFormValidation;
