// useHealthMetrics.js

import { useEffect, useState, useRef } from 'react';
import useDataManagement from './useDataManagement';
import useFormValidation from './useFormValidation';
import {
  calculateBMI,
  calculateBMIPercentile,
  calculateBodyFat,
  calculateTDEE,
  generateSummary,
} from './healthCalculations';

const useHealthMetrics = () => {
  const initialData = {
    dob: '',
    sex: 'male',
    weight: '',
    height: '',
    activityFactor: 1.2,
    neck: '',
    waist: '',
    hip: '',
    bmi: null,
    bodyFat: null,
    tdee: null,
    bmr: null,
    age: null,
    bmiPercentile: null,
  };

  const {
    formData,
    setFormData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
  } = useDataManagement(initialData);

  const { validateForm } = useFormValidation();

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [visibleSVG, setVisibleSVG] = useState(null);
  const svgContainerRef = useRef(null);
  const timeoutId = useRef(null);

  const [isPlanActive, setIsPlanActive] = useState(false);

  const resultsRef = useRef(null);

  useEffect(() => {
    const checkPlanActive = () => {
      const events = JSON.parse(localStorage.getItem('events') || '[]');
      setIsPlanActive(events.length > 0);
    };

    checkPlanActive();
    const intervalId = setInterval(checkPlanActive, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { errors, isValid, age } = validateForm(formData);

    if (!isValid) {
      setErrorMessages(errors);
      return;
    } else {
      setErrorMessages([]);
    }

    const bmiValue = calculateBMI(formData.weight, formData.height);
    const bmiPercentile = calculateBMIPercentile(age, formData.sex, bmiValue);

    const bodyFatValue = calculateBodyFat(
      formData.sex,
      formData.weight,
      formData.height,
      age,
      formData.neck,
      formData.waist,
      formData.hip
    );

    const { bmr, tdee } = calculateTDEE(
      formData.sex,
      formData.weight,
      formData.height,
      age,
      formData.activityFactor
    );

    const updatedData = {
      ...formData,
      age,
      bmi: bmiValue,
      bmiPercentile,
      bodyFat: bodyFatValue,
      tdee,
      bmr,
    };

    await saveFormData(updatedData, user);

    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsSaved(false);
  };

  const handleActivityFactorChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      activityFactor: newValue,
    }));
    setIsSaved(false);
  };

  const activityLevels = [
    { label: 'Sedentario', value: 1.2 },
    { label: 'Ligera actividad', value: 1.375 },
    { label: 'Actividad moderada', value: 1.55 },
    { label: 'Alta actividad', value: 1.725 },
    { label: 'Actividad muy alta', value: 1.9 },
  ];

  const toggleSVG = (type) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    setVisibleSVG(type);

    timeoutId.current = setTimeout(() => {
      setVisibleSVG(null);
      const inputField = document.getElementById(type);
      if (inputField) {
        inputField.focus();
      }
    }, 3000);
  };

  useEffect(() => {
    if (visibleSVG && svgContainerRef.current) {
      svgContainerRef.current.focus();
    }
  }, [visibleSVG]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return {
    formData,
    handleSubmit,
    handleDelete: () => handleDelete(isPlanActive),
    handleInputChange,
    errorMessages,
    isSaved,
    isPlanActive,
    showAlert,
    activityLevels,
    handleActivityFactorChange,
    generateSummary,
    resultsRef,
    visibleSVG,
    toggleSVG,
    svgContainerRef,
  };
};

export default useHealthMetrics;
