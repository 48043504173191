// src/components/HourTracking.js

import React, { useState } from 'react';
import { ReactComponent as MoodIcon } from './mood.svg';
import { ReactComponent as StateIcon } from './state.svg';
import { ReactComponent as WorkoutIcon } from './workout.svg';
import { ReactComponent as FoodIcon } from './food.svg';
import FoodForm from './FoodForm';
import WorkoutForm from './WorkoutForm';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { useTheme } from '../../ThemeContext'; // Importamos el contexto del tema
import { usePlan } from '../../contexts/PlanContext'; // Importamos el PlanContext

const HourTracking = ({ hour, data, onSave, onDelete, onBack, metrics }) => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const { theme } = useTheme(); // Acceso al tema actual desde el contexto
  const { planId } = usePlan(); // Obtener planId del PlanContext

  const handleIconClick = (iconType) => {
    setSelectedIcon((prevIcon) => (prevIcon === iconType ? null : iconType));
  };

  const handleFormSubmit = async (dataObject) => {
    const newEntry = {
      id: Date.now(),
      type: selectedIcon,
      data: dataObject,
      icons: [selectedIcon],
      planId: planId || null, // Usar planId del contexto si está disponible
      hour: hour, // Agregar la hora para filtrar después
      date: data.date, // Utilizar la fecha de la prop `data`
    };
    setSelectedIcon(null);
    // Actualizar entradas locales
    onSave(newEntry);
  };

  const handleDelete = async (id) => {
    onDelete(id);
  };

  const renderForm = () => {
    switch (selectedIcon) {
      case 'mood':
        return (
          <form
            className="flex flex-col gap-3 text-sm"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit({ mood: e.target.mood.value });
            }}
          >
            <label
              className={`font-semibold ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
              }`}
            >
              Estado de ánimo:
            </label>
            <select
              name="mood"
              required
              className="p-2 rounded-md border border-gray-300 bg-white text-gray-700"
            >
              <option value="">Seleccionar...</option>
              <option value="enérgico">Enérgico</option>
              <option value="desanimado">Desanimado</option>
              <option value="sin-apetito">Sin apetito</option>
              <option value="ansioso">Ansioso</option>
              <option value="indiferente">Indiferente</option>
            </select>
            <button
              type="submit"
              className="bg-blue-600 text-white rounded-lg py-2 mt-3 shadow-md hover:bg-blue-700"
            >
              Guardar
            </button>
          </form>
        );
      case 'state':
        return (
          <form
            className="flex flex-col gap-3 text-sm"
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit({ state: e.target.state.value });
            }}
          >
            <label
              className={`font-semibold ${
                theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
              }`}
            >
              Estado físico:
            </label>
            <select
              name="state"
              required
              className="p-2 rounded-md border border-gray-300 bg-white text-gray-700"
            >
              <option value="">Seleccionar...</option>
              <option value="dolor-de-cabeza">Dolor de cabeza</option>
              <option value="cólico">Cólico</option>
              <option value="mareo">Mareo</option>
              <option value="náuseas">Náuseas</option>
              <option value="dolor-estomacal">Dolor estomacal</option>
              <option value="taquicardia">Taquicardia</option>
            </select>
            <button
              type="submit"
              className="bg-blue-600 text-white rounded-lg py-2 mt-3 shadow-md hover:bg-blue-700"
            >
              Guardar
            </button>
          </form>
        );
      case 'workout':
        return <WorkoutForm onSubmit={handleFormSubmit} metrics={metrics} />;
      case 'food':
        return <FoodForm onSubmit={handleFormSubmit} />;
      default:
        return null;
    }
  };

  const renderEntry = (entry) => {
    let entryLabel = '';
    switch (entry.type) {
      case 'mood':
        entryLabel = 'Estado de ánimo';
        break;
      case 'state':
        entryLabel = 'Estado físico';
        break;
      case 'workout':
        entryLabel = 'Ejercicio';
        break;
      case 'food':
        entryLabel = 'Alimento';
        break;
      default:
        break;
    }

    if (entry.type === 'food') {
      if (typeof entry.data.nutritionDetails !== 'object') {
        console.error(
          'nutritionDetails is not an object:',
          entry.data.nutritionDetails
        );
        return `${entryLabel}: ${entry.data.food} - Detalles nutricionales no disponibles.`;
      }
      const {
        calories,
        fat,
        carbohydrates,
        protein,
        fiber,
        sodium,
      } = entry.data.nutritionDetails;
      return `${entryLabel}: ${entry.data.food} - Calorías: ${calories} kcal, Grasas: ${fat} g, Carbohidratos: ${carbohydrates} g, Proteínas: ${protein} g, Fibra: ${fiber} g, Sodio: ${sodium} mg`;
    }

    if (entry.type === 'workout') {
      return `${entryLabel}: ${entry.data.workoutType} - Duración: ${entry.data.duration} minutos, Calorías Quemadas: ${entry.data.caloriesBurned} kcal`;
    }

    const value = Object.values(entry.data)[0];
    return `${entryLabel}: ${
      value.charAt(0).toUpperCase() + value.slice(1)
    }`;
  };

  // Filtrar las entradas por tipo seleccionado
  const filteredEntries = selectedIcon
    ? data.filter((entry) => entry.type === selectedIcon)
    : data;

  return (
    <div
      className={`flex flex-col items-center p-5 ${
        theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
      } rounded-2xl shadow-lg transition-colors duration-300 w-full max-w-4xl mx-auto mt-5`}
    >
      <button
        onClick={onBack}
        className="flex items-center mb-5 bg-blue-600 text-white px-4 py-2 rounded-full shadow-lg hover:bg-blue-700 transition-transform transform hover:-translate-x-1"
      >
        <FaArrowLeft className="mr-2" />
        Volver
      </button>
      <div className="flex justify-around w-full max-w-xs mb-5">
        <MoodIcon
          className={`w-10 h-10 cursor-pointer transform transition-transform duration-300 ${
            selectedIcon === 'mood'
              ? 'scale-125 text-blue-600'
              : 'text-gray-400 hover:scale-125 hover:text-blue-500'
          }`}
          onClick={() => handleIconClick('mood')}
        />
        <StateIcon
          className={`w-10 h-10 cursor-pointer transform transition-transform duration-300 ${
            selectedIcon === 'state'
              ? 'scale-125 text-blue-600'
              : 'text-gray-400 hover:scale-125 hover:text-blue-500'
          }`}
          onClick={() => handleIconClick('state')}
        />
        <WorkoutIcon
          className={`w-10 h-10 cursor-pointer transform transition-transform duration-300 ${
            selectedIcon === 'workout'
              ? 'scale-125 text-blue-600'
              : 'text-gray-400 hover:scale-125 hover:text-blue-500'
          }`}
          onClick={() => handleIconClick('workout')}
        />
        <FoodIcon
          className={`w-10 h-10 cursor-pointer transform transition-transform duration-300 ${
            selectedIcon === 'food'
              ? 'scale-125 text-blue-600'
              : 'text-gray-400 hover:scale-125 hover:text-blue-500'
          }`}
          onClick={() => handleIconClick('food')}
        />
      </div>
      <h2 className="text-xl font-bold mb-4">{`${hour
        .toString()
        .padStart(2, '0')}:00`}</h2>
      <div
        className={`${
          theme === 'dark'
            ? 'bg-gray-700 border-gray-600 text-gray-300'
            : 'bg-gray-100 border-gray-200 text-gray-700'
        } rounded-lg p-4 flex items-center justify-center shadow-inner w-full max-w-xs`}
      >
        {renderForm()}
      </div>
      <div className="mt-5 w-full">
        {filteredEntries.map((entry) => (
          <div
            key={entry.id}
            className={`flex justify-between items-center p-3 ${
              theme === 'dark'
                ? 'bg-gray-700 hover:bg-gray-600'
                : 'bg-gray-50 hover:bg-gray-100'
            } rounded-xl shadow-md mb-4 transition-all duration-300`}
          >
            <p className="text-sm font-semibold">{renderEntry(entry)}</p>
            <button
              onClick={() => handleDelete(entry.id)}
              className="p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 hover:scale-105 transition-transform duration-300"
            >
              <FaTrash className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HourTracking;
