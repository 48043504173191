import React from 'react';
import { useTheme } from '../../ThemeContext';
import { FaAppleAlt, FaFire, FaDumbbell, FaWeight, FaHeartbeat } from 'react-icons/fa';
import CircularProgressBar from './CircularProgressBar';

const AdditionalMetrics = ({
  metrics,
  description,
  location,
  maxCaloriesBurned,
  nutritionDetail,
  exerciseActivities,
  routine,
}) => {
  const { theme } = useTheme();

  // Funciones auxiliares para manejar clases según el tema
  const getBackgroundClass = (light, dark) => (theme === 'dark' ? dark : light);
  const getTextClass = (light, dark) => (theme === 'dark' ? dark : light);
  const getBorderClass = (light, dark) => (theme === 'dark' ? dark : light);

  return (
    <div className="mt-2">
      {/* Sección de Calorías Ingeridas */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className={`border p-4 rounded-lg shadow-lg ${getBackgroundClass('bg-white', 'bg-gray-800')}`}>
          <div className={`flex flex-col items-center border-b pb-2 w-full ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
            <div className="flex items-center">
              <FaAppleAlt className="text-2xl text-green-500 mr-2" />
              <span className={`text-xl font-bold ${getTextClass('text-gray-800', 'text-gray-100')} mb-1`}>
                Calorías ingeridas
              </span>
            </div>
            <div className="flex items-center mt-2">
              <CircularProgressBar
                maxValue={metrics.dailyCaloricIntake}
                currentValue={metrics.currentCalories}
                size={80}
                strokeWidth={8}
                color="green"
              />
            </div>
          </div>
          {nutritionDetail && Object.keys(nutritionDetail).length > 0 && (
            <div className={`mt-4 grid grid-cols-1 gap-2 text-sm w-full ${getTextClass('text-gray-800', 'text-gray-100')}`}>
              {/* Detalles de Nutrición */}
              {nutritionDetail.protein > 0 && (
                <p className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
                  Proteínas: <span className="font-semibold">{nutritionDetail.protein} g</span>
                </p>
              )}
              {nutritionDetail.fat > 0 && (
                <p className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
                  Grasas: <span className="font-semibold">{nutritionDetail.fat} g</span>
                </p>
              )}
              {nutritionDetail.carbohydrates > 0 && (
                <p className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
                  Carbohidratos: <span className="font-semibold">{nutritionDetail.carbohydrates} g</span>
                </p>
              )}
              {nutritionDetail.fiber > 0 && (
                <p className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
                  Fibra: <span className="font-semibold">{nutritionDetail.fiber} g</span>
                </p>
              )}
              {nutritionDetail.sodium > 0 && (
                <p className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
                  Sodio: <span className="font-semibold">{nutritionDetail.sodium} mg</span>
                </p>
              )}
            </div>
          )}
        </div>

        {/* Sección de Calorías Quemadas */}
        <div className={`border p-4 rounded-lg shadow-lg ${getBackgroundClass('bg-white', 'bg-gray-800')}`}>
          <div className={`flex flex-col items-center border-b pb-2 w-full ${getBorderClass('border-gray-300', 'border-gray-700')}`}>
            <div className="flex items-center">
              <FaFire className="text-2xl text-red-500 mr-2" />
              <span className={`text-xl font-bold ${getTextClass('text-gray-800', 'text-gray-100')} mb-1`}>
                Calorías quemadas
              </span>
            </div>
            <div className="flex items-center mt-2">
              <CircularProgressBar
                maxValue={maxCaloriesBurned}
                currentValue={metrics.currentCaloriesBurned}
                size={80}
                strokeWidth={8}
                color="red"
              />
            </div>
          </div>
          {exerciseActivities && exerciseActivities.length > 0 && (
            <div className={`mt-4 grid grid-cols-1 gap-2 text-sm w-full ${getTextClass('text-gray-800', 'text-gray-100')}`}>
              <ul className="list-disc list-inside">
                {exerciseActivities.map((activity, index) => (
                  <li
                    key={index}
                    className={`border p-2 rounded-lg ${getBorderClass('border-gray-300', 'border-gray-700')}`}
                  >
                    {activity.workoutType && activity.duration && activity.caloriesBurned > 0 && (
                      <>
                        <span className="font-semibold">{activity.workoutType}</span>, {activity.duration},{' '}
                        <span className="font-semibold">{activity.caloriesBurned} kcal</span>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Sección de Información Adicional */}
      <div className={`mt-6 p-4 rounded-lg space-y-2 text-sm ${theme === 'dark' ? 'bg-gray-700 text-gray-100' : 'bg-gray-50 text-gray-800'}`}>
        <p>
          <strong>Descripción:</strong> {description || 'No disponible'}
        </p>
        <p>
          <strong>Ubicación:</strong> {location || 'No disponible'}
        </p>
        {routine && routine.length > 0 && (
          <div>
            <div className="flex items-center">
              <FaDumbbell className="text-xl text-blue-500 mr-2" />
              <strong>Rutina de Ejercicios:</strong>
            </div>
            <ul className="list-disc list-inside ml-6 mt-2">
              {routine.map((exercise, index) => (
                <li key={index}>
                  {exercise.nombre}: <span className="font-semibold">{exercise.series} series x {exercise.repeticiones} repeticiones</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <p>
          <FaWeight className={`inline text-lg mr-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`} />
          <strong>Peso:</strong> <span className="font-semibold text-lg">{metrics.dailyWeight} kg</span>
        </p>
        <p>
          <FaHeartbeat className={`inline text-lg mr-2 ${theme === 'dark' ? 'text-red-300' : 'text-red-500'}`} />
          <strong>IMC:</strong> <span className="font-semibold text-lg">{metrics.dailyBMI}</span>
        </p>
        <p>
          <strong>% Grasa Corporal:</strong> <span className="font-semibold text-lg">{metrics.dailyBodyFat}</span>
        </p>
        <p>
          <strong>Ganancia de Músculo (Diaria):</strong> <span className="font-semibold text-lg">{metrics.dailyMuscleGain} kg</span>
        </p>
        <p>
          <strong>Ganancia de Músculo (Acumulada):</strong> <span className="font-semibold text-lg">{metrics.totalMuscleGain} kg</span>
        </p>
      </div>
    </div>
  );
};

export default AdditionalMetrics;
