// AudioManager.jsx
import React, { useState, useEffect } from 'react';
import AudioRecorder from './AudioRecorder';
import { useTheme } from '../../../ThemeContext';
import useAudioProcessor from './useAudioProcessor'; // Importar el hook

const AudioManager = () => {
  const { theme } = useTheme();
  const [stream, setStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null); // Estado para AudioContext
  const [error, setError] = useState(null);
  const [isStarted, setIsStarted] = useState(false); // Estado para gestionar si el audio ha comenzado

  const startAudio = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(mediaStream);
      console.log('Media stream recibido:', mediaStream);
      // Crear el AudioContext
      const context = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(context);
      setIsStarted(true); // Cambiar el estado a iniciado
      console.log('AudioContext creado:', context);
    } catch (err) {
      console.error('Error al acceder al micrófono:', err);
      setError('No se pudo acceder al micrófono. Por favor, verifica los permisos.');
    }
  };

  useEffect(() => {
    // Limpieza al desmontar el componente
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      if (audioContext) {
        audioContext.close().catch(err => console.error('Error al cerrar AudioContext:', err));
      }
    };
  }, [stream, audioContext]);

  // Utilizar el hook useAudioProcessor
  const {
    processedStream,
    isCalibrating,
    startCalibration,
    error: processorError,
  } = useAudioProcessor({
    audioContext,
    mediaStream: stream,
    onTimeDomainData: (data) => {
      console.log('Datos de dominio de tiempo recibidos:', data);
    },
    onFrequencyData: (data) => {
      console.log('Datos de frecuencia recibidos:', data);
    },
    gain: 1.18,
    ampGain: 2.68,
    finalAmpGain: 1,
    noiseProfile: null,
  });

  // Iniciar la calibración automáticamente una vez que el audio ha comenzado
  useEffect(() => {
    if (isStarted && startCalibration) {
      startCalibration();
    }
  }, [isStarted, startCalibration]);

  // Manejar errores tanto del acceso al micrófono como del procesamiento
  if (error || processorError) {
    return (
      <div
        className="mt-8 p-4 rounded-lg shadow-md"
        style={{ backgroundColor: theme === 'dark' ? '#2d3748' : '#f9fafb' }}
      >
        <p className="text-red-500 text-center">{error || processorError}</p>
      </div>
    );
  }

  // Mostrar un botón de inicio si aún no ha comenzado el audio
  if (!isStarted) {
    return (
      <div
        className="mt-8 p-4 rounded-lg shadow-md flex items-center justify-center"
        style={{ backgroundColor: theme === 'dark' ? '#2d3748' : '#f9fafb' }}
      >
        <button
          onClick={startAudio}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          Iniciar Audio
        </button>
      </div>
    );
  }

  // Mostrar un mensaje de carga mientras se procesa el audio
  return (
    <div
      className="flex flex-col items-center"
      style={{ backgroundColor: theme === 'dark' ? '#1a202c' : '#ffffff' }}
    >
      {/* Grabador de Audio con el stream procesado */}
      <AudioRecorder stream={processedStream} />

      {/* Mostrar un indicador de calibración */}
      {isCalibrating && (
        <div className="mt-4">
          <p className="text-yellow-500">Calibrando perfil de ruido...</p>
        </div>
      )}
    </div>
  );
};

export default AudioManager;
