// App.js
import React, { useState } from 'react';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Dashboard/Dashboard';
import Footer from './Components/Footer/Footer';
import { ThemeProvider } from './Components/ThemeContext';
import { AuthProvider } from './Components/contexts/AuthContext';
import { PlanProvider } from './Components/contexts/PlanContext';
import { ChatProvider } from './Components/contexts/ChatContext'; // Importa ChatProvider
import AssistantChat from './Components/assistant/AssistantChat';

const App = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <ThemeProvider>
      <AuthProvider>
        <PlanProvider>
          <ChatProvider> {/* Envolver AssistantChat con ChatProvider */}
            <div className="flex flex-col min-h-screen">
              {!isChatOpen && <Sidebar />}
              {/* Dashboard */}
              <Dashboard />

              {/* Footer */}
              <Footer />

              {/* Componente AssistantChat */}
              <AssistantChat isOpen={isChatOpen} toggleChat={toggleChat} />
            </div>
          </ChatProvider>
        </PlanProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
