// src/hooks/useDayTracking.js

import { useState, useEffect, useCallback } from 'react';
import { usePlan } from '../../contexts/PlanContext';
import { useAuth } from '../../contexts/AuthContext';
import { getEntries, saveEntry, deleteEntry as deleteEntryFromAPI } from '../../Sidebar/formats/apiService'; // Ajusta la ruta según sea necesario

/**
 * Custom hook to handle daily tracking of activities and nutrition.
 *
 * @param {string} planId - Current plan identifier. (Este parámetro será ignorado y se usará el planId del contexto)
 * @param {string} eventDate - Date of the event in format 'DD/MM/YYYY'.
 * @param {function} onDataChange - Callback for changes in the data.
 * @returns {object} - State and functions to handle daily data.
 */
const useDayTracking = (planId, eventDate, onDataChange) => {
  const { planId: contextPlanId } = usePlan(); // Obtener planId del contexto
  const effectivePlanId = contextPlanId; // Usar el planId del contexto en lugar del pasado como parámetro

  const { user } = useAuth(); // Obtener usuario del contexto de autenticación

  // Estado para los datos registrados por hora
  const [registeredData, setRegisteredData] = useState({});

  // Estado para el resumen nutricional diario
  const [dailyNutritionSummary, setDailyNutritionSummary] = useState({
    calories: 0,
    fat: 0,
    carbohydrates: 0,
    protein: 0,
    fiber: 0,
    sodium: 0,
  });

  // Estado para las calorías quemadas diarias y actividades de ejercicio
  const [dailyExerciseCalories, setDailyExerciseCalories] = useState(0);
  const [exerciseActivities, setExerciseActivities] = useState([]);

  // Estado para los conteos de cada tipo
  const [typeCounts, setTypeCounts] = useState({
    mood: 0,
    state: 0,
    workout: 0,
    food: 0,
  });

  /**
   * Fetch entries from database if user is logged in, otherwise load from localStorage.
   */
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        // Usuario está logueado, obtener datos de la base de datos
        try {
          const dateParts = eventDate.split('/');
          // Crear un rango de fechas para todo el día en UTC
          const startDate = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 0, 0, 0));
          const endDate = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], 23, 59, 59));

          const entries = await getEntries(effectivePlanId, startDate, endDate);

          if (entries) {
            // Procesar entradas y actualizar registeredData
            const newRegisteredData = {};

            entries.forEach(entry => {
              const entryDate = new Date(entry.timestamp);
              const entryHour = entryDate.getUTCHours(); // Usar getUTCHours para alinear con tiempo UTC

              if (!newRegisteredData[entryHour]) {
                newRegisteredData[entryHour] = [];
              }
              newRegisteredData[entryHour].push({
                id: entry._id,
                type: entry.type,
                data: entry.data,
                planId: entry.planId,
                hour: entryHour, // Agregar la propiedad 'hour'
                date: eventDate, // Agregar la propiedad 'date'
              });
            });

            setRegisteredData(newRegisteredData);

            // También actualizar localStorage
            try {
              const existingData = JSON.parse(localStorage.getItem('hourTrackingData')) || {};
              if (!existingData[effectivePlanId]) {
                existingData[effectivePlanId] = {};
              }
              existingData[effectivePlanId][eventDate] = newRegisteredData;
              localStorage.setItem('hourTrackingData', JSON.stringify(existingData));
            } catch (error) {
              console.error('Error saving "hourTrackingData" to localStorage:', error);
            }
          } else {
            console.error('No entries returned from getEntries');
          }
        } catch (error) {
          console.error('Error fetching entries:', error);
        }
      } else {
        // Usuario no está logueado, cargar datos desde localStorage
        try {
          const savedData = localStorage.getItem('hourTrackingData');
          const parsedData = savedData ? JSON.parse(savedData) : {};
          const data = parsedData[effectivePlanId]?.[eventDate] || {};
          setRegisteredData(data);
        } catch (error) {
          console.error('Error parsing "hourTrackingData" from localStorage:', error);
        }
      }
    };

    fetchData();
  }, [user, effectivePlanId, eventDate]);

  /**
   * Function to add a new entry.
   *
   * @param {number} hour - Hour of the day (0-23).
   * @param {object} dataObject - Data of the entry (e.g., exercise or food).
   */
  const addEntry = useCallback(async (hour, dataObject) => {
    const tempId = Date.now(); // ID temporal hasta obtener el ID de la base de datos
    const newEntry = {
      id: tempId,
      type: dataObject.type,
      data: dataObject.data,
      planId: effectivePlanId,
      hour: hour, // Agregar la propiedad 'hour'
      date: eventDate, // Agregar la propiedad 'date'
    };

    setRegisteredData(prevData => ({
      ...prevData,
      [hour]: [...(prevData[hour] || []), newEntry],
    }));

    if (user) {
      try {
        // Parsear eventDate y establecer el timestamp en UTC
        const dateParts = eventDate.split('/');
        const timestamp = new Date(Date.UTC(dateParts[2], dateParts[1] - 1, dateParts[0], hour, 0, 0));

        const entryData = {
          planId: effectivePlanId,
          timestamp: timestamp.toISOString(),
          type: dataObject.type,
          data: dataObject.data,
        };

        const savedEntry = await saveEntry(entryData);

        if (savedEntry) {
          // Actualizar la entrada en registeredData con el ID real de la base de datos
          setRegisteredData(prevData => {
            const updatedHourEntries = prevData[hour].map(entry => {
              if (entry.id === tempId) {
                return {
                  ...entry,
                  id: savedEntry._id, // Actualizar con el ID real
                };
              }
              return entry;
            });
            return {
              ...prevData,
              [hour]: updatedHourEntries,
            };
          });

          // También actualizar localStorage
          try {
            const existingData = JSON.parse(localStorage.getItem('hourTrackingData')) || {};
            if (!existingData[effectivePlanId]) {
              existingData[effectivePlanId] = {};
            }
            if (!existingData[effectivePlanId][eventDate]) {
              existingData[effectivePlanId][eventDate] = {};
            }
            if (!existingData[effectivePlanId][eventDate][hour]) {
              existingData[effectivePlanId][eventDate][hour] = [];
            }
            existingData[effectivePlanId][eventDate][hour] = existingData[effectivePlanId][eventDate][hour].map(entry => {
              if (entry.id === tempId) {
                return {
                  ...entry,
                  id: savedEntry._id,
                };
              }
              return entry;
            });
            localStorage.setItem('hourTrackingData', JSON.stringify(existingData));
          } catch (error) {
            console.error('Error updating "hourTrackingData" in localStorage:', error);
          }
        }
      } catch (error) {
        console.error('Error saving entry to database:', error);
      }
    }

    // Llamar a onDataChange si está definido
    if (onDataChange) {
      onDataChange();
    }

  }, [effectivePlanId, user, eventDate, onDataChange]);

  /**
   * Function to delete an entry by its ID.
   *
   * @param {number} hour - Hour of the day (0-23).
   * @param {number} id - Unique identifier of the entry.
   */
  const deleteEntry = useCallback(async (hour, id) => {
    // Guardar una copia de la entrada para posibles restauraciones en caso de error
    const entryToDelete = registeredData[hour]?.find(entry => entry.id === id);

    if (!entryToDelete) {
      console.error(`No se encontró la entrada con ID: ${id} en la hora: ${hour}`);
      return;
    }

    // Actualizar el estado optimísticamente
    setRegisteredData(prevData => ({
      ...prevData,
      [hour]: prevData[hour].filter(entry => entry.id !== id),
    }));

    // Actualizar localStorage
    try {
      const existingData = JSON.parse(localStorage.getItem('hourTrackingData')) || {};
      if (
        existingData[effectivePlanId] &&
        existingData[effectivePlanId][eventDate] &&
        existingData[effectivePlanId][eventDate][hour]
      ) {
        existingData[effectivePlanId][eventDate][hour] = existingData[effectivePlanId][eventDate][hour].filter(entry => entry.id !== id);
        localStorage.setItem('hourTrackingData', JSON.stringify(existingData));
      }
    } catch (error) {
      console.error('Error updating "hourTrackingData" in localStorage:', error);
    }

    if (user) {
      try {
        const result = await deleteEntryFromAPI(id);
        if (!result.success) { // Ajustar la condición para verificar 'success'
          console.error('Error al eliminar la entrada:', result.message);
          // Restaurar la entrada en el estado si la eliminación falla
          if (entryToDelete) {
            setRegisteredData(prevData => ({
              ...prevData,
              [hour]: [...prevData[hour], entryToDelete],
            }));
          }
        } else {
          console.log('Entrada eliminada exitosamente');
        }
      } catch (error) {
        console.error('Error al eliminar la entrada desde el servidor:', error);
        // Restaurar la entrada en el estado si hay un error en la eliminación
        if (entryToDelete) {
          setRegisteredData(prevData => ({
            ...prevData,
            [hour]: [...prevData[hour], entryToDelete],
          }));
        }
      }
    }

    // Llamar a onDataChange si está definido
    if (onDataChange) {
      onDataChange();
    }
  }, [user, effectivePlanId, eventDate, registeredData, onDataChange]);

  /**
   * Function to calculate the daily nutritional summary.
   */
  const calculateDailyNutritionSummary = useCallback(() => {
    const summary = {
      calories: 0,
      fat: 0,
      carbohydrates: 0,
      protein: 0,
      fiber: 0,
      sodium: 0,
    };

    Object.values(registeredData).forEach(hourData => {
      hourData.forEach(entry => {
        if (entry.type === 'food' && entry.data.nutritionDetails) {
          const { calories, fat, carbohydrates, protein, fiber, sodium } = entry.data.nutritionDetails;
          summary.calories += parseFloat(calories) || 0;
          summary.fat += parseFloat(fat) || 0;
          summary.carbohydrates += parseFloat(carbohydrates) || 0;
          summary.protein += parseFloat(protein) || 0;
          summary.fiber += parseFloat(fiber) || 0;
          summary.sodium += parseFloat(sodium) || 0;
        }
      });
    });

    setDailyNutritionSummary(summary);
  }, [registeredData]);

  /**
   * Function to calculate daily burned calories and exercise activities.
   */
  const calculateDailyExerciseData = useCallback(() => {
    let exerciseCalories = 0;
    const activities = [];

    Object.values(registeredData).forEach(hourData => {
      hourData.forEach(entry => {
        if (entry.type === 'workout' && entry.data.caloriesBurned) {
          exerciseCalories += entry.data.caloriesBurned;
          activities.push({
            workoutType: entry.data.workoutType,
            duration: entry.data.duration,
            caloriesBurned: entry.data.caloriesBurned,
          });
        }
      });
    });

    setDailyExerciseCalories(exerciseCalories);
    setExerciseActivities(activities);
  }, [registeredData]);

  /**
   * Function to calculate counts of each entry type.
   */
  const calculateTypeCounts = useCallback(() => {
    const counts = { mood: 0, state: 0, workout: 0, food: 0 };
    Object.values(registeredData).forEach(hourData => {
      hourData.forEach(entry => {
        if (counts.hasOwnProperty(entry.type)) {
          counts[entry.type] += 1;
        }
      });
    });
    setTypeCounts(counts);
  }, [registeredData]);

  /**
   * Synchronize registeredData with localStorage whenever it changes.
   */
  useEffect(() => {
    try {
      const existingData = JSON.parse(localStorage.getItem('hourTrackingData')) || {};
      if (!existingData[effectivePlanId]) {
        existingData[effectivePlanId] = {};
      }
      existingData[effectivePlanId][eventDate] = registeredData;
      localStorage.setItem('hourTrackingData', JSON.stringify(existingData));
    } catch (error) {
      console.error('Error saving "hourTrackingData" to localStorage:', error);
    }
  }, [registeredData, effectivePlanId, eventDate]);

  /**
   * Calculate nutritional summaries, exercise data, and type counts whenever registeredData changes.
   */
  useEffect(() => {
    calculateDailyNutritionSummary();
    calculateDailyExerciseData();
    calculateTypeCounts();
  }, [registeredData, calculateDailyNutritionSummary, calculateDailyExerciseData, calculateTypeCounts]);

  /**
   * Save daily summaries and counts to localStorage.
   */
  useEffect(() => {
    try {
      const existingSummaries = JSON.parse(localStorage.getItem('dailyNutritionSummaries')) || {};
      if (!existingSummaries[effectivePlanId]) {
        existingSummaries[effectivePlanId] = {};
      }
      existingSummaries[effectivePlanId][eventDate] = {
        ...dailyNutritionSummary,
        caloriesBurned: dailyExerciseCalories,
        exerciseActivities: exerciseActivities,
        typeCounts: typeCounts,
      };
      localStorage.setItem('dailyNutritionSummaries', JSON.stringify(existingSummaries));
    } catch (error) {
      console.error('Error saving "dailyNutritionSummaries" to localStorage:', error);
    }
  }, [
    dailyNutritionSummary,
    dailyExerciseCalories,
    exerciseActivities,
    typeCounts,
    effectivePlanId,
    eventDate,
  ]);

  return {
    registeredData,
    dailyNutritionSummary,
    dailyExerciseCalories,
    exerciseActivities,
    typeCounts,
    addEntry,
    deleteEntry,
  };
};

export default useDayTracking;
