// useAudioProcessor.js
import { useRef, useEffect, useState } from 'react';

// Función de submuestreo
const downsampleArray = (dataArray, targetSize) => {
  const factor = dataArray.length / targetSize;
  const downsampled = [];
  for (let i = 0; i < targetSize; i++) {
    const start = Math.floor(i * factor);
    const end = Math.floor((i + 1) * factor);
    let sum = 0;
    for (let j = start; j < end; j++) {
      sum += dataArray[j];
    }
    downsampled.push(Math.round(sum / (end - start)));
  }
  return downsampled;
};

const useAudioProcessor = ({
  audioContext,              // Nuevo parámetro para AudioContext
  mediaStream,               // Nuevo parámetro para MediaStream
  onTimeDomainData = () => {},   // Función vacía por defecto
  onFrequencyData = () => {},    // Función vacía por defecto
  gain = 1,
  ampGain = 1,
  finalAmpGain = 1,
  noiseProfile = null,           // Nuevo prop para el perfil de ruido
} = {}) => {                      // Asignar objeto vacío por defecto
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [noiseProfileData, setNoiseProfileData] = useState(null);
  const [error] = useState(null);

  const analyserRef = useRef(null);
  const gainNodeRef = useRef(null); // Ganancia Inicial (Pre-Amplificación)

  // **Filtros para Eliminación de Ruido**
  const noiseHighPassRef = useRef(null);
  const noiseLowPassRef = useRef(null);

  // Filtro Paso Banda Inicial (10Hz - 230Hz)
  const bandPassFilterInitialRef = useRef(null);

  // Filtros para Rama 1 (10Hz - 60Hz)
  const bandPass1HighRef = useRef(null); // High-Pass 10Hz
  const bandPass1LowRef = useRef(null);  // Low-Pass 60Hz

  // Filtros para Rama 2 (50Hz - 250Hz)
  const bandPass2HighRef = useRef(null); // High-Pass 50Hz
  const bandPass2LowRef = useRef(null);  // Low-Pass 250Hz

  const sumGainNode2Ref = useRef(null); // Suma de las dos ramas filtradas
  const amplificationGainNodeRef = useRef(null); // Amplificación Adicional

  // Filtro para Sustracción (4250Hz - 7500Hz)
  const bandPassFilterSubtractionRef = useRef(null);

  const invertGainNodeRef = useRef(null); // Invertir Señal para Sustracción
  const finalSumGainNodeRef = useRef(null); // Suma Final (Restar)

  // Filtro Paso Banda Final (10Hz - 500Hz)
  const bandPassFilterFinalRef = useRef(null);
  
  // **Nuevos Filtros para la Nueva Etapa**
  const bandPassFinalStageHigh1Ref = useRef(null); // High-Pass 10Hz
  const bandPassFinalStageLow1Ref = useRef(null);  // Low-Pass 80Hz
  const bandPassFinalStageHigh2Ref = useRef(null); // High-Pass 70Hz
  const bandPassFinalStageLow2Ref = useRef(null);  // Low-Pass 250Hz
  const sumFinalStageRef = useRef(null);           // Suma de las dos ramas filtradas
  const additionalLowPassRef = useRef(null);        // Filtro Paso Bajo Adicional
  const finalAmplificationGainNodeRef = useRef(null); // Amplificación Final (Volumen)

  // **Nuevo Ref para el DelayNode (Look-Ahead)**
  const lookAheadDelayRef = useRef(null); // Nuevo nodo para Look-Ahead

  const animationIdRef = useRef(null);

  // Referencia para MediaStreamDestination
  const mediaStreamDestinationRef = useRef(null);

  // **Nodo de Compresión Opcional**
  const compressorRef = useRef(null);

  useEffect(() => {
    if (audioContext && mediaStream) {
      const currentTime = audioContext.currentTime;

      // Crear MediaStreamSource
      const source = audioContext.createMediaStreamSource(mediaStream);

      // **Crear Filtros de Eliminación de Ruido Personalizados Mejorados**
      noiseHighPassRef.current = audioContext.createBiquadFilter();
      noiseHighPassRef.current.type = 'highpass';
      noiseHighPassRef.current.frequency.setValueAtTime(300, currentTime); // Paso alto a 300Hz
      noiseHighPassRef.current.Q.setValueAtTime(1, currentTime); // Ajustar Q

      noiseLowPassRef.current = audioContext.createBiquadFilter();
      noiseLowPassRef.current.type = 'lowpass';
      noiseLowPassRef.current.frequency.setValueAtTime(7500, currentTime); // Paso bajo a 7500Hz
      noiseLowPassRef.current.Q.setValueAtTime(1, currentTime); // Ajustar Q

      // 1. Crear el nodo de ganancia inicial (Pre-Amplificación)
      gainNodeRef.current = audioContext.createGain();
      gainNodeRef.current.gain.setValueAtTime(gain, currentTime);

      // 2. Crear el filtro paso banda inicial (10Hz - 230Hz)
      bandPassFilterInitialRef.current = audioContext.createBiquadFilter();
      bandPassFilterInitialRef.current.type = 'bandpass';
      bandPassFilterInitialRef.current.frequency.setValueAtTime(255, currentTime); // Frecuencia central
      bandPassFilterInitialRef.current.Q.setValueAtTime(255 / 400, currentTime); // Q para 10-230Hz

      // 3. Crear los filtros para Rama 1 (10Hz - 60Hz)
      bandPass1HighRef.current = audioContext.createBiquadFilter();
      bandPass1HighRef.current.type = 'highpass';
      bandPass1HighRef.current.frequency.setValueAtTime(10, currentTime);

      bandPass1LowRef.current = audioContext.createBiquadFilter();
      bandPass1LowRef.current.type = 'lowpass';
      bandPass1LowRef.current.frequency.setValueAtTime(60, currentTime);

      // 4. Crear los filtros para Rama 2 (50Hz - 250Hz)
      bandPass2HighRef.current = audioContext.createBiquadFilter();
      bandPass2HighRef.current.type = 'highpass';
      bandPass2HighRef.current.frequency.setValueAtTime(50, currentTime);

      bandPass2LowRef.current = audioContext.createBiquadFilter();
      bandPass2LowRef.current.type = 'lowpass';
      bandPass2LowRef.current.frequency.setValueAtTime(250, currentTime);

      // 5. Crear el nodo de suma para las dos ramas filtradas
      sumGainNode2Ref.current = audioContext.createGain();

      // 6. Crear el nodo de amplificación adicional
      amplificationGainNodeRef.current = audioContext.createGain();
      amplificationGainNodeRef.current.gain.setValueAtTime(
        ampGain,
        currentTime
      );

      // 7. Crear el filtro para sustracción (4250Hz - 7500Hz)
      bandPassFilterSubtractionRef.current = audioContext.createBiquadFilter();
      bandPassFilterSubtractionRef.current.type = 'bandpass';
      bandPassFilterSubtractionRef.current.frequency.setValueAtTime(4250, currentTime); // Frecuencia central
      bandPassFilterSubtractionRef.current.Q.setValueAtTime(4250 / 7500, currentTime); // Q

      // 8. Crear el GainNode para invertir la señal (para la sustracción)
      invertGainNodeRef.current = audioContext.createGain();
      invertGainNodeRef.current.gain.setValueAtTime(-1, currentTime);

      // 9. Crear el nodo de suma final para la sustracción
      finalSumGainNodeRef.current = audioContext.createGain();

      // 10. Crear el filtro paso banda final (10Hz - 500Hz)
      bandPassFilterFinalRef.current = audioContext.createBiquadFilter();
      bandPassFilterFinalRef.current.type = 'bandpass';
      bandPassFilterFinalRef.current.frequency.setValueAtTime(255, currentTime); // Frecuencia central
      bandPassFilterFinalRef.current.Q.setValueAtTime(255 / 420, currentTime); // Q para 10-500Hz

      // 11. **Crear los nuevos filtros para la nueva etapa de filtrado**
      // Ruta 1: 10Hz - 80Hz
      bandPassFinalStageHigh1Ref.current = audioContext.createBiquadFilter();
      bandPassFinalStageHigh1Ref.current.type = 'highpass';
      bandPassFinalStageHigh1Ref.current.frequency.setValueAtTime(10, currentTime);

      bandPassFinalStageLow1Ref.current = audioContext.createBiquadFilter();
      bandPassFinalStageLow1Ref.current.type = 'lowpass';
      bandPassFinalStageLow1Ref.current.frequency.setValueAtTime(80, currentTime);

      // Ruta 2: 70Hz - 250Hz
      bandPassFinalStageHigh2Ref.current = audioContext.createBiquadFilter();
      bandPassFinalStageHigh2Ref.current.type = 'highpass';
      bandPassFinalStageHigh2Ref.current.frequency.setValueAtTime(70, currentTime);

      bandPassFinalStageLow2Ref.current = audioContext.createBiquadFilter();
      bandPassFinalStageLow2Ref.current.type = 'lowpass';
      bandPassFinalStageLow2Ref.current.frequency.setValueAtTime(250, currentTime);
      
      // Crear el nuevo filtro paso bajo (1000Hz)
      additionalLowPassRef.current = audioContext.createBiquadFilter();
      additionalLowPassRef.current.type = 'lowpass';
      additionalLowPassRef.current.frequency.setValueAtTime(1000, currentTime);

      // Nodo de suma para las dos ramas filtradas
      sumFinalStageRef.current = audioContext.createGain();
      sumFinalStageRef.current.gain.setValueAtTime(1, currentTime); // Ganancia de 1 para suma

      // 12. Crear el nodo de amplificación final (Volumen)
      finalAmplificationGainNodeRef.current = audioContext.createGain();
      finalAmplificationGainNodeRef.current.gain.setValueAtTime(
        finalAmpGain,
        currentTime
      );

      // 13. Crear el analizador de frecuencias con fftSize reducido
      analyserRef.current = audioContext.createAnalyser();
      analyserRef.current.fftSize = 512; // Reducido de 2048 a 512
      analyserRef.current.smoothingTimeConstant = 0.4; // Suavizado moderado

      // 14. Crear MediaStreamDestination para capturar el audio procesado
      mediaStreamDestinationRef.current = audioContext.createMediaStreamDestination();

      // **Nodo de Compresión Opcional - Ajustes más suaves**
      compressorRef.current = audioContext.createDynamicsCompressor();
      compressorRef.current.threshold.setValueAtTime(-50, currentTime);
      compressorRef.current.knee.setValueAtTime(40, currentTime);
      compressorRef.current.ratio.setValueAtTime(12, currentTime);
      compressorRef.current.attack.setValueAtTime(0, currentTime);
      compressorRef.current.release.setValueAtTime(0.25, currentTime);

      // **Crear el DelayNode para Look-Ahead (5 ms)**
      lookAheadDelayRef.current = audioContext.createDelay();
      lookAheadDelayRef.current.delayTime.setValueAtTime(0.005, currentTime); // 5 ms

      // **Conexión de los nodos**
      // Fuente -> Filtros de Eliminación de Ruido Personalizados Mejorados
      source.connect(noiseHighPassRef.current);
      noiseHighPassRef.current.connect(noiseLowPassRef.current);
      noiseLowPassRef.current.connect(gainNodeRef.current);

      // Ganancia Inicial -> Filtro Paso Banda Inicial
      gainNodeRef.current.connect(bandPassFilterInitialRef.current);

      // Filtro Paso Banda Inicial -> Rama 1
      bandPassFilterInitialRef.current.connect(bandPass1HighRef.current);
      bandPass1HighRef.current.connect(bandPass1LowRef.current);
      bandPass1LowRef.current.connect(sumGainNode2Ref.current);

      // Filtro Paso Banda Inicial -> Rama 2
      bandPassFilterInitialRef.current.connect(bandPass2HighRef.current);
      bandPass2HighRef.current.connect(bandPass2LowRef.current);
      bandPass2LowRef.current.connect(sumGainNode2Ref.current);

      // Suma de las dos ramas filtradas -> Amplificación Adicional
      sumGainNode2Ref.current.connect(amplificationGainNodeRef.current);

      // Amplificación Adicional -> Sustracción de Frecuencias
      amplificationGainNodeRef.current.connect(bandPassFilterSubtractionRef.current);
      bandPassFilterSubtractionRef.current.connect(invertGainNodeRef.current);
      invertGainNodeRef.current.connect(finalSumGainNodeRef.current);

      // Amplificación Adicional -> Suma Final
      amplificationGainNodeRef.current.connect(finalSumGainNodeRef.current);

      // Suma Final -> Filtro Paso Banda Final
      finalSumGainNodeRef.current.connect(bandPassFilterFinalRef.current);

      // **Nueva Etapa de Filtrado**
      // Conectar el filtro paso banda final a las dos ramas de filtrado
      bandPassFilterFinalRef.current.connect(bandPassFinalStageHigh1Ref.current);
      bandPassFinalStageHigh1Ref.current.connect(bandPassFinalStageLow1Ref.current);
      bandPassFinalStageLow1Ref.current.connect(sumFinalStageRef.current);

      bandPassFilterFinalRef.current.connect(bandPassFinalStageHigh2Ref.current);
      bandPassFinalStageHigh2Ref.current.connect(bandPassFinalStageLow2Ref.current);
      bandPassFinalStageLow2Ref.current.connect(additionalLowPassRef.current);
      additionalLowPassRef.current.connect(sumFinalStageRef.current);

      // Conectar la suma de las dos ramas filtradas a la amplificación final
      sumFinalStageRef.current.connect(finalAmplificationGainNodeRef.current);

      // **Nodo de Compresión Opcional con Look-Ahead**
      // Conectar la señal a través del DelayNode antes del Compressor
      finalAmplificationGainNodeRef.current.connect(lookAheadDelayRef.current);
      lookAheadDelayRef.current.connect(compressorRef.current);

      // Conectar el Compressor a los siguientes nodos
      compressorRef.current.connect(analyserRef.current);
      compressorRef.current.connect(mediaStreamDestinationRef.current);
      analyserRef.current.connect(audioContext.destination); // Opcional: Para escuchar el audio

      // Iniciar el procesamiento de datos
      processAudioData();
    }

    // Limpieza al desmontar el componente o cambiar audioContext/mediaStream
    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }

      // Desconectar y limpiar todos los nodos
      [
        noiseHighPassRef.current,
        noiseLowPassRef.current,
        gainNodeRef.current,
        bandPassFilterInitialRef.current,
        bandPass1HighRef.current,
        bandPass1LowRef.current,
        bandPass2HighRef.current,
        bandPass2LowRef.current,
        sumGainNode2Ref.current,
        amplificationGainNodeRef.current,
        bandPassFilterSubtractionRef.current,
        invertGainNodeRef.current,
        finalSumGainNodeRef.current,
        bandPassFilterFinalRef.current,
        bandPassFinalStageHigh1Ref.current,
        bandPassFinalStageLow1Ref.current,
        bandPassFinalStageHigh2Ref.current,
        bandPassFinalStageLow2Ref.current,
        sumFinalStageRef.current,
        additionalLowPassRef.current,
        finalAmplificationGainNodeRef.current,
        analyserRef.current,
        mediaStreamDestinationRef.current,
        compressorRef.current,
        lookAheadDelayRef.current, // Añadir el DelayNode a la limpieza
      ].forEach(node => {
        if (node) {
          node.disconnect();
        }
      });

      // Limpiar referencias
      [
        noiseHighPassRef,
        noiseLowPassRef,
        gainNodeRef,
        bandPassFilterInitialRef,
        bandPass1HighRef,
        bandPass1LowRef,
        bandPass2HighRef,
        bandPass2LowRef,
        sumGainNode2Ref,
        amplificationGainNodeRef,
        bandPassFilterSubtractionRef,
        invertGainNodeRef,
        finalSumGainNodeRef,
        bandPassFilterFinalRef,
        bandPassFinalStageHigh1Ref,
        bandPassFinalStageLow1Ref,
        bandPassFinalStageHigh2Ref,
        bandPassFinalStageLow2Ref,
        sumFinalStageRef,
        additionalLowPassRef,
        finalAmplificationGainNodeRef,
        analyserRef,
        mediaStreamDestinationRef,
        compressorRef,
        lookAheadDelayRef, // Añadir el DelayNode a la limpieza
      ].forEach(ref => {
        if (ref.current) {
          ref.current = null;
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioContext, mediaStream, noiseProfile]); // Añadir audioContext y mediaStream como dependencias

  // Actualizar los nodos de ganancia cuando los props cambien
  useEffect(() => {
    if (gainNodeRef.current && audioContext) {
      gainNodeRef.current.gain.setValueAtTime(gain, audioContext.currentTime);
    }
  }, [gain, audioContext]);

  useEffect(() => {
    if (amplificationGainNodeRef.current && audioContext) {
      amplificationGainNodeRef.current.gain.setValueAtTime(ampGain, audioContext.currentTime);
    }
  }, [ampGain, audioContext]);

  useEffect(() => {
    if (finalAmplificationGainNodeRef.current && audioContext) {
      finalAmplificationGainNodeRef.current.gain.setValueAtTime(
        finalAmpGain,
        audioContext.currentTime
      );
    }
  }, [finalAmpGain, audioContext]);

  // Captura de Perfil de Ruido
  useEffect(() => {
    if (isCalibrating && analyserRef.current) {
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      let accumulatedData = new Uint8Array(bufferLength);
      let samples = 0;
      const calibrationSamples = 500; // Aumenta este valor para capturar audio por más tiempo

      const calibrate = () => {
        analyserRef.current.getByteFrequencyData(dataArray);
        for (let i = 0; i < bufferLength; i++) {
          accumulatedData[i] += dataArray[i];
        }
        samples++;
        if (samples < calibrationSamples) {
          requestAnimationFrame(calibrate);
        } else {
          // Calcular el promedio
          for (let i = 0; i < bufferLength; i++) {
            accumulatedData[i] = Math.round(accumulatedData[i] / samples);
          }
          setNoiseProfileData([...accumulatedData]);
          setIsCalibrating(false);
          console.log('Perfil de ruido calibrado:', accumulatedData);
        }
      };

      calibrate();
    }
  }, [isCalibrating, analyserRef]);

  const processAudioData = () => {
    const analyser = analyserRef.current;
    if (!analyser) return;

    const bufferLength = analyser.fftSize;
    const timeDataArray = new Uint8Array(bufferLength);
    const freqDataArray = new Uint8Array(analyser.frequencyBinCount);

    const targetSampleSize = 200; // Número deseado de muestras

    const draw = () => {
      animationIdRef.current = requestAnimationFrame(draw);
      if (onTimeDomainData) {
        analyser.getByteTimeDomainData(timeDataArray);
        const downsampledTimeData = downsampleArray([...timeDataArray], targetSampleSize);
        onTimeDomainData(downsampledTimeData);
      }
      if (onFrequencyData) {
        analyser.getByteFrequencyData(freqDataArray);
        let processedFreqData = [...freqDataArray];
        if (noiseProfileData || noiseProfile) {
          const profile = noiseProfileData || noiseProfile;
          // Sustracción del perfil de ruido de los datos de frecuencia
          for (let i = 0; i < processedFreqData.length; i++) {
            processedFreqData[i] = Math.max(processedFreqData[i] - (profile[i] || 0), 0);
          }
        }
        const downsampledFreqData = downsampleArray(processedFreqData, targetSampleSize);
        onFrequencyData(downsampledFreqData);
      }
    };

    draw();
  };

  // Exponer el MediaStream procesado
  const processedStream = mediaStreamDestinationRef.current
    ? mediaStreamDestinationRef.current.stream
    : null;

  // Función para iniciar la calibración de ruido
  const startCalibration = () => {
    setIsCalibrating(true);
  };

  return { processedStream, isCalibrating, startCalibration, noiseProfileData, error };
};

export default useAudioProcessor;
